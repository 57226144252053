import { Box, Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { Page } from '../../components/common'
import ProfileTabs from "../../containers/profile/tabs/ProfileTabs.container";

const AccountPage = () => {

    return (
        <Page title='Il tuo profilo'>
            <Container component='main' maxWidth="md">
                <ProfileTabs />                
                <Box mt={4} width={1}>
                    <Outlet />
                </Box>
            </Container>
        </Page>
    )
}

export default AccountPage