import { Box, Typography } from '@mui/material'


const Info = ({
    title,
}) => {


    return (
        <Box height={40} width={1} display='flex' alignItems='flex-end' justifyContent='center'>
            <Typography variant='caption' textAlign='center' fontSize='0.65rem' width={1}>
                {title}
            </Typography>
        </Box>

    )
}

export default Info