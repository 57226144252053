import React, { useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { Loading, Page } from '../components/common'
import KioskContainer from '../containers/kiosk/Kiosk.container'
import { KioskContext } from '../core/context/kiosk.context'
import useCatalog from '../core/hooks/useCatalog'
import { DateTime } from 'luxon';
import PurchaseContainer from '../containers/kiosk/Purchase.container'

const KioskPage = () => {


    const [index, setIndex] = useState(0);

    const { loading, editions, issueToday, issueOthers } = useCatalog(DateTime.now().toString())

    const items = useMemo(() => {
        if (issueOthers && issueToday) {
            return [issueToday, ...issueOthers]
        }
        return []
    }, [issueToday, issueOthers])

    return (
        <Page title='Edicola' subtitle='Leggi il quotidiano in versione digitale' backgroundColor='white.main'>
            <KioskContext.Provider value={{ index, setIndex }}>
                {loading && <Loading type='backdrop' />}

                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <KioskContainer items={items} editions={editions} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <PurchaseContainer items={items} />
                    </Grid>
                </Grid>
            </KioskContext.Provider>
        </Page>
    )
}

export default KioskPage