import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ImageConf } from '../../../conf/image';
import Loading from '../../common/Loading.component';


const CoverComponent = ({
    issue,
    width,
    height
}) => {

    const { newspaper } = useParams()
    const [url, setUrl] = useState(null)
    const [loading, setLoading] = useState(false)
    const { ratio, padding } = ImageConf

    const { selected: { edition } } = useSelector((state) => state.catalog)

    useEffect(() => {
        if (edition && issue) {
            setUrl(`${process.env.REACT_APP_BASE_URL_CDN}vp3ws/v3/issue/${issue.id}/cover/jpg/800?app_name=${newspaper.replaceAll('-', '').toUpperCase()}`)
        }
    }, [edition, issue])


    const size = useMemo(() => {

        if (width) {
            return ({
                width: width - padding,
                height: (width - padding) / ratio
            })
        }


        if (height) {
            return ({
                width: (height * ratio) - padding,
                height: height
            })
        }

        return ({
            width: 0,
            height: 0
        })

    }, [height, width, ratio, padding])

    return (
        <Box sx={{ position: 'relative', ...size }}>
            {loading && <Loading type='linear' />}
            {issue &&
                <LazyLoadImage
                    alt=''
                    height='auto'
                    width='100%'
                    src={url}
                    afterLoad={() => setLoading(false)}
                    beforeLoad={() => setLoading(true)} />                 
            }            
        </Box>
    )
}

export default CoverComponent