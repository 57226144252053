import { Container, Stack, Typography } from '@mui/material'
import DividerSection from '../../components/cards/components/divider';
import { useSelector } from 'react-redux';

const PapersContainer = () => {

    const { newspaper: contact } = useSelector((state) => state.newspaper)

    return (
        <Container component='section' maxWidth="xl">
            <DividerSection title='Per Abbonarti al quotidiano cartaceo' >
                <Stack justifyContent={'center'}>                    
                    <Typography variant='subtitle1' color='primary' textAlign={'center'}>
                        {`CHIAMA AL NUMERO:`}
                    </Typography>
                    <Typography variant='h6' textAlign={'center'} color={'secondary'} fontWeight={'bold'}>
                        {`${contact?.paywall_configuration?.paper_subscription_contact_phone || ''}`}
                    </Typography>
                    <Typography variant='subtitle1' color='primary' textAlign={'center'}>
                        {`OPPURE SCRIVI ALL’INDIRIZZO EMAIL:`}
                    </Typography>
                    <Typography variant='h6' textAlign={'center'} color={'secondary'} fontWeight={'bold'}>
                        {`${contact?.paywall_configuration?.paper_subscription_contact_email || ''}`}
                    </Typography>
                </Stack>
            </DividerSection>
        </Container>
    )

}

export default PapersContainer