import React, { useMemo } from "react";
import { Typography } from '@mui/material'
import { Link, useParams } from "react-router-dom";

const CTAActionComponent = ({
    title,
    actionLink
}) => {

    const { newspaper, partner } = useParams();
    const to = useMemo(() => {
        return partner ? `/${newspaper}/${partner}${actionLink}` : `/${newspaper}${actionLink}`
    }, [newspaper, partner, actionLink])

    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <Typography variant='h6' color={'primary'} sx={{ fontWeight: 'bold', width: 1 }}>
                {title}
            </Typography>
        </Link>
    )
}

export default CTAActionComponent