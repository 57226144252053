import { useAuth0 } from "@auth0/auth0-react";
import React, { useMemo } from "react";
import { Typography, Button, Stack, Avatar } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const UserButton = ({
    variant = 'contained',
    color = 'button',
    showWelcome = true,
    sx = {},
    hash = ''
}) => {

    const { loginWithRedirect, isAuthenticated, user: auth0 } = useAuth0();
    const { user } = useSelector(state => state.user)
    const { newspaper, partner } = useParams();
    const { pathname } = useLocation();
    
    const menuLink = useMemo(() => {
        return partner? `${newspaper}/${partner}`: `${newspaper}`
    }, [partner, newspaper])

    const redirectUri = useMemo(() => {
        if (pathname.includes('/abbonamenti') || pathname.includes('/edicola')) {
            return `${process.env.REACT_APP_AUTH0_REDIRECT_URL}${pathname}${hash}`
        } else {
            return `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/${newspaper}/abbonamenti`
        }

    }, [pathname, hash, newspaper])

    const WelcomeMessage = useMemo(() => {

        if (isAuthenticated && showWelcome && user) {
            return (
                <Link to={`/${menuLink}/account/profilo`}>
                    <Avatar src={auth0 && auth0.picture} alt="Profile" />
                </Link>                
            )            
        }

        return null

    }, [isAuthenticated, showWelcome, user, menuLink, auth0])

    const LoginButton = useMemo(() => {

        if (!isAuthenticated) {

            return (
                <Button variant={variant} color={color}>
                    <Typography variant={'button'} fontWeight='bold' onClick={() => loginWithRedirect({ redirectUri: redirectUri })} textAlign="center">
                        Accedi
                    </Typography>
                </Button>
            )
        }

    }, [isAuthenticated, variant, color, loginWithRedirect, redirectUri])


    return (
        <Stack sx={{ ...sx }}>
            {WelcomeMessage}
            {LoginButton}
        </Stack>
    )

}

export default UserButton