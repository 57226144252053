import _ from 'lodash'

export const SubscriptionEndpoints = {
    basePath: '',
    endpoints: {
        create: {
            path: `/subscriptions`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        faq: {
            path: `/faqs?newspaper.slug={{newspaper}}`,
            method: 'GET',
            get: null,
            fallback: null,
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data, 'order')
        },
        all: {
            path: `/subscriptions?profile.auth0={{auth0}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data, 'current_period_end')
        },
        exists: {
            path: `/subscriptions/exists`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true
        },
        promo: {
            path: `/redeem-subscription-with-code`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true
        }             
    }
}

export default SubscriptionEndpoints