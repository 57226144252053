import { TextField } from '@mui/material'
import styled from '@emotion/styled'
import { theme } from '../../../theme/theme'

export const Styled = {
    TextField: styled(TextField)({        
        '& label.Mui-focused': {
            color: theme.palette.primary.main,            
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#096ab0',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main
            }
        }
    }),
}