import React from 'react'
import Page from '../components/common/Page.component';

const NotFoundPage = () => {

    return (
        <Page title="404 Page Not Found">
            
        </Page>
    )
}

export default NotFoundPage