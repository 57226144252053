import React from 'react'
import { Grid, Container, CssBaseline } from '@mui/material'
import Page from '../components/common/Page.component'
import NewslettersContainer from '../containers/newsletter/Newsletters.container'

const NewslettersPage = () => {

    return (
        <Page title='Newsletter'>
            <Container component="main" maxWidth="md" sx={{ padding: 8 }}>
                <CssBaseline />
                <Grid container  spacing={4}>
                    <NewslettersContainer />
                </Grid>
            </Container>
        </Page>
    )
}

export default NewslettersPage