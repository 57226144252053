import _ from 'lodash';

export const UsersEndpoints = {
    basePath: 'api/users',
    endpoints: {
        me: {
            path: '/me',
            method: 'GET',
            auth: true,
            get: null,
            fallback: null,
        }        
    }
}

export default UsersEndpoints;