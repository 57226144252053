import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { Box, Container, Paper, Stack, Typography } from '@mui/material';


const analyticsIDs = {
    'il-tirreno': process.env.REACT_APP_GOOGLE_ANALYTICS_ID_IL_TIRRENO,
    'gazzetta-di-modena': process.env.REACT_APP_GOOGLE_ANALYTICS_ID_GAZZETTA_DI_MODENA,
    'gazzetta-di-reggio': process.env.REACT_APP_GOOGLE_ANALYTICS_ID_GAZZETTA_DI_REGGIO,
    'la-nuova-sardegna': process.env.REACT_APP_GOOGLE_ANALYTICS_ID_LA_NUOVA_SARDEGNA,
    'la-nuova-ferrara': process.env.REACT_APP_GOOGLE_ANALYTICS_ID_LA_NUOVA_FERRARA,
}

const pixelFBIds = {
    'il-tirreno': process.env.REACT_APP_FB_PIXEL_ID_IL_TIRRENO,
    'gazzetta-di-modena': process.env.REACT_APP_FB_PIXEL_ID_GAZZETTA_DI_MODENA,
    'gazzetta-di-reggio': process.env.REACT_APP_FB_PIXEL_ID_GAZZETTA_DI_REGGIO,
    'la-nuova-sardegna': process.env.REACT_APP_FB_PIXEL_ID_LA_NUOVA_SARDEGNA,
    'la-nuova-ferrara': process.env.REACT_APP_FB_PIXEL_ID_LA_NUOVA_FERRARA,
}

const Page = forwardRef(({
    children,
    title = '',
    subtitle = '',
    backgroundColor = 'background.main',
    ...other
}, ref) => {

    const pathname = window.location.pathname
    const newspaper = pathname.split('/')[1];
    const trackingId = analyticsIDs[newspaper];
    const pixelId = pixelFBIds[newspaper];

    return (
        <Box ref={ref} {...other}>
            <Helmet>
                <title>{title}</title>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`} >
                </script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${trackingId}',{ 'debug_mode':true });`}
                </script>
                <script>
                    {`!function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '${pixelId}'); 
                        fbq('track', 'PageView');`}
                </script>                
            </Helmet>
            <img height="1" width="1" src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`} style={{ visibility: 'hidden'}}/>
            <Paper square={true} elevation={0} sx={{ backgroundColor: 'background.main' }}>
                <Container maxWidth="xl">
                    <Stack padding={2} >
                        <Typography variant='h4' color='black.main' textAlign='center' fontFamily={'Bookman'}>
                            {title}
                        </Typography>
                        <Typography variant='h5' color='black.main' textAlign='center' fontFamily={'Bookman'}>
                            {subtitle}
                        </Typography>
                    </Stack>
                </Container>
            </Paper>
            <Stack sx={{ backgroundColor, paddingBottom: 2 }} alignItems='center'>
                {children}
            </Stack>
        </Box>
    )
}

);

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
};

export default Page;