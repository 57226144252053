import _ from 'lodash'

export const CMSEndpoints = {
    basePath: '',
    endpoints: {
        newspapers: {
            path: `/newspapers`,
            method: 'GET',
            get: 'data',
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            }
        },
        newspaper: {
            path: `/newspapers?slug={{slug}}`,
            method: 'GET',
            get: null,
            fallback: null,
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => {                
                if (data.length > 0) {                    
                    const { title } = data[0]
                    return { title }
                }

                return {
                    title: ''
                }
            },
        },        
        footer: {
            path: `/footer`,
            method: 'GET',
            get: 'data',
            fallback: null,
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => {

                const footer = data.attributes
                return ({
                    text: footer?.text,
                    rights: footer?.rights
                })
            },
        },

    }
}

export default CMSEndpoints