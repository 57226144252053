import React, { Fragment, useMemo } from 'react'
import { Paper, Stack, Typography, Divider } from '@mui/material'
import moment from 'moment';
import _ from 'lodash'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AppleButton, GooglePlayButtonButton, ReaderButton } from '../common/buttons';

const IssueName = ({
    text
}) => {

    const title = useMemo(() => {
        return moment(text.substring(text.length - 9, text.length), 'YYYY_MMDD').format('DD-MM-YYYY')
    }, [text])

    return (
        <Typography variant='h6' fontWeight='bold' sx={{ padding: 1 }}>
            Copia del {title}
        </Typography>
    )
}


const ProfilePurchases = ({
    purchases = [],
}) => {

    return (
        <Fragment>
            <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                Le Copie Singole Acquistate
            </Typography>
            <Stack spacing={4}>
                {purchases.map((item, index) => (
                    <Paper variant="outlined" square key={index}>
                        <IssueName text={_.get(item, 'issue')} />
                        <Divider sx={{ marginBottom: 1 }} />
                        <Stack spacing={1}>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <CalendarMonthIcon color='primary' />
                                <Typography variant='body2' fontWeight='bold'>
                                    Acquistata il
                                </Typography>
                                <Typography variant='body2'>
                                    {moment(_.get(item, 'date')).format('DD-MM-YYYY')}
                                </Typography>
                            </Stack>
                            <Divider />
                        </Stack>

                        <Stack padding={1} direction='row' alignItems='center' flexWrap='wrap' justifyContent={'space-between'}>
                            <ReaderButton />  
                            <AppleButton />                          
                            <GooglePlayButtonButton />
                        </Stack>
                    </Paper>
                ))}
            </Stack>



        </Fragment>
    )
}

export default ProfilePurchases