import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Box } from "@mui/material";
import { FieldsUnder25, InitialData, ValidationSchema } from "../../conf/under25";
import { Components, ErrorField } from '../form'
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitButton } from "../form";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ModalForm = ({
	open = false,
	setOpen = () => {},
	clickSubmit = () => { },
	children
}) => {

	const { user } = useSelector(state => state.user)
	const { handleSubmit, control, formState, setValue } = useForm({
		resolver: yupResolver(ValidationSchema),
		defaultValues: InitialData
	});

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if(user){
			setValue('firstName', user.given_name || '')
			setValue('lastName', user.family_name || '')
			setValue('birthPlace', user.city || '')
			setValue('gender', user.gender? user.gender === 'male'? 'M' : user.gender === 'female'? 'F' : 'M' : 'M')
			setValue('birthDate', user.birthdate || new Date().toLocaleDateString())
		}

	}, [user, setValue])

	return (
		<Dialog
			fullWidth={true}
			maxWidth={'xs'}
			open={open}
			onClose={handleClose}>
			<DialogContent>
				<Box display='flex' justifyContent={'space-between'}>
					<DialogTitle>Inserisci i tuoi dati</DialogTitle>
					<Button onClick={handleClose}>Chiudi</Button>
				</Box>
				{children}
				<Box component="form" noValidate onSubmit={handleSubmit(clickSubmit)} width={1}>
					<Grid container spacing={1}>
						{FieldsUnder25.map(({ dimensions, controller, component }) => {
							const Field = Components[component.name]
							return (
								<Grid item xs={dimensions.xs} key={controller.name}>
									<Controller
										name={controller.name}
										control={control}
										rules={{ required: controller.required }}
										render={({ field }) =>
											<Field
												shrink={controller.name == 'birthdate'}
												required={controller.required}
												label={component.label}
												type={component.type}
												options={component.options}
												{...field} />} />
									{formState.errors?.[controller.name] ? <ErrorField message={formState.errors?.[controller.name]?.message} /> : null}
								</Grid>
							)
						})}
						<Grid item xs={12}>
							<SubmitButton title='Procedi' />
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ModalForm;
