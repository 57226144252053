import { Paper } from '@mui/material'

const Container = ({
    height = 350,
    children,
}) => {
    
    
    return(
        <Paper elevation={24} square={true} sx={{ padding: 2, height, width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} >
            {children}
        </Paper>    
    )
}

export default Container