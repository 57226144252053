import { Typography } from "@mui/material";

const Price = ({
    price,
}) => {

    return (
        <Typography color={'secondary.main'} textAlign={'center'}>
            <div dangerouslySetInnerHTML={{ __html: price }} />
        </Typography>
    )
}

export default Price