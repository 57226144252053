import React, { Fragment } from "react";
import { Backdrop, CircularProgress, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";


//Type: 'circular', 'linear', 'backdrop'
const Loading = ({
    type,
    color = 'secondary',
    variant = 'indeterminate',
    value = 100
}) => {

    return (
        <Fragment>
            {type === 'circular' &&
                <Box sx={{ width: '100%' }} display='flex' justifyContent='center'>
                    <CircularProgress color={color} variant={variant} value={value} />
                </Box>
            }

            {type === 'linear' &&
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color={color} variant={variant} value={value} />
                </Box>
            }

            {type === 'backdrop' &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color={color} variant={variant} value={value} />
                </Backdrop>
            }

        </Fragment>


    )
}

export default Loading