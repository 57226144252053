import * as React from "react"

const Logo = () => (

    <svg id="a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 67 43">
        <defs>
            <clipPath id="b">
                <rect x="7.859" y="4" width="22.527" height="35" fill="none" />
            </clipPath>
        </defs>
        <rect width="67" height="43" fill="#ffdf33" />
        <g clipPath="url(#b)">
            <path d="M30.385,16.455h-8.479v-2.158c0-1.975-.138-3.418-2.472-3.418-2.575,0-2.482,2.13-2.482,2.866v14.257c0,1.272-.438,4.067,2.482,4.067,2.841,0,2.472-2.771,2.472-3.944v-3.003h-1.953v-5.905h10.432v16.015c-1.292,1.238-2.839,2.15-4.639,2.736-1.801,.585-4.204,1.034-6.728,1.034-2.563,0-4.152-.441-5.644-1.009-1.492-.569-2.646-1.339-3.462-2.31-.815-.97-1.361-2.09-1.639-3.363-.276-1.271-.415-2.611-.415-4.017V14.447c0-1.339,.145-2.627,.439-3.865,.292-1.237,.839-2.335,1.637-3.288,.801-.954,1.923-1.701,3.349-2.285,2.032-.832,5.735-1.009,5.735-1.009,0,0,3.625,.304,6.197,1.11,1.946,.609,3.661,1.589,5.17,2.861v8.484Z" />
        </g>
        <path d="M49.643,10.239c.458,0,.815,.242,1.07,.727,.256,.484,.383,.905,.383,1.262,0,.203-.115,.727-.345,1.568s-.483,1.784-.765,2.83c-.279,1.044-.561,2.014-.842,2.906-.279,.892-.445,1.492-.497,1.798,.357,0,1.161,.012,2.409,.038,1.25,.025,2.576,.102,3.978,.229,1.403,.127,2.627,.306,3.671,.535,1.046,.229,1.568,.548,1.568,.956,0,.459-.203,.88-.612,1.263-.407,.382-.841,.573-1.3,.573-1.784,0-3.582,.012-5.393,.038-1.809,.025-3.606,.038-5.392,.038-.662,2.243-1.339,4.486-2.027,6.73-.688,2.243-1.262,4.513-1.721,6.808-.306,.05-.522-.115-.649-.497-.127-.383-.204-.804-.229-1.263-.025-.458-.039-.765-.039-.917,0-1.786,.128-3.595,.383-5.431,.256-1.836,.511-3.646,.765-5.431h-8.337c-.713,0-1.427,.013-2.142,.038-.713,.025-1.427,.063-2.142,.115,0-.052-.012-.09-.038-.115-.024-.025-.038-.064-.038-.114,0-.357,.396-.688,1.186-.994,.791-.306,1.785-.587,2.983-.842,1.198-.256,2.422-.485,3.671-.688,1.25-.204,2.384-.37,3.403-.497,1.021-.128,1.709-.217,2.065-.268,.103-.357,.344-1.147,.727-2.371,.382-1.224,.829-2.524,1.338-3.901,.511-1.376,1.021-2.575,1.53-3.595,.51-1.021,.969-1.53,1.377-1.53Z" />
    </svg>
)

export default Logo