export const routes = [{
    title: 'Acquista un nuovo Abbonamento',
    link: 'abbonamenti',
    slug: 'abbonamenti',
    internal: true
},{
    title: 'Acquista una copia singola',
    link: 'edicola',
    slug: 'edicola',
    internal: true
},{
    title: 'Acquista un Abbonamento Cartaceo',
    link: 'cartaceo',
    slug: 'cartaceo',
    internal: true
}, {
    title: 'Newsletters',
    link: 'newsletters',
    slug: 'newsletters',
    internal: true
}, {
    title: 'FAQ',
    link: 'FAQ',
    slug: 'faq',
    internal: true
}]


export const routesUser = (newspaper) => [{
    title: 'Il tuo profilo',
    link: 'account/profilo',
    slug: 'profilo',
    internal: true
}, {
    title: 'Leggi il quotidiano',
    link: readerLink[newspaper],
    slug: 'leggiquotidiano',
    internal: false
}, {
    title: 'Logout',
    link: 'logout',
    slug: 'logout',
    internal: false
}]


export const backLink = {
    'il-tirreno': process.env.REACT_APP_IL_TIRRENO,
    'gazzetta-di-modena': process.env.REACT_APP_GAZZETTA_DI_MODENA,
    'gazzetta-di-reggio': process.env.REACT_APP_GAZZETTA_DI_REGGIO,
    'la-nuova-sardegna': process.env.REACT_APP_LA_NUOVA_SARDEGNA,
    'la-nuova-ferrara': process.env.REACT_APP_LA_NUOVA_FERRARA,
}

export const readerLink = {
    'il-tirreno': process.env.REACT_APP_READER_IL_TIRRENO,
    'gazzetta-di-modena': process.env.REACT_APP_READER_GAZZETTA_DI_MODENA,
    'gazzetta-di-reggio': process.env.REACT_APP_READER_GAZZETTA_DI_REGGIO,
    'la-nuova-sardegna': process.env.REACT_APP_READER_LA_NUOVA_SARDEGNA,
    'la-nuova-ferrara': process.env.REACT_APP_READER_LA_NUOVA_FERRARA,
}

export const appleLink = {
    'il-tirreno': process.env.REACT_APP_IL_TIRRENO_APPLE,
    'gazzetta-di-modena': process.env.REACT_APP_GAZZETTA_DI_MODENA_APPLE,
    'gazzetta-di-reggio': process.env.REACT_APP_GAZZETTA_DI_REGGIO_APPLE,
    'la-nuova-sardegna': process.env.REACT_APP_LA_NUOVA_SARDEGNA_APPLE,
    'la-nuova-ferrara': process.env.REACT_APP_LA_NUOVA_FERRARA_APPLE,
}


export const googlePlayLink = {
    'il-tirreno': process.env.REACT_APP_IL_TIRRENO_GOOGLE_PLAY,
    'gazzetta-di-modena': process.env.REACT_APP_GAZZETTA_DI_MODENA_GOOGLE_PLAY,
    'gazzetta-di-reggio': process.env.REACT_APP_GAZZETTA_DI_REGGIO_GOOGLE_PLAY,
    'la-nuova-sardegna': process.env.REACT_APP_LA_NUOVA_SARDEGNA_GOOGLE_PLAY,
    'la-nuova-ferrara': process.env.REACT_APP_LA_NUOVA_FERRARA_GOOGLE_PLAY,
}