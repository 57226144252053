import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'newsletters',
    initialState: {        
        loading: false,
        error: null,        
        newsletters: [],        
    },
    reducers: {
        newsletters: (state) => {
            state.error = null
            state.loading = true            
            state.newsletters = []
        },                
        newslettersSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.newsletters = action.newsletters
        },
        newslettersError: (state, action) => {              
            state.newsletters = []   
            state.error = action.error
            state.loading = false
        }        
    },
});


export const {
    newsletters,
    newslettersSuccess,
    newslettersError    
} = slice.actions

export default slice.reducer