
import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const Contact = () => {

    const { newspaper: contact } = useSelector((state) => state.newspaper)

    return (
        <Stack justifyContent={'center'}>
            <Stack direction={'row'} spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                <PhoneIcon />
                <Typography component={'span'} variant='body2' textAlign={'center'} color={'primary'} fontWeight={'bold'}>
                    {`${contact?.paywall_configuration?.paper_subscription_contact_phone || ''}`}
                </Typography>
            </Stack>            
            <Stack direction={'row'} spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                <EmailIcon />
                <Typography component={'span'} variant='body2' textAlign={'center'} color={'primary'} fontWeight={'bold'}>
                    {`${contact?.paywall_configuration?.paper_subscription_contact_email || ''}`}
                </Typography>
            </Stack>
        </Stack>
    )

}

export default Contact