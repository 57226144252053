import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'purchases',
    initialState: {        
        loading: false,
        error: null,        
        purchases: [],        
    },
    reducers: {
        purchases: (state) => {
            state.error = null
            state.loading = true            
            state.purchases = []
        },                
        purchasesSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.purchases = action.purchases
        },
        purchasesError: (state, action) => {              
            state.purchases = []   
            state.error = action.error
            state.loading = false
        }        
    },
});


export const {
    purchases,
    purchasesSuccess,
    purchasesError    
} = slice.actions

export default slice.reducer