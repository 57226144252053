import { Stack } from '@mui/material';
import React from 'react'
import ProfilePurchasesContainer from '../../containers/profile/ProfilePurchases.container';
import ProfileSubscriptionsContainer from '../../containers/profile/ProfileSubscriptions.container';

const ProfileSubscriptionsPage = () => {

    return (
        <Stack spacing={4}>
            <ProfileSubscriptionsContainer />
            <ProfilePurchasesContainer />
        </Stack>
        
    )
}

export default ProfileSubscriptionsPage