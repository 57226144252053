import IlTirreno from './IlTirreno'
import LaGazzettaDiModena from './LaGazzettaDiModena'
import LaGazzettaDiReggio from './LaGazzettaDiReggio'
import LaNuovaSardegna from './LaNuovaSardegna'
import LaNuovaFerrara from './LaNuovaFerrara'

export const Testata = {
    'il-tirreno' : IlTirreno,
    'gazzetta-di-modena' : LaGazzettaDiModena,
    'gazzetta-di-reggio' : LaGazzettaDiReggio,
    'la-nuova-sardegna' : LaNuovaSardegna,
    'la-nuova-ferrara' : LaNuovaFerrara,
}