import React from "react";
import Questions from "../../components/questions/Questions.component";
import { Requests, useSWRRequest } from "../../core";
import { Loading } from "../../components/common";
import { useParams } from "react-router-dom";

const FAQContainer = () => {

    const { newspaper } = useParams();
    const { data, loading } = useSWRRequest(Requests.subscription.faq, { newspaper })
    
    return (
        loading? <Loading /> : <Questions questions={data} /> 
    )    
}

export default FAQContainer