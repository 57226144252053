import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';

const useUser = () => {

    const { getAccessTokenSilently, user } = useAuth0()
    const [token, setToken] = useState(null)

    // Get Auth0 Token
    useEffect(() => {
        if (user) {
            getAccessTokenSilently({
                audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
                scope: "read:current_user",
            }).then((token) => setToken(token))
        }
    }, [user, setToken, getAccessTokenSilently])


    useEffect(() => {

        const handleToken = () => {
            window?.setTimeout(() => { if (user) getAccessTokenSilently() }, 100);
        };

        window?.addEventListener('focus', handleToken);
        window?.addEventListener('load', handleToken);

        return () => {
            window?.removeEventListener('focus', handleToken);
            window?.removeEventListener('load', handleToken);
        };
    }, [user, getAccessTokenSilently]);

    return [user, token]
}

export default useUser