import _ from 'lodash';

export const AuthEndpoints = {
    basePath: '',
    endpoints: {
        profile: {
            path: '/profile_update/{{auth0}}',
            method: 'PUT',
            get: null,
            auth: true,
            fallback: null,
        },                        
    }
}

export default AuthEndpoints;