import React, { Fragment } from 'react'
import { Paper, Stack, Typography, Box, Chip, Divider, Button } from '@mui/material'
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Abbonamento } from '../../icons/abbonamento';
import _ from 'lodash'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { AppleButton, GooglePlayButtonButton, ReaderButton, SiteButton } from '../common/buttons';

const ProfileSubscription = ({
    subscriptions = [],
}) => {

    const { newspaper } = useParams()
    const Logo = Abbonamento[newspaper]

    return (
        <Fragment>
            <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                I Tuoi Abbonamenti
            </Typography>
            <Stack spacing={4}>
                {subscriptions.map((item, index) => (
                    <Paper variant="outlined" square key={index}>

                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Box sx={{ display: 'flex', width: 48, height: 56, backgroundColor: 'yellow.main' }}>
                                <Logo />
                            </Box>
                            <Stack direction='row' spacing={1} alignItems='center' justifyContent={'space-between'} width={1}>
                                <Typography variant='body1' fontWeight='bold'>
                                    {_.get(item, 'price.name')}
                                </Typography>
                                {item.can_download_pdf && <PictureAsPdfIcon color='secondary' />}
                            </Stack>
                        </Stack>

                        <Divider sx={{ marginBottom: 1 }} />

                        <Stack spacing={1} sx={{ padding: 1 }}>
                        
                            <Stack direction='row' spacing={1} justifyContent='center'>
                                <Chip label={item.is_active ? 'Attivo' : 'Scaduto'} color={item.is_active ? 'success' : 'default'} sx={{ width: 100}}/>
                            </Stack>
                            

                            <Stack direction='row' spacing={1} alignItems='center'>
                                <CalendarMonthIcon color='primary' />
                                <Typography variant='body2' fontWeight='bold'>
                                    Acquistato il
                                </Typography>
                                <Typography variant='body2'>
                                    {moment(_.get(item, 'created_at')).format('DD-MM-YYYY')}
                                </Typography>
                            </Stack>


                            <Stack direction='row' spacing={1} alignItems='center'>
                                <CalendarMonthIcon color='secondary' />
                                <Typography variant='body2' fontWeight='bold'>
                                    Scadenza il
                                </Typography>
                                <Typography variant='body2'>
                                    {moment(_.get(item, 'current_period_end')).format('DD-MM-YYYY')}
                                </Typography>
                            </Stack>

                            <Divider />



                        </Stack>

                        <Stack padding={1} direction='row' alignItems='center' flexWrap='wrap' justifyContent={'space-between'}>
                            <SiteButton />
                            <ReaderButton />  
                            <AppleButton />                          
                            <GooglePlayButtonButton />
                        </Stack>

                    </Paper>
                ))}
            </Stack>
        </Fragment>
    )
}

export default ProfileSubscription