import React from 'react'
import { Grid, Container } from '@mui/material'
import Page from '../components/common/Page.component'
import FAQContainer from '../containers/FAQ/FAQ.container'

const FAQPage = () => {

    return (
        <Page title='Cerchi maggiori informazioni sui nostri servizi?' subtitle='Leggi le risposte alle domande frequenti che trovi di seguito'>
            <Container component="main" maxWidth="md">                
                <Grid container>
                    <FAQContainer />
                </Grid>
            </Container>
        </Page>
    )
}

export default FAQPage