import _ from 'lodash'

export const NewslettersEndpoints = {
    basePath: '',
    endpoints: {
        all: {
            path: `/newsletters?newspaper.slug={{slug}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data, 'free', 'desc')
        },
        my: {
            path: `/newsletters-profiles/`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        subscribe: {
            path: `/newsletters/subscribe/{{id}}`,
            method: 'PUT',
            get: null,
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        isSubscribe: {
            path: `/newsletters/is-subscribed/{{id}}`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        unsubscribe: {
            path: `/newsletters/unsubscribe/{{id}}`,
            method: 'PUT',
            get: null,
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },

    }
}

export default NewslettersEndpoints