import { Box, Grid , Typography} from "@mui/material";
import LogoGruppoSae from "../../icons/LogoGruppoSae";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { useMemo } from "react";

export const FooterComponent = () => {

    const mobile = useMediaQuery(767)

    const pathname = window.location.pathname

    const newspaper = pathname.split('/')[1];

    const text = useMemo(() => {
        return newspaper === 'la-nuova-sardegna' ?
            'Sae Sardegna SpA – sede legale Zona industriale Predda Niedda strada 31 , 07100 Sassari, - Capitale sociale Euro 6.000.000 – R.E.A. SS – 213461 – Registro Imprese di Sassari 02328540683 – CF e Partita IVA 02328540683' :
            'Gruppo SAE (SAPERE AUDE EDITORI) S.p.A, Zona industriale Predda Niedda strada 31 , 07100 Sassari - P.I. 0195463049'
    }, [newspaper])

    return (
        <Grid container sx={{ backgroundColor: 'background.main', padding: 1}}>
            <Grid item xs={12} container justifyContent={mobile ? 'center' : 'flex-start'}>
                <Box sx={{ width: 83, height: 83 }}>
                    <LogoGruppoSae width='100%' />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant="body2">
                    {text}
                </Typography>    
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography variant='caption'>
                    {'I diritti delle immagini e dei testi sono riservati. È espressamente vietata la loro riproduzione con qualsiasi mezzo e l\'adattamento totale o parziale. '}
                </Typography>    
            </Grid>
        </Grid>
    )
};



export default FooterComponent