import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'products',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {
        products: (state) => {
            state.error = null
            state.loading = true
            state.data = []
        },
        productsSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.data = action.products.map((p) => ({
                ...p,
                prices: []
            }))
        },
        productsError: (state, action) => {
            state.data = []
            state.error = action.error
            state.loading = false
        },
        prices: (state) => {
            state.error = null
            state.loading = true
        },
        pricesSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.data =  state.data.map((p) => p.id_stripe === action.product || p.id === action.product ? ({ ...p, prices: action.prices }) : ({ ...p }))
        },
        pricesError: (state, action) => {            
            state.error = action.error
            state.loading = false
        }
    },
});


export const {
    products,
    productsSuccess,
    productsError,
    prices,
    pricesSuccess,
    pricesError,
} = slice.actions

export default slice.reducer