import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './router/Router';
import { HelmetProvider } from 'react-helmet-async';
import '@stripe/stripe-js'
import store from './store/app.store'
import './App.css';
import { Endpoints } from './core'
import { Auth0Provider } from "@auth0/auth0-react";
import MainLayout from './layout/MainLayout';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
Endpoints.baseURL = process.env.REACT_APP_BASE_URL


function App() {
	
	return (
		<ThemeProvider theme={theme}>
			<HelmetProvider>
				<Auth0Provider
					domain={process.env.REACT_APP_AUTH0_DOMAIN}
					clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
					audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
					scope="read:current_user update:current_user_metadata"
					cacheLocation="localstorage">
					<Provider store={store}>
						<BrowserRouter>
							<MainLayout>
								<Router />
							</MainLayout>
						</BrowserRouter>
					</Provider>
				</Auth0Provider>
			</HelmetProvider>
		</ThemeProvider>

	);
}

export default App;
