import React, { forwardRef } from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { Styled } from './selectField.styled'

const SelectField = forwardRef(({
    required = false,
    label,
    options = [],
    ...props
}, ref) => {

    return (
        <FormControl fullWidth margin='normal' >
            <InputLabel >{label}</InputLabel>
            <Select
                inputRef={ref}
                value={props.value}
                required={required}
                onChange={props.onChange}
                name={props.name}
                input={<Styled.OutlinedInput label={label} />}
            >
                {options.map((item) =>
                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                )}
            </Select>
        </FormControl>
    )
})

export default SelectField