import React from 'react';
import { useTheme, Typography, Stack } from '@mui/material';

const PriceKiosk = ({
    text
}) => {

    const theme = useTheme()

    return (
        <Stack direction={'row'} spacing={1}>
            <Typography color={theme.palette.primary.main} variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                PREZZO:
            </Typography>
            <Typography color={theme.palette.secondary.main} variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {text}
            </Typography>
        </Stack>

    )
}

export default PriceKiosk
