import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Box } from '@mui/material';
import { useParams } from "react-router-dom";
import NewsletterCard from '../../components/cards/newsletter-card/NewsletterCard.component';
import SubscribeContainer from './Subscribe.container';
import { useDispatch, useSelector } from 'react-redux';
import { newsletters } from '../../store/newsletters.store';
import { Loading, Message } from '../../components/common';
import { Requests, useSWRRequest, useUser } from '../../core'

const NewslettersContainer = () => {

    const dispatch = useDispatch()
    const { newspaper } = useParams();
    const [user, token] = useUser()
    const { newsletters: data, loading, error } = useSelector(state => state.newsletters)
    const [index, setIndex] = useState(0)
    const [items, setItems] = useState([])

    useEffect(() => {
        dispatch(newsletters({ slug: newspaper }))
    }, [])


    useEffect(() => {

        if (data && data.length > 0) {
            if (user && token && items.length === data.length) {
                Requests.newsletter.isSubscribe({ id: data[index].mailup_id, auth: token }, { email: user.email }).then((response) => {
                    if (response.result) {

                        const item = { ...data[index], isSubscribe: response.result }

                        const foundIndex = items.findIndex(element => element.mailup_id === data[index].mailup_id);

                        let updatedItems = items;
                        foundIndex >= 0 ? updatedItems[foundIndex] = item : updatedItems = [...items, item]

                        setItems(updatedItems)
                    }

                    if (index + 1 < data.length) {
                        setIndex(index + 1)
                    }
                }).catch(() => {
                    if (index + 1 < data.length) {
                        setIndex(index + 1)
                    }
                })
            } else {
                setItems(data.map((item) => ({ ...item, isSubscribe: false })))
            }
        }
    }, [data, token, user, index])

    return (
        <Fragment>
            {loading && <Loading type='linear' />}
            {error && <Message severity='error' message={error} />}
            {items.map((item) =>
                <Grid item xs={12} sm={6} key={item.slug}>
                    <NewsletterCard card={item}>
                        <SubscribeContainer item={item} />
                    </NewsletterCard>
                </Grid>
            )}
            {items.length === 0 && loading === false && <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 8
                }}>
                Nessuna newsletter presente
            </Box>}
        </Fragment>
    )

}

export default NewslettersContainer