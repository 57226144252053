import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Container } from '@mui/material';

const Image = ({
    image
}) => {

    return (
        <Container maxWidth="md">
            <LazyLoadImage
                alt={image.alt}
                height='auto'
                src={`${process.env.REACT_APP_BASE_URL}/${image.url}`}
                width='100%'/>
        </Container>
    )
};

export default Image;