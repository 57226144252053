import _ from 'lodash'

export const NewspaperEndpoints = {
    basePath: '',
    endpoints: {
        find: {
            path: `/newspapers?slug={{slug}}`,
            method: 'GET',
            get: null,
            fallback: null,
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
        },
    }
}

export default NewspaperEndpoints