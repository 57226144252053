import { useState, useEffect } from 'react';


export const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);


  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= width) {
        setTargetReached(true)
      } else {
        setTargetReached(false)
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return targetReached;
};
