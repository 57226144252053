import React, { useContext, useMemo } from 'react';
import { Divider, Stack } from '@mui/material';
import PurchaseButtonContainer from '../purchase/button';
import { useSelector } from 'react-redux';
import _first from 'lodash/first'
import { KioskContext } from '../../core/context/kiosk.context';
import DateKiosk from '../../components/kiosk/date';
import PromoContainer, { Type } from '../promo/PromoCode.container';
import PriceKiosk from '../../components/kiosk/price';

const PurchaseContainer = ({
    items = []
}) => {

    const { index } = useContext(KioskContext);
    const { issue } = useSelector((state) => state.issue)

    const priceValue = useMemo(() => {
        if (issue && issue.prices) {
            return `${_first(issue.prices)?.unit_amount / 100}`
        }
        return ''
    }, [issue])

    const price = useMemo(() => {
        return _first(issue?.prices)
    }, [issue])


    return (
        <Stack spacing={2} height={1} justifyContent={'center'} alignItems={'center'} padding={2}>
            <DateKiosk date={items[index]?.publication_date} />
            <PriceKiosk text={`${priceValue} €`} />
            <PurchaseButtonContainer issue={items[index]} product={issue} price={price} type={{ label: 'Copia Singola' }} />
            <Divider sx={{ width: 1 }} />
            <PromoContainer type={Type.issue} issue={items[index]} />
        </Stack>
    )
}

export default PurchaseContainer