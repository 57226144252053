import React, { useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AccordionDetails, Stack } from "@mui/material";
import { Accordion, AccordionSummary, Typography } from "@mui/material";

export const QuestionsComponent = ({
    questions
}) => {

    const [expanded, setExpandend] = useState({})

    const handleChange = (value) => {
        setExpandend({ ...expanded, [value]: expanded[value] ? !expanded[value] : true })
    }

    return (
        <Stack spacing={1}>
            {questions.map((item, index) =>
                <Accordion key={index} onChange={() => handleChange(index)}>
                    <AccordionSummary                        
                        expandIcon={<ExpandLessIcon color={expanded[index]? 'white' : 'primary'} />}
                        isOpen={expanded[index]}
                        sx={{backgroundColor: expanded[index]? 'primary.main' : 'white.main'}}>
                        <Typography sx={{ color: expanded[index]? 'white.main' : 'primary.main'}}>
                            {item.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {item.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>)}
        </Stack>
    )
}

export default QuestionsComponent