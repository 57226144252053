import { createSlice } from '@reduxjs/toolkit'
import { getItem, removeItem, saveItem } from './local.store';
import _orderBy from 'lodash/orderBy'

// Slice
const slice = createSlice({
    name: 'catalog',
    initialState: {
        catalog: getItem('catalog'),
        selected: {
            edition: getItem('edition'),            
        }
    },
    reducers: {        
        catalogSuccess: (state, action) => {            
            state.catalog = action.payload
            state.selected.edition = action.payload.magazineEditions.length > 0? _orderBy(action.payload.magazineEditions, 'position')[0] : null
            saveItem('catalog', action.payload)
            saveItem('edition', state.selected.edition)
        },
        catalogError: (state, action) => {
            state.catalog = null
            state.error = action.error            
            removeItem('catalog')
            removeItem('edition')
        },
        selectedEdition: (state, action) => {
            state.selected.edition = action.payload.selected
            saveItem('edition', state.selected.edition)
        },
    },
});


export const {    
    catalogSuccess,
    catalogError,    
    selectedEdition,
} = slice.actions

export default slice.reducer