import styled from '@emotion/styled'
import { theme } from '../../../theme/theme'
import OutlinedInput from '@mui/material/OutlinedInput';

export const Styled = {
    OutlinedInput: styled(OutlinedInput)({
        '&.MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#096ab0'
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main
            }
        }
    })
}