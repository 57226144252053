import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
    username: Yup.string(),
    email: Yup.string(),
    password: Yup.string(),
    vat_number: Yup.string().nullable()
        .notRequired()
        .transform(x => x === '' ? undefined : x)
        .matches(/[0-9]{11}$/, "Invalid TaxID", { excludeEmptyString: true })
        .max(11, "Invalid TaxID")
})


export const FieldsProfile = [{
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'nickname',
        required: false
    },
    component: {
        label: 'Nickname',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'given_name',
        required: false
    },
    component: {
        label: 'Nome/Ragione sociale',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'family_name',
        required: false
    },
    component: {
        label: 'Cognome',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'gender',
        required: false
    },
    component: {
        label: 'Genere',
        name: 'SelectField',
        options: [{
            id: 'male',
            label: 'Maschio'
        }, {
            id: 'female',
            label: 'Femmina'
        }, {
            id: 'other',
            label: 'Altro'
        }]
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'birthdate',
        required: false
    },
    component: {
        label: 'Data di Nascita',
        name: 'EditText',
        type: 'date'
    }
}]


export const FieldsAddress = [{
    dimensions: {
        xs: 9
    },
    controller: {
        name: 'address',
        required: false
    },
    component: {
        label: 'Indirizzo',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 3
    },
    controller: {
        name: 'zip_code',
        required: false
    },
    component: {
        label: 'CAP',
        name: 'EditText',
        type: 'number'
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'city',
        required: false
    },
    component: {
        label: 'Città',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 6
    },
    controller: {
        name: 'nationality',
        required: false
    },
    component: {
        label: 'Nazione',
        name: 'SelectField',
        options: [{
            id: 'Italia',
            label: 'Italia'
        }]
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'mobile',
        required: false
    },
    component: {
        label: 'Mobile',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'phone',
        required: false
    },
    component: {
        label: 'Telefono',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'vat_number',
        required: false
    },
    component: {
        label: 'P.IVA',
        name: 'EditText',
        type: 'text'
    }
}]

export const InitialData = {
    nickname: '',
    given_name: '',
    family_name: '',
    gender: '',
    birthdate: '',
    address: '',
    zip_code: '',
    city: '',
    nationality: '',
    mobile: '',
    phone: '',
    vat_number: ''
}