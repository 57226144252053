import * as React from "react"

const Logo = () => (

    <svg id="a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 43">
        <rect fill="#ffdf33" />
        <g>
            <path d="M50.643,10.239c.458,0,.815,.242,1.07,.727,.256,.484,.383,.905,.383,1.262,0,.203-.115,.727-.345,1.568s-.483,1.784-.765,2.83c-.279,1.044-.561,2.014-.842,2.906-.279,.892-.445,1.492-.497,1.798,.357,0,1.161,.012,2.409,.038,1.25,.025,2.576,.102,3.978,.229,1.403,.127,2.627,.306,3.671,.535,1.046,.229,1.568,.548,1.568,.956,0,.459-.203,.88-.612,1.263-.407,.382-.841,.573-1.3,.573-1.784,0-3.582,.012-5.393,.038-1.809,.025-3.606,.038-5.392,.038-.662,2.243-1.339,4.486-2.027,6.73-.687,2.243-1.262,4.513-1.721,6.808-.306,.05-.522-.115-.649-.497-.127-.383-.204-.804-.229-1.263-.025-.458-.039-.765-.039-.917,0-1.786,.128-3.595,.383-5.431,.256-1.836,.511-3.646,.765-5.431h-8.337c-.713,0-1.427,.013-2.142,.038-.713,.025-1.427,.063-2.142,.115,0-.052-.012-.09-.038-.115-.024-.025-.038-.064-.038-.114,0-.357,.396-.688,1.186-.994,.791-.306,1.785-.587,2.983-.842,1.198-.256,2.422-.485,3.671-.688,1.25-.204,2.384-.37,3.403-.497,1.021-.128,1.709-.217,2.065-.268,.103-.357,.344-1.147,.727-2.371,.382-1.224,.829-2.524,1.338-3.901,.511-1.376,1.021-2.575,1.53-3.595,.51-1.021,.969-1.53,1.377-1.53Z" />
            <path d="M25.195,21.404c0-.059,0-.119,0-.178,0-2.579,.003-5.158-.002-7.738-.001-.563-.108-1.108-.417-1.593-.32-.502-.781-.778-1.372-.837q-.364-.036-.364-.403c0-2.008,0-4.017,0-6.025,0-.304,.002-.306,.307-.306,3.297,0,6.594,0,9.891,0,.293,0,.298,.005,.298,.294,0,2.044-.001,4.087,.003,6.131,0,.173-.034,.278-.228,.303-.543,.069-.95,.38-1.281,.789-.428,.53-.654,1.133-.654,1.83,.006,8.203,.004,16.405,.004,24.608q0,.395-.384,.395c-3.276,0-6.552-.002-9.827,.005-.232,0-.326-.068-.387-.295-1.221-4.548-2.451-9.093-3.679-13.64-.448-1.658-.893-3.317-1.34-4.975-.008-.031-.025-.06-.065-.085,0,.061,0,.123,0,.184,0,3.249,0,6.497,0,9.746,0,.82,.275,1.53,.862,2.11,.393,.388,.875,.583,1.419,.624,.162,.012,.221,.061,.22,.226-.004,1.959-.005,3.918,.001,5.877,0,.206-.1,.224-.262,.224-1.634-.003-3.269-.002-4.903-.002-1.881,0-3.762,0-5.643,0-.289,0-.29-.001-.29-.296,0-1.91,.002-3.819-.004-5.729,0-.208,.046-.284,.275-.305,.87-.081,1.394-.605,1.674-1.402,.141-.402,.207-.821,.207-1.244,.004-5.356,.007-10.711-.002-16.067-.001-.628-.088-1.251-.442-1.801-.3-.467-.744-.699-1.278-.764-.429-.053-.43-.042-.43-.469,0-1.987,0-3.974,0-5.962,0-.308,0-.31,.301-.31,4.17,0,8.341,.001,12.511-.004,.204,0,.296,.063,.354,.263,1.133,3.915,2.273,7.828,3.411,11.742,.472,1.622,.941,3.245,1.412,4.868,.021,.072,.048,.143,.072,.215l.031-.004Z" />
        </g>
    </svg>
)

export default Logo