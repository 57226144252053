import React from 'react';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const Stepper = ({
    items = [],
    index = 0,
    setIndex = () => { },
    children
}) => {

    const handleNext = () => {
        setIndex((index) => index + 1);
    };

    const handleBack = () => {
        setIndex((index) => index - 1);
    };

    return (
        <Stack direction={'row'} justifyContent='center' alignItems='center' width={1}>
            <Button color='primary' size="medium" onClick={handleBack} disabled={index === 0}>
                <KeyboardArrowLeft />
            </Button>
            {children}
            <Button color='primary' size="medium" onClick={handleNext} disabled={index === items.length - 1}>
                <KeyboardArrowRight />
            </Button>
        </Stack>
    )
}

export default Stepper
