import { Typography } from '@mui/material'

const titleStyle = {
    textAlign: "center",
    textTransform: "capitalize",
    width: "100%",    
}

const Subtitle = ({
    title,
}) => {


    return (
        <Typography variant='subtitle2' sx={{ ...titleStyle }}>
            {title.toUpperCase()}
        </Typography>
    )
}

export default Subtitle