import React, { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import UserButton from "../../../components/user/button";
import { Link, useParams } from "react-router-dom";
import _ from 'lodash'

const PurchaseButtonContainer = ({
    product,
    price,
    issue,
    type,
    color = 'button'
}) => {

    const { newspaper, partner } = useParams()
    const { isAuthenticated } = useAuth0()

    const link = useMemo(() => {

        if (product && price) {
            const amount = price.unit_amount ? price.unit_amount : _.first(price.tiers)?.unit_amount
            const to = partner ? `/${newspaper}/${partner}` : `/${newspaper}`
            let url = `${to}/purchase/billing?name=${product.name}&amount=${amount}&id_stripe=${product.id_stripe}&price=${price.id}&type=${type?.label}`
            if (issue) {
                url = `${url}&issue_sku=${issue.id}`
            }
            return url
        }
        return null

    }, [product, issue, price, type, newspaper, partner])


    if (!isAuthenticated) {
        return <UserButton showWelcome={false} />
    }

    return (
        link &&
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Button variant='contained' color={color} fullWidth>
                ACQUISTA
            </Button>
        </Link>
    )
}

export default PurchaseButtonContainer