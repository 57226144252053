import React from 'react'
import { Box } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { SubmitButton, ErrorField } from '../form'
import { FieldsProfile, FieldsAddress, ValidationSchema } from '../../conf/profile'
import { Components } from '../form'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

const ProfileForm = ({
    isSubmitting = false,
    initialData,
    onSubmit = () => { },
}) => {

    const { handleSubmit, control, formState } = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues: initialData
    });


    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} width={1}>
            <Typography variant='h6' sx={{ fontWeight: 'bold', margin: 2  }} textAlign='center'>
                I tuoi dati personali
            </Typography>
            <Grid container spacing={1} sx={{ padding: 1, backgroundColor: 'white.main' }}>
                {FieldsProfile.map(({ dimensions, controller, component }) => {
                    const Field = Components[component.name]
                    return (
                        <Grid item xs={dimensions.xs} key={controller.name}>
                            <Controller
                                name={controller.name}
                                control={control}
                                rules={{ required: controller.required }}
                                render={({ field }) =>
                                    <Field
                                        shrink={controller.name === 'birthdate'}
                                        required={controller.required}
                                        label={component.label}
                                        type={component.type}
                                        options={component.options}
                                        {...field} />} />
                            {formState.errors?.[controller.name] ? <ErrorField message={formState.errors?.[controller.name]?.message} /> : null}
                        </Grid>
                    )
                })}
            </Grid>

            <Typography variant='h6' sx={{ fontWeight: 'bold', margin: 2 }} textAlign='center'>
                Dati di fatturazione
            </Typography>
            <Grid container spacing={1} sx={{ padding: 1, backgroundColor: 'white.main' }}>
                {FieldsAddress.map(({ dimensions, controller, component }) => {
                    const Field = Components[component.name]
                    return (
                        <Grid item xs={dimensions.xs} key={controller.name}>
                            <Controller
                                name={controller.name}
                                control={control}
                                rules={{ required: controller.required }}
                                render={({ field }) =>
                                    <Field
                                        shrink={controller.name == 'birthdate'}
                                        required={controller.required}
                                        label={component.label}
                                        type={component.type}
                                        options={component.options}
                                        {...field} />} />
                            {formState.errors?.[controller.name] ? <ErrorField message={formState.errors?.[controller.name]?.message} /> : null}
                        </Grid>
                    )
                })}
            </Grid>

            <Grid container spacing={1} sx={{ margin: 2}}>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={4}>
                    <SubmitButton title='Salva' isSubmitting={isSubmitting} />
                </Grid>
                <Grid item xs={12} sm={4} />
            </Grid>

        </Box>
    )
}

export default ProfileForm