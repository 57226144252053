import { createTheme } from '@mui/material/styles';

let theme = createTheme({
	typography: {
		fontFamily: [
			"Roboto",
			"Helvetica",
			"Arial",			
			'Bookman',
		].join(','),
	},
	palette: {
		primary: {
			main: '#13406e',
			light: '#678cac',
		},
		secondary: {
			main: '#c73934',
			light: '#e6ac5a'
		},
		red: {
			main: '#921421',
		},
		black: {
			main: '#000000',
			secondary: '#1a1a1a'
		},
		white: {
			main: '#ffffff',
		},
		blueLight: {
			main: '#008BD2',
		},
		gray: {
			main: '#e6e6e6'
		},
		yellow: {
			main: "#f8e60c"
		},
		card: {
			main: '#678cac',
			light: '#d1dde6',
			dark: '#13406e'
		},
		background: {
			main: '#DFEAEF'
		},
		button: {
			main: '#678cac',
			contrastText: '#ffffff'
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 990,
			xl: 1260,
		},
	},
	button: {
		lineHeight: 1
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					marginBottom: 0,
					marginTop: 0,
					//fontFamily: 'PoynterOldstyleDisplay-NarRoman',

				},
				button: {
					//fontFamily: 'ITCFranklinGothicStd-Book'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					//fontFamily: 'ITCFranklinGothicStd-Book'
				}
			}
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: 0,
					paddingBottom: '4px',
					paddingTop: '4px',
					paddingLeft: 0,
					":last-child": {
						paddingBottom: '4px',
						paddingTop: '4px',
					}
				},

			}
		},

	}
});

//theme = responsiveFontSizes(theme);

export { theme };
