import React, { Fragment } from 'react';
import { Message, Loading } from '../../components/common';
import ProfileUpdateContainer from './ProfileUpdate.container';
import { useSelector } from 'react-redux';

const ProfileContainer = ({
    onSave = () => { },
}) => {

    const { user, loading, error } = useSelector(state => state.user)

    return (
        <Fragment>
            {loading && <Loading type='linear' />}
            {error && <Message severity='error' message={error} />}
            {user && <ProfileUpdateContainer initialData={user} onSave={onSave} />}
        </Fragment>
    )
}

export default ProfileContainer