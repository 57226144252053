import React from 'react'
import { Button } from '@mui/material'

export const SubscribeButton = ({
	label,
	onClick,
	disabled,
	type
}) => {
	return (
		<Button
			color="primary"
			variant="contained"
			onClick={onClick}
			disabled={disabled}
			type={type}>
			{label}
		</Button>
	)
}

export default SubscribeButton