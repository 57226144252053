import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import UserButton from "../../../components/user/button";
import { useNavigate, useParams } from "react-router-dom";
import _ from 'lodash'
import { ValidationSchema } from "../../../conf/under25";
import { checkTaxCode } from "../../under25/helpers";
import ModalForm from "../../../components/under25form/ModalForm.component";
import { ErrorField } from "../../../components/form";
import { useUser, Requests } from "../../../core";
import { useDispatch } from "react-redux";
import { me } from "../../../store/user.store";

const PurchaseUnder25ButtonContainer = ({
    product,
    price,
    type,
}) => {

    const dispatch = useDispatch()
    const [error, setError] = useState({ message: "" });
    const [success, setSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const { newspaper, partner } = useParams()
    const { isAuthenticated } = useAuth0()
    const [user, token] = useUser()
    const navigate = useNavigate()

    const clickSubmit = (data) => {

        if (token) {
            const values = {
                auth0: user.sub,
                given_name: data.firstName,
                family_name: data.lastName,
                city: data.birthPlace,
                gender: data.gender === 'M' ? 'male' : 'female',
                birthdate: data.birthDate
            }
            Requests.profile.update({ auth0: user.sub, auth: token, ...values }).then(() => dispatch(me({ auth: token })))
        }

        ValidationSchema
            .validate(data)
            .then((validData) => {
                checkTaxCode(validData, setSuccess, setError);
            })
            .catch((error) => {
                setSuccess(false);
                setError({ message: error.message });
            });
    }

    const link = useMemo(() => {

        if (product && price) {
            const amount = price.unit_amount ? price.unit_amount : _.first(price.tiers)?.unit_amount
            const to = partner? `/${newspaper}/${partner}` : `/${newspaper}`
            let url = `${to}/purchase/billing?name=${product.name}&amount=${amount}&id_stripe=${product.id_stripe}&price=${price.id}&type=${type?.label}`
            return url
        }
        return null

    }, [product, price, type, newspaper, partner])

    useEffect(() => {
        if (success && link) {
            setOpen(false)
            navigate(link)
        }
    }, [success, link])

    if (!isAuthenticated) {
        return <UserButton showWelcome={false} />
    }

    return (
        <Fragment>
            <Button variant="contained" onClick={() => setOpen(true)} fullWidth color="button">
                Acquista
            </Button>
            <ModalForm clickSubmit={clickSubmit} open={open} setOpen={setOpen}>
                {error.message && <div style={{ textAlign: 'center' }}><ErrorField message={error.message} /></div>}
            </ModalForm>
        </Fragment>

    )
}

export default PurchaseUnder25ButtonContainer