import React, { useEffect, Fragment } from 'react';
import { Message, Loading } from '../../components/common';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { useUser } from '../../core';
import ProfileSubscription from '../../components/profile/ProfileSubscription.component';
import { subscriptions } from '../../store/subscriptions.store';

const ProfileSubscriptionsContainer = () => {

    const dispatch = useDispatch()
    const { newspaper } = useParams()
    const [user, token] = useUser()
    const { subscriptions: items, loading, error } = useSelector(state => state.subscriptions)

    useEffect(() => {
        token && user && dispatch(subscriptions({ auth: token, newspaper: newspaper, auth0: user.sub }))
    }, [user, token])


    return (
        <Fragment>
            {loading && <Loading type='linear' />}
            {error && <Message severity='error' message={error} />}
            <ProfileSubscription subscriptions={items} />
        </Fragment>
    )
}

export default ProfileSubscriptionsContainer