import { Box, Container, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import PurchaseStepperContainer from "../../containers/purchase/stepper";
import { Page } from '../../components/common'

const PurchasePage = () => {

    return (
        <Page title='Pagamento'>
            <Stack spacing={2} alignItems='center'>
                <Container component='main' maxWidth="md">
                    <PurchaseStepperContainer />
                    <Box mt={4} sx={{ backgroundColor: 'white.main'}} padding={2}>
                        <Outlet />
                    </Box>                    
                </Container>
            </Stack>
        </Page>
    )
}

export default PurchasePage