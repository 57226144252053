import React from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';

const MenuButton = ({
    toggleMenu = () => { },
    open
}) => {

    return (
        <IconButton onClick={toggleMenu} color='primary'>
            {open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
    )
}

export default MenuButton