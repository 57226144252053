import { Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileContainer from "../../containers/profile/Profile.container";
import useQuery from "../../core/hooks/useQuery";

const BillingPage = () => {

    const { newspaper, partner } = useParams()
    const query = useQuery()
    const navigate = useNavigate()

    const link = useMemo(() => {
        return partner? `/${newspaper}/${partner}/purchase/payment?${query.toString()}` : `/${newspaper}/purchase/payment?${query.toString()}`
    }, [newspaper, partner, query])                                

    const onSave = useCallback(() => {
        navigate(link)
    }, [link])

    return (
        <Stack spacing={2}>
            <Typography variant='h6'>
                Se ti occorre la fattura, verifica o compila i tuoi dati e clicca su salva per poi proseguire con il pagamento.
            </Typography>
            <Typography>
                Oppure vai direttamente al <Link to={link}>pagamento</Link>
            </Typography>
            <ProfileContainer onSave={onSave}/>
        </Stack>
    )
}

export default BillingPage