import { Box, Container, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useQuery from "../../core/hooks/useQuery";
import { Requests, useUser } from '../../core';
import { useParams } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PurchaseContainer from "../../containers/purchase/form";
import { Loading, Message } from "../../components/common";

const style = {
    appearance: {
        variables: {
            fontFamily: 'Roboto, sans-serif',
            fontSizeBase: '14px',
            borderRadius: '2px',
        },
        rules: {
            '.Input': {
                borderColor: '#BBBBBB',
                marginTop: '10px',
                padding: '13px',
                colorText: '#EEEEEE',
            },
            '.Input:focus': {
                borderColor: '#BBBBBB',
                boxShadow: 'none',
            },
            '.Label': {
                marginTop: '10px',
                color: '#90A0B7'
            }
        }
    },
}

const PaymentPage = () => {

    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { newspaper } = useParams()
    const [user, token] = useUser()

    // Query
    const query = useQuery()
    const price = query.get('price')
    const issue_sku = query.get('issue_sku')
    const name = query.get('name')
    const id_stripe = query.get('id_stripe')

    // GTag Manager
    const gtag = { currency: "EUR", value: 0, items: [{ item_id: id_stripe, item_name: name, price: 0, quantity: 1 }] }


    // Stripe
    const options = useMemo(() => response ? { ...style, clientSecret: response.clientSecret } : {}, [response])
    const stripePromise = useMemo(() => response ? loadStripe(process.env.REACT_APP_STRIPE_KEY, { stripeAccount: response.accountId }) : null, [response])


    useEffect(() => {

        if (user && token && newspaper) {
            setLoading(true)

            const body = { auth: token, price, newspaper }
            
            Requests.purchase.buy(issue_sku ? { ...body, issue_sku } : { ...body })
                .then((data) => {
                    setResponse(data)
                    setLoading(false)
                    window.gtag("event", "add_to_cart", {...gtag});
                })
                .catch((error) => {
                    setLoading(false)
                    setError(error?.response?.data?.statusCode === 400?  `Hai già un abbonamento attivo per questo prodotto!` : `Errore generico. Contattare l'assistenza`)                    
                })
        }
    }, [token])

    return (
        <Stack>
            {error && <Message severity='error' message={error} />}
            {loading && <Loading type='backdrop'/>}
            {response && stripePromise && options &&
                <Container maxWidth="sm">
                    <Box p={3} sx={{ minHeight: "60vh" }}>
                        <Elements stripe={stripePromise} options={options}>
                            <PurchaseContainer />
                        </Elements>
                    </Box>
                </Container>
            }
        </Stack>
    )
}

export default PaymentPage