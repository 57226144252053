import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { useUser } from '../../core';
import { subscriptions } from '../../store/subscriptions.store';
import _find from 'lodash/find'


const ProductsWarningContainer = () => {

    const dispatch = useDispatch()
    const { newspaper, partner } = useParams()
    const [user, token] = useUser()
    const { subscriptions: items } = useSelector(state => state.subscriptions)

    useEffect(() => {
        token && user && dispatch(subscriptions({ auth: token, newspaper: newspaper, auth0: user.sub }))
    }, [user, token, dispatch, newspaper])

    const to = useMemo(() => {
        return partner ? `/${newspaper}/${partner}/account/acquisti` : `/${newspaper}/account/acquisti`
    }, [newspaper, partner])


    const showAlert = useMemo(() => items.length > 0 && _find(items, (i) => i.is_active === true), [items])

    return (
        <Box sx={{ padding: 1 }} width={1}>
            {showAlert &&
                <Link to={to} style={{ color: 'red' }}>
                    <Typography variant='subtitle1' color='error' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        NB: Hai già uno o più abbonamenti attivi! Clicca qui per visualizzarli
                    </Typography>
                </Link>
            }
        </Box>

    )
}

export default ProductsWarningContainer