import React, { Fragment, useCallback, useState } from 'react'
import ProfileForm from '../../components/profile/ProfileForm.component';
import { Requests, useUser } from '../../core'
import { Message, Loading } from '../../components/common';
import { useDispatch } from 'react-redux';
import { me } from '../../store/user.store';

const ProfileUpdateContainer = ({
    initialData,
    onSave = () => { },
}) => {

    const dispatch = useDispatch()
    const [user, token] = useUser()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isSubmitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)

    // Submit Update
    const onSubmit = async (values) => {
        setSubmitting(true)
        setLoading(true)
        setSuccess(false)

        if (token) {
            const data = { auth0: user.sub, ...values }
            Requests.profile.update({ auth0: user.sub, auth: token, ...data }).then((response) => onSuccess(response)).catch((error) => onFailure(error))
        } else {
            onFailure()
        }
    }

    const onSuccess = useCallback(() => {
        setSubmitting(false)
        setLoading(false)
        setError(null)
        setSuccess(true)
        dispatch(me({ auth: token }))
        onSave();
    }, [token])

    const onFailure = useCallback((error) => {
        setSubmitting(false)
        setLoading(false)
        setSuccess(false)
        const data = error?.response?.data
        setError(data?.message ? data.message : 'Errore generico')

    }, [])


    return (
        <Fragment>
            {loading && <Loading type='linear' />}
            {success && <Message severity='success' message='Utente modificato con successo' />}
            {error && <Message severity='error' message={error} />}
            {initialData &&
                <ProfileForm
                    initialData={initialData}
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit} />}
        </Fragment>
    )
}

export default ProfileUpdateContainer