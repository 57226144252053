import auth from './auth'
import users from './users'
import profile from './profile'
import cms from './cms'
import newsletter from './newsletter'
import product from './product'
import subscription from './subscription'
import offer from './offer'
import issue from './issue'
import purchase from './purchase'
import kiosk from './kiosk'
import newspaper from './newspaper'

const settings = {
    baseURL: ''
}

export const Endpoints = {
    auth,
    users,
    profile,
    cms,
    newsletter,
    product,
    subscription,
    offer,
    issue,
    purchase,
    kiosk,
    newspaper
}

Object.defineProperty(Endpoints, 'baseURL', {
    enumerable: false,
    get() {
        return settings.baseURL
    },
    set(baseURL) {
        return settings.baseURL = baseURL
    }
})

export default Endpoints