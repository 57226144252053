const month = {
    interval: "month",
    interval_count: 1,
}

const three_month = {
    interval: "month",
    interval_count: 3,
}

const six_month = {
    interval: "month",
    interval_count: 6,
}

const year = {
    interval: 'year',
    interval_count: 1,
}

const coupons360 = {
    interval: 'year',
    interval_count: 360,
}

const coupons180 = {
    interval: 'year',
    interval_count: 180,
}
const coupons90 = {
    interval: 'year',
    interval_count: 90,
}

const coupons30 = {
    interval: 'year',
    interval_count: 30,
}

export const Full = [{
    title: 'Mensile',
    interval: 'i primi 3 mesi'.toUpperCase(),
    other_interval: 'al mese'.toUpperCase(),
    info: 'Annulli il rinnovo automatico quando vuoi',
    period: month,
    tag: 'full'
}, {
    title: 'Trimestrale',
    interval: 'il primo trimestre'.toUpperCase(),
    other_interval: 'a trimestre'.toUpperCase(),
    info: 'Annulli il rinnovo automatico quando vuoi',
    period: three_month,
    tag: 'full'
}, {
    title: 'Semestrale',
    interval: 'il primo semestre'.toUpperCase(),
    other_interval: 'a semestre'.toUpperCase(),
    info: 'Annulli il rinnovo automatico quando vuoi',
    period: six_month,
    tag: 'full'
}, {
    title: 'Annuale',
    interval: 'il primo anno'.toUpperCase(),
    other_interval: `all'anno`.toUpperCase(),
    info: 'Annulli il rinnovo automatico quando vuoi',
    period: year,
    tag: 'full'
}]


export const Others = [{
    title: '',
    info: '',
    period: null,
    tag: 'dummy'
}, {
    title: 'Sito Web',
    info: 'Annulli il rinnovo automatico quando vuoi',
    interval: 'Annuale'.toUpperCase(),
    other_interval: 'NESSUNA VARIAZIONE DI PREZZO AL RINNOVO'.toUpperCase(),
    period: year,
    tag: 'web'
}, {
    title: 'Copia singola',
    info: '',
    interval: 'Quotidiano'.toUpperCase(),
    other_interval: '(LEGGI DA PC, TABLET E SMARTPHONE)'.toUpperCase(),
    tag: 'reader'
}, {
    title: '',
    info: '',
    period: null,
    tag: 'dummy'
}]


export const Under25 = [{
    title: 'Under 25',
    interval: 'il primo anno'.toUpperCase(),
    other_interval: `all'anno`.toUpperCase(),
    info: 'Annulli il rinnovo automatico quando vuoi',
    period: year,
    tag: 'full'
}]

export const Papers7d = [{
    title: 'Annuale',
    interval: `tutto l'anno`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: year,
    tag: 'paper7d'
}, {
    title: 'Semestrale',
    interval: `6 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: six_month,
    tag: 'paper7d'
}, {
    title: 'Trimestrale',
    interval: `3 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: three_month,
    tag: 'paper7d'
},]

export const Papers6d = [{
    title: 'Annuale',
    interval: `tutto l'anno`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: year,
    tag: 'paper6d'
}, {
    title: 'Semestrale',
    interval: `6 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: six_month,
    tag: 'paper6d'
}, {
    title: 'Trimestrale',
    interval: `3 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: three_month,
    tag: 'paper6d'
},]

export const Papers5d = [{
    title: 'Annuale',
    interval: `tutto l'anno`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: year,
    tag: 'paper5d'
}, {
    title: 'Semestrale',
    interval: `6 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: six_month,
    tag: 'paper5d'
}, {
    title: 'Trimestrale',
    interval: `3 mesi`.toUpperCase(),
    other_interval: ``,
    info: '',
    period: three_month,
    tag: 'paper5d'
}]

export const PapersCoupons = [{
    title: '360 coupons',
    interval: 360,
    other_interval: ``,
    info: '',
    period: coupons360,
    tag: 'coupon'
}, {
    title: '180 coupons',
    interval: 180,
    other_interval: ``,
    info: '',
    period: coupons180,
    tag: 'coupon'
}, {
    title: '90 coupons',
    interval: 90,
    other_interval: ``,
    info: '',
    period: coupons90,
    tag: 'coupon'
}, {
    title: '30 coupons',
    interval: 30,
    other_interval: ``,
    info: '',
    period: coupons30,
    tag: 'coupon'
}]