import { Typography } from '@mui/material'

const titleStyle = {
    textAlign: "center",
    textTransform: "capitalize",
    width: "100%",
    fontWeight: 'bold',
    color: 'primary.light'
}

const Header = ({
    title,
}) => {


    return (
        <Typography variant='h6' sx={{ ...titleStyle }}>
            {title.toUpperCase()}
        </Typography>
    )
}

export default Header