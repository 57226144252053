import React, { useEffect } from 'react'
import { Box, Container, Stack } from '@mui/material'
import ProductsContainer from '../containers/product/Products.container'
import { Page } from '../components/common'
import PromoContainer, { Type } from '../containers/promo/PromoCode.container'
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import DividerSection from '../components/cards/components/divider'
import ProductsWarningContainer from '../containers/product/ProductsWarning.container'
import { products } from '../store/products.store'
import { productsSpecial } from '../store/productsSpecial.store'
import { Full, Others, Under25 } from '../conf/products'
import ContactsContainer, { SubscriptionsType } from '../containers/contacts'

const SubscriptionsPage = () => {

    const dispatch = useDispatch()
    const { newspaper } = useParams();

    useEffect(() => {
        dispatch(products({ slug: newspaper }))
    }, [newspaper, dispatch])

    useEffect(() => {
        dispatch(productsSpecial({ slug: newspaper }))
    }, [newspaper, dispatch])

    return (
        <Page title='Scopri tutte le nuove tariffe' subtitle='e scegli quella che fa per te'>

            <ProductsWarningContainer />

            <Container component='section' maxWidth="xl" sx={{ pb: 2 }}>

                <ProductsContainer store='products' conf={Full} tag='full'>
                    <DividerSection
                        title='Quotidiano digitale + Sito Web'
                        subtitle='Offerte valide per utenti privati' />
                </ProductsContainer>

                <ProductsContainer store='products' conf={Others}>
                    <DividerSection
                        title='Altri Prodotti'
                        subtitle='Offerte valide per utenti privati' />
                </ProductsContainer>

            </Container>

            <Box width={1} bgcolor={'secondary.light'} sx={{ pb: 2 }}>
                <Container component='section' maxWidth="xl">
                    <ProductsContainer store='products_special' conf={Under25}>
                        <DividerSection
                            title='Le Nostre Promozioni'
                            subtitle='Offerte valide per utenti privati'
                            titleColor='white'
                            dividerColor='white'
                            subtitleColor='white' />
                    </ProductsContainer>
                </Container>
            </Box>

            <Box width={1} bgcolor={'white.main'} sx={{ pb: 2 }}>
                <Container component='section' maxWidth="sm">
                    <PromoContainer type={Type.subscription}>
                        <DividerSection title='Hai un codice promozionale?' />
                    </PromoContainer>
                </Container>
            </Box>


            <Container component='section' maxWidth="xl">
                <Stack spacing={1}>
                    <DividerSection title='Abbonamento al quotidiano cartaceo' subtitle='PUOI RITIRARLO NELLA TUA EDICOLA DI FIDUCIA' subtitleColor='primary' link={`/${newspaper}/cartaceo`} >
                        <ContactsContainer type={SubscriptionsType.Paper}/>
                    </DividerSection>
                    <DividerSection title='Abbonamenti per aziende e PA' subtitle='TI INVIEREMO LE NOSTRE OFFERTE PER LA TUA AZIENDA E PER LE PUBBLICHE AMMINISTRAZIONI' subtitleColor='primary'>
                        <ContactsContainer type={SubscriptionsType.PA}/>
                    </DividerSection>
                </Stack>
            </Container>

        </Page>
    )

}

export default SubscriptionsPage