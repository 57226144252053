import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Requests } from '../core';
import { subscriptions, subscriptionsError, subscriptionsSuccess } from '../store/subscriptions.store';
import { newsletters, newslettersError, newslettersSuccess } from '../store/newsletters.store';
import { prices, pricesError, pricesSuccess, products, productsError, productsSuccess } from '../store/products.store';
import { me, logoutSuccess, logout, meSuccess, errorUser } from '../store/user.store';
import { offer, offerError, offerSuccess } from '../store/offer.store';
import { issue, issueError, issueSuccess } from '../store/issue.store';
import { purchases, purchasesError, purchasesSuccess } from '../store/purchase.store';
import { newspaper, newspaperError, newspaperSuccess } from '../store/newspaper.store';
import _ from 'lodash'
import { pricesSpecial, pricesSpecialError, pricesSpecialSuccess, productsSpecial, productsSpecialError, productsSpecialSuccess } from '../store/productsSpecial.store';

/* User */
function* meUser(action) {

    try {
        const user = yield call(Requests.profile.me, { ...action.payload })
        yield put({ type: meSuccess.type, user });
    } catch (e) {
        yield put({ type: errorUser.type, error: e?.response?.data });
    }
}

function* logoutUser() {

    try {
        yield put({ type: logoutSuccess.type });
    } catch (e) {
        yield put({ type: errorUser.type, error: e });
    }
}



/* Newsletters */
function* getNewsletters(action) {

    try {
        const newsletters = yield call(Requests.newsletter.all, { ...action.payload })
        yield put({ type: newslettersSuccess.type, newsletters });
    } catch (e) {
        yield put({ type: newslettersError.type, error: e?.response?.data });
    }
}



/* Products */
function* getProducts(action) {

    try {
        const products = yield call(Requests.product.all, { ...action.payload })
        yield put({ type: productsSuccess.type, products });
    } catch (e) {
        yield put({ type: productsError.type, error: e?.response?.data });
    }
}

function* getPrices(action) {

    const { id_stripe, id, newspaper } = action.payload
    try {
        const prices = id_stripe? yield call(Requests.product.prices, { id_stripe, newspaper }) : yield call(Requests.product.prices_papers, { id, newspaper })
        yield put({ type: pricesSuccess.type, product: id_stripe || id, prices: prices });
    } catch (e) {
        console.log(e)
        yield put({ type: pricesError.type, error: e?.response?.data });
    }
}

function* getProductsSpecial(action) {

    try {
        const products = yield call(Requests.product.all_special, { ...action.payload })
        yield put({ type: productsSpecialSuccess.type, products });
    } catch (e) {
        yield put({ type: productsSpecialError.type, error: e?.response?.data });
    }
}

function* getPricesSpecial(action) {

    const { id_stripe, newspaper } = action.payload
    try {
        const prices = yield call(Requests.product.prices, { id_stripe, newspaper })
        yield put({ type: pricesSpecialSuccess.type, product: id_stripe, prices: prices });
    } catch (e) {
        console.log(e)
        yield put({ type: pricesSpecialError.type, error: e?.response?.data });
    }
}


function* getSubscriptions(action) {

    const { auth, newspaper, auth0 } = action.payload

    try {
        const products = yield call(Requests.product.all, { slug: newspaper })
        const subscriptions = yield call(Requests.subscription.all, { auth, newspaper, auth0 })

        const skus = _.flatten(_.map(products, (product) => product.product_skus))

        const filter = _.orderBy(_.map(subscriptions, (i) => ({
            id: i.id,
            is_active: i.is_active,
            can_download_pdf: i.can_download_pdf,
            created_at: i.created_at,
            current_period_end: i.current_period_end,
            current_period_start: i.current_period_start,
            subscription_authorization_type: i.subscription_authorization_type,
            subscription_redeem_code: i.subscription_redeem_code,
            status: i.status,
            price: _.find(skus, (o) => i.sku === o.sku) ? _.find(skus, (o) => i.sku === o.sku) : null
        })), 'created_at', 'desc')



        const status = ['active', 'canceled', 'trialing', 'past_due', 'unpaid']

        yield put({ type: subscriptionsSuccess.type, subscriptions: _.filter(filter, (i) => _.includes(status, i.status) && i.price !== null) });
    } catch (e) {
        yield put({ type: subscriptionsError.type, error: e?.response?.data });
    }
}

function* getPurchases(action) {

    const { auth, newspaper } = action.payload

    try {
        const purchases = yield call(Requests.purchase.all, { auth, newspaper })
        yield put({ type: purchasesSuccess.type, purchases: purchases });
    } catch (e) {
        yield put({ type: purchasesError.type, error: e?.response?.data });
    }
}


function* getOffer(action) {

    const { newspaper } = action.payload
    try {
        const offer = yield call(Requests.offer.all, { newspaper })

        yield put({ type: offerSuccess.type, offer });
    } catch (e) {
        console.log(e)
        yield put({ type: offerError.type, error: e?.response?.data });
    }
}


/* Issue */
function* getIssue(action) {

    try {
        const issue = yield call(Requests.issue.all, { ...action.payload })
        if (issue.length > 0) {
            const prices = yield call(Requests.issue.prices, { id_stripe: issue[0].id_stripe, newspaper: issue[0].newspaper.slug })
            yield put({ type: issueSuccess.type, payload: { ...issue[0], prices } });
        } else {
            yield put({ type: issueSuccess.type, payload: null });
        }
    } catch (e) {
        yield put({ type: issueError.type, error: e?.response?.data });
    }
}

/* Newspaper */
function* getNewspaper(action) {

    try {
        const newspaper = yield call(Requests.newspaper.find, { ...action.payload })
        if (newspaper.length > 0) {
            yield put({ type: newspaperSuccess.type, newspaper: newspaper[0] });
        } else {
            yield put({ type: newspaperSuccess.type, payload: null });
        }       
    } catch (e) {
        yield put({ type: newspaperError.type, error: e?.response?.data });
    }
}

/* Saga */
function* sagas() {

    /* User */
    yield takeLatest(me.type, meUser);
    yield takeLatest(logout.type, logoutUser);

    /* Newsletters */
    yield takeLatest(newsletters.type, getNewsletters);

    /* Products */
    yield takeLatest(products.type, getProducts);
    yield takeLatest(productsSpecial.type, getProductsSpecial);

    /* Prices */
    yield takeEvery(prices.type, getPrices);
    yield takeEvery(pricesSpecial.type, getPricesSpecial);

    /* Subscriptions */
    yield takeEvery(subscriptions.type, getSubscriptions);

    /* Purchases */
    yield takeEvery(purchases.type, getPurchases);

    /* Offer */
    yield takeLatest(offer.type, getOffer);

    /*Issue */
    yield takeLatest(issue.type, getIssue);

    /*Newspaper */
    yield takeLatest(newspaper.type, getNewspaper);

}

export default sagas;