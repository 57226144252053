import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { catalogSuccess } from "../../store/catalog.store";
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import { DateTime } from 'luxon'
import { catalogConf } from '../../conf/catalog'


const useCatalog = (date) => {

    const dispatch = useDispatch()
    const { catalog, selected: { edition } } = useSelector((state) => state.catalog)

    const { newspaper } = useParams()

    const [loading, setLoading] = useState(false)
    const [editions, setEditions] = useState([])
    const [issueToday, setIssueToday] = useState(null)
    const [issueOthers, setIssueOthers] = useState([])


    // Get Catalog
    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_BASE_URL_CATALOG}vp3catalog/v3/main/${catalogConf[newspaper]}/web/catalog/full`)
            .then((response) => {
                dispatch(catalogSuccess(response.data))
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                dispatch(catalogSuccess(null))
                throw err
            })
    }, [])


    // Get Editions
    useEffect(() => {
        if (catalog) {
            const issues = _filter(catalog.issues, (issue) => issue.publication_date === DateTime.fromISO(date).toFormat('yyyy-MM-dd'))
            const editions = issues.map((issue) => {
                const currentEdition = _find(catalog.magazineEditions, (item) => issue.magazine_edition_id === item.id)
                return ({ name: currentEdition.name, id: currentEdition.id })
            });
            setEditions(_orderBy(editions, 'name'))
        }
    }, [catalog, setEditions])


    // Get Main Issue
    useEffect(() => {

        if (catalog && edition) {
            const item = _find(catalog.issues, (issue) => issue.magazine_edition_id === edition.id && issue.publication_date === DateTime.now().toFormat('yyyy-MM-dd'))
            setIssueToday(item)
        }

    }, [catalog, edition, setIssueToday, issueToday])

    // Get Others Issues
    useEffect(() => {
        if (catalog && edition) {
            const issues = _filter(catalog.issues, (issue) => issue.magazine_edition_id === edition.id && issue.publication_date !== DateTime.now().toFormat('yyyy-MM-dd'))
            setIssueOthers(_orderBy(issues, 'publication_date', 'desc'))
        }

    }, [catalog, edition, setIssueOthers])



    return { loading, editions, issueToday, issueOthers }
}

export default useCatalog