import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'newspaper',
    initialState: {        
        loading: false,
        error: null,        
        newspaper: null,        
    },
    reducers: {
        newspaper: (state) => {
            state.error = null
            state.loading = true            
            state.newspaper = null
        },                
        newspaperSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.newspaper = action.newspaper
        },
        newspaperError: (state, action) => {              
            state.newspaper = null   
            state.error = action.error
            state.loading = false
        }        
    },
});


export const {
    newspaper,
    newspaperSuccess,
    newspaperError    
} = slice.actions

export default slice.reducer