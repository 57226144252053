import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'products_special',
    initialState: {
        loading: false,
        error: null,
        data: [],
    },
    reducers: {
        productsSpecial: (state) => {
            state.error = null
            state.loading = true
            state.data = []
        },
        productsSpecialSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.data = action.products.map((p) => ({
                ...p,
                prices: []
            }))
        },
        productsSpecialError: (state, action) => {
            state.data = []
            state.error = action.error
            state.loading = false
        },
        pricesSpecial: (state) => {
            state.error = null
            state.loading = true
        },
        pricesSpecialSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.data =  state.data.map((p) => p.id_stripe === action.product ? ({ ...p, prices: action.prices }) : ({ ...p }))
        },
        pricesSpecialError: (state, action) => {            
            state.error = action.error
            state.loading = false
        }
    },
});


export const {
    productsSpecial,
    productsSpecialSuccess,
    productsSpecialError,
    pricesSpecial,
    pricesSpecialSuccess,
    pricesSpecialError,
} = slice.actions

export default slice.reducer