import { Divider, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const DividerSection = ({
    title = '',
    subtitle = '',
    children,
    link,
    titleColor = 'primary',
    dividerColor = 'primary',
    subtitleColor = 'primary',
}) => {

    return (
        <Stack width={1} marginTop={4} marginBottom={2}>
            <Typography variant='h4' color={`${titleColor}.main`} textAlign='center' fontFamily={'Bookman'}>
                {title}
            </Typography>
            <Divider sx={{ backgroundColor: `${dividerColor}.main` }} />
            <Typography variant={'subtitle2'} color={`${subtitleColor}.main`} textAlign='center'>
                {subtitle.toUpperCase()}
            </Typography>
            {children}
            {link &&
                <Typography variant={'button'} textAlign='end'>
                    <Link to={link} style={{ color: 'black' }}>
                        {`Scopri di più`.toUpperCase()}
                    </Link>
                </Typography>
            }
        </Stack>
    )
}

export default DividerSection