import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import CardContainer from '../Card/Card.container';
import _find from 'lodash/find'

const ProductContainer = ({
    data,    
    item
}) => {

    const product = useMemo(() => _find(data, (i) => i.product_type === item.tag), [data, item.tag])

    if (product) {
        return (
            <CardContainer product={product} conf={item} />
        )
    }

    return (
        <Box sx={{ width: 230 }} />
    )
}

export default ProductContainer