import React, { useEffect } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation , Navigate } from 'react-router-dom'

export function PrivateRoute({    
    children,    
}) {

    const { isAuthenticated, isLoading } = useAuth0();
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10)                
    }, [location])
    
    if(isLoading){
        return <div>Loading...</div>
    }

    if (!isAuthenticated) {
        return <Navigate to='/' replace={true} />
    }

    return children
}


export default PrivateRoute