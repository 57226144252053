import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Loading, Message } from '../../components/common';
import ProductContainer from './Product.container';
import { Stack } from '@mui/material';
import _find from 'lodash/find'
import Image from '../../components/image';

const ProductsContainer = ({
    store,    
    conf,
    tag,
    children
}) => {

    const { data, loading, error } = useSelector(state => state[store])
    const img = useMemo(() => {
        if(tag){
            const product = _find(data, (i) => i.product_type === tag)
            return product?.banner_image || null
        }
    }, [data, tag])

    return (
        <Fragment>
            {img && <Image image={img}/>}
            {children}            
            {loading && <Loading type='linear' />}
            {error && <Message severity='error' message={error} />}
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 4 }} justifyContent={'space-evenly'} alignItems={'center'}>                
                {conf.map((item, index) => <ProductContainer key={`${item.tag}_${index}`} data={data} item={item} />)}
            </Stack>
        </Fragment>
    )

}

export default ProductsContainer