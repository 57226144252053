import React from "react";
import { Stack } from '@mui/material'
import Container from "./components/container";
import Header from "./components/header";
import Price from "./components/price";
import Subtitle from "./components/subtitle";
import Info from "./components/info";

const Card = ({
    title = '',
    subtitle,
    info,
    price,
    prices,
    height,
    children
}) => {

    return (
        <Container height={height}>
            <Stack width={1} spacing={1}>
                {title && <Header title={title} />}
                {subtitle && <Subtitle title={subtitle.toUpperCase()} />}
            </Stack>

            <Price price={price} />

            {prices &&
                <Stack>
                    {prices.map((price, index) => <Price key={index} price={price} index={index} />)}
                </Stack>}
            <Stack>
                <Info title={info} />
                {children}
            </Stack>
        </Container>
    )
}

export default Card