import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import useQuery from "../../core/hooks/useQuery";
import DoneIcon from '@mui/icons-material/Done';
import { Requests, useSWRRequest } from "../../core";
import { useParams } from "react-router-dom";
import _ from 'lodash'
import { AppleButton, GooglePlayButtonButton, ReaderButton, SiteButton } from "../../components/common/buttons";

const SuccessPage = () => {

    const query = useQuery()
    const { newspaper } = useParams()
    const { data } = useSWRRequest(Requests.product.all_products_and_special, { slug: newspaper })
    const product = useMemo(() => _.find(data, (i) => i.id_stripe === query.get('id_stripe')), [data])

    useEffect(() => {
        if (product && query) {
            const gtag = { transaction_id: query.get('payment_intent'), currency: "EUR", value: 0, items: [{ item_id: product.id, item_name: product.name, price: 0, currency: "EUR", quantity: 1 }] }
            window.gtag("event", "purchase", { ...gtag });
        }
    }, [query, product])

    return (
        <Stack justifyContent={'center'} width={1} alignItems='center' spacing={2}>
            <DoneIcon sx={{ fontSize: 80 }} color='success' />
            {product &&
                <Stack justifyContent={'center'} width={1} alignItems='center'>
                    <Typography variant="h5">
                        {`Grazie per aver acquistato ${product.label}`}
                    </Typography>                    
                </Stack>
            }

            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <SiteButton />
                </Grid>                
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <ReaderButton />
                </Grid>                
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <AppleButton />
                </Grid>                
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <GooglePlayButtonButton />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default SuccessPage