import { Button } from '@mui/material'
import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import PurchaseButtonContainer from '../../../../containers/purchase/button'
import PurchaseUnder25ButtonContainer from '../../../../containers/purchase/under_25'

const Action = ({
    productStripe,
    priceStripe,
    period,
}) => {

    const { newspaper, partner } = useParams()
    const to = useMemo(() => {
        return partner ? `/${newspaper}/${partner}` : `/${newspaper}`
    }, [newspaper, partner])


    const CTA = useMemo(() => {


        if (productStripe.product_special && productStripe.product_special.unique_code === 'under_25') {            
            return (
                <PurchaseUnder25ButtonContainer
                    product={productStripe}
                    price={priceStripe}
                    type={period} />
            )
        }

        if (productStripe.product_type === 'reader') {
            return (
                <Link to={`${to}/edicola`} style={{ textDecoration: 'none' }}>
                    <Button color='button' variant="contained" fullWidth>
                        {`Vai all'edicola`}
                    </Button>
                </Link>
            )
        } else {
            return (
                <PurchaseButtonContainer
                    product={productStripe}
                    price={priceStripe}
                    type={period}
                    color='button' />
            )
        }

    }, [productStripe, priceStripe, period, to])



    return CTA
}

export default Action