import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'issue',
    initialState: {
        loading: false,
        error: null,
        issue: null,
    },
    reducers: {
        issue: (state) => {
            state.error = null
            state.loading = true
            state.issue = null
        },
        issueSuccess: (state, action) => {            
            state.error = null
            state.loading = false
            state.issue = {...action.payload}
        },
        issueError: (state, action) => {
            state.issue = null
            state.error = action.error
            state.loading = false
        },        
    },
});


export const {
    issue,
    issueSuccess,
    issueError,
} = slice.actions

export default slice.reducer