import React, { useEffect } from 'react'
import { Box, Container } from '@mui/material'
import ProductsContainer from '../containers/product/Products.container'
import { Page } from '../components/common'
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import DividerSection from '../components/cards/components/divider'
import { products } from '../store/products.store'
import { Papers7d, Papers6d, Papers5d, PapersCoupons } from '../conf/products'
import PapersContainer from '../containers/papers'

const PapersPage = () => {

    const dispatch = useDispatch()
    const { newspaper } = useParams();

    useEffect(() => {
        dispatch(products({ slug: newspaper }))
    }, [newspaper, dispatch])


    return (
        <Page title='Scopri tutte le nuove tariffe' subtitle='e scegli quella che fa per te'>

            <Container component='section' maxWidth="xl" sx={{ pb: 2 }}>
                <ProductsContainer store='products' conf={Papers7d} tag='paper7d'>
                    <DividerSection title='Abbonamento 7 giorni' subtitle='Offerte valide per utenti privati' />
                </ProductsContainer>
            </Container>

            <Box width={1} bgcolor={'secondary.light'} sx={{ pb: 2 }}>
                <Container component='section' maxWidth="xl">
                    <ProductsContainer store='products' conf={Papers6d} tag='paper6d'>
                        <DividerSection title='Abbonamento 6 giorni' subtitle='Offerte valide per utenti privati' />
                    </ProductsContainer>
                </Container>
            </Box>

            <Container component='section' maxWidth="xl" sx={{ pb: 2 }}>
                <ProductsContainer store='products' conf={Papers5d} tag='paper5d'>
                    <DividerSection title='Abbonamento 5 giorni' subtitle='Offerte valide per utenti privati' />
                </ProductsContainer>
            </Container>

            <Box width={1} bgcolor={'secondary.light'} sx={{ pb: 2 }}>
                <Container component='section' maxWidth="xl">
                    <ProductsContainer store='products' conf={PapersCoupons} tag='coupon'>
                        <DividerSection title='Coupons' subtitle='Offerte valide per utenti privati' />
                    </ProductsContainer>
                </Container>
            </Box>

            <Container component='section' maxWidth="xl">
                <PapersContainer />
            </Container>

        </Page>
    )

}

export default PapersPage