import React, { useMemo } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { routes, routesUser } from '../../../conf/toolbar';

const Nav = ({
    onClose = () => { }
}) => {

    const { isAuthenticated, logout } = useAuth0();

    const navigate = useNavigate()
    const { newspaper, partner } = useParams();

    const menu = useMemo(() => {
        return isAuthenticated ? [...routes, ...routesUser(newspaper)] : [...routes]
    }, [isAuthenticated, newspaper])


    const returnTo = useMemo(() => {
        return partner ? `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/${newspaper}/${partner}/abbonamenti` : `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/${newspaper}/abbonamenti`
    }, [partner, newspaper])


    const menuLink = useMemo(() => {
        return partner ? `${newspaper}/${partner}` : `${newspaper}`
    }, [partner, newspaper])

    return (
        <>
            <List>
                <ListItem key="close" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={onClose} >
                        <CloseIcon color='primary' />
                    </IconButton>
                </ListItem>
            </List>
            <List>
                {menu.map((item, index) => {
                    if (item.link === 'logout') {
                        return (
                            <ListItem key={index} divider={true}>
                                <ListItemButton onClick={() => logout({ returnTo })}>
                                    <ListItemText primary={item.title.toUpperCase()} color='primary'/>
                                </ListItemButton>
                            </ListItem>
                        )
                    }
                    return (
                        <ListItem key={index} divider={true}>
                            {item.internal ?
                                <ListItemButton onClick={() => navigate(`/${menuLink}/${item.link}`)}>
                                    <ListItemText primary={item.title.toUpperCase()} color='primary'/>
                                </ListItemButton> :
                                <ListItemButton onClick={() => window.open(item.link, '_blank')}>
                                    <ListItemText primary={item.title.toUpperCase()} />
                                </ListItemButton>
                            }
                        </ListItem>
                    )
                })}
            </List>
        </>
    )
}
export default Nav