import { useMemo } from "react"
import { useMediaQuery } from "../../utils/useMediaQuery"

const Testata = ({
	color = 'black',
}) => {


	const mobile = useMediaQuery(900)
	const height = useMemo(() => mobile? 20 : 30, [mobile])

	return (
		<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 27.462" enableBackground="new 0 0 876.742 80.257" xmlSpace="preserve" height={`${height}`} preserveAspectRatio="xMinYMin meet">
			<g>
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="m209.697 21.062 -3.548 -7.343v-0.041c3.292 -0.951 3.899 -3.674 3.899 -6.133 0 -7.212 -4.894 -6.952 -7.101 -6.952h-11.477v4.621h0.432c0.434 0 0.737 0.299 0.737 0.692v15.59c0 0.386 -0.304 0.69 -0.737 0.69h-0.305l-0.128 0.125v4.538H201.473V22.226l-0.084 -0.041H200.436c-0.393 0 -0.735 -0.305 -0.735 -0.69v-6.005l0.128 0.262v-0.045l1.343 2.852 3.85 8.29h6.498V22.226c-0.687 0 -1.603 -0.693 -1.822 -1.164zM201.172 11.52c-0.258 0.041 -0.521 0.083 -0.82 0.083h-0.697V5.127c0.558 0 0.606 -0.042 0.868 0 0.217 0 0.43 0.041 0.648 0.133 0.958 0.426 1.511 1.723 1.511 3.365 -0.038 1.379 -0.3 2.588 -1.511 2.894z" />
				<g>
					<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M269.291 0.614v4.621h0.738c0.388 0 0.735 0.563 0.735 0.82V21.556c0 0.219 -0.347 0.777 -0.735 0.819h-0.738l0.046 4.491h10.218V22.376h-0.652c-0.43 -0.042 -0.78 -0.6 -0.78 -0.819V6.055c0 -0.257 0.349 -0.82 0.78 -0.82h0.606l0.046 -0.042V0.614h-10.264z" />
					<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M212.732 0.613v4.571h0.519c0.39 0 0.735 0.305 0.735 0.692v15.59c0 0.39 -0.345 0.695 -0.735 0.695h-0.475l-0.044 0.044v4.661h15.76v-8.811h-3.372c-0.31 1.295 -0.134 4.575 -3.558 4.189V16.678h3.118V10.757h-3.118v-5.526c3.378 -0.089 3.034 3.024 3.508 4.188h3.422V0.613h-15.76z" />
					<path fill={color} d="M247.756 9.773h-6.653v-1.693c0 -1.55 -0.108 -2.682 -1.939 -2.682 -2.02 0 -1.948 1.671 -1.948 2.248v11.186c0 0.998 -0.344 3.191 1.948 3.191 2.229 0 1.939 -2.175 1.939 -3.095v-2.356h-1.533V11.939h8.186v12.566c-1.014 0.971 -2.228 1.687 -3.64 2.147 -1.413 0.459 -3.299 0.811 -5.278 0.811 -2.011 0 -3.258 -0.346 -4.429 -0.791 -1.171 -0.447 -2.076 -1.05 -2.717 -1.813 -0.64 -0.761 -1.068 -1.641 -1.286 -2.639 -0.217 -0.997 -0.325 -2.048 -0.325 -3.151v-10.871c0 -1.05 0.114 -2.061 0.344 -3.033 0.229 -0.971 0.658 -1.832 1.285 -2.58 0.629 -0.748 1.508 -1.334 2.627 -1.792C235.931 0.139 238.837 0 238.837 0s2.843 0.238 4.862 0.871c1.527 0.478 2.873 1.247 4.056 2.245V9.773z" />
					<path fill={color} d="M267.415 9.773h-6.653v-1.693c0 -1.55 -0.108 -2.682 -1.939 -2.682 -2.02 0 -1.948 1.671 -1.948 2.248v11.186c0 0.998 -0.344 3.191 1.948 3.191 2.229 0 1.939 -2.175 1.939 -3.095v-2.356h-1.533V11.939h8.186v12.566c-1.014 0.971 -2.228 1.687 -3.64 2.147 -1.413 0.459 -3.299 0.811 -5.278 0.811 -2.011 0 -3.258 -0.346 -4.429 -0.791 -1.171 -0.447 -2.076 -1.05 -2.717 -1.813 -0.64 -0.761 -1.068 -1.641 -1.286 -2.639 -0.217 -0.997 -0.325 -2.048 -0.325 -3.151v-10.871c0 -1.05 0.114 -2.061 0.344 -3.033 0.229 -0.971 0.658 -1.832 1.285 -2.58 0.629 -0.748 1.508 -1.334 2.627 -1.792C255.59 0.139 258.497 0 258.497 0s2.843 0.238 4.862 0.871c1.527 0.478 2.873 1.247 4.056 2.245V9.773z" />
				</g>
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M290.646 0c-7.102 0.345 -8.663 3.889 -9.184 8.853V17.923c0.388 5.266 1.737 9.413 9.142 9.539h0.042c7.312 -0.126 8.917 -4.312 9.354 -9.283V8.376C299.432 3.972 297.313 0.514 290.646 0zm2.032 19.91c0 1.252 -0.606 2.114 -1.991 2.114h-0.041c-1.433 0 -2.039 -0.778 -2.039 -2.114v-12.353c0 -1.121 0.347 -2.16 2.039 -2.16h0.041c1.691 0 1.991 1.039 1.991 2.16v12.353z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M174.673 0.595v4.621h0.738c0.388 0 0.735 0.563 0.735 0.82v15.502c0 0.219 -0.347 0.777 -0.735 0.819h-0.738l0.046 4.491h10.218V22.357h-0.652c-0.43 -0.042 -0.78 -0.6 -0.78 -0.819V6.036c0 -0.257 0.349 -0.82 0.78 -0.82h0.606l0.046 -0.042V0.595h-10.264z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M91.415 0.614v8.812h3.689v-1.21c0 -1.169 0.17 -2.937 1.552 -2.981v16.322c0 0.219 -0.518 0.777 -0.949 0.819h-0.433v4.491h10.173V22.376h-0.477c-0.435 -0.042 -0.738 -0.6 -0.738 -0.819l-0.038 -16.322h0.038c1.435 0 1.435 2.333 1.435 2.764v1.426h3.636V0.614h-17.888z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M110.518 0.614v8.812h3.689v-1.21c0 -1.169 0.17 -2.937 1.552 -2.981v16.322c0 0.219 -0.518 0.777 -0.949 0.819h-0.433v4.491h10.173V22.376H124.073c-0.435 -0.042 -0.738 -0.6 -0.738 -0.819l-0.038 -16.322h0.038c1.435 0 1.435 2.333 1.435 2.764v1.426h3.636V0.614h-17.887z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M74.094 0.613v4.571h0.519c0.39 0 0.735 0.305 0.735 0.692v15.59c0 0.39 -0.345 0.695 -0.735 0.695h-0.475l-0.044 0.044v4.661h15.76v-8.811h-3.372c-0.31 1.295 -0.134 4.575 -3.558 4.189V16.678h3.118V10.757h-3.118v-5.526c3.378 -0.089 3.034 3.024 3.508 4.188h3.422V0.613h-15.76z" />
				<path fill={color} d="m56.862 22.14 7.993 -15.913h-0.976c-0.861 0 -1.437 0.184 -1.731 0.552 -0.294 0.368 -0.44 1.077 -0.44 2.127v2.245H57.02V0.595h15.733v4.569l-7.929 15.992h1.039c0.524 0 0.938 -0.052 1.242 -0.158 0.304 -0.104 0.53 -0.295 0.677 -0.571s0.236 -0.65 0.268 -1.123 0.047 -1.076 0.047 -1.812v-1.615h4.783v10.99h-16.017V22.14z" />
				<path fill={color} d="m39.63 22.14 7.993 -15.913h-0.976c-0.861 0 -1.437 0.184 -1.731 0.552 -0.294 0.368 -0.44 1.077 -0.44 2.127v2.245h-4.687V0.595h15.733v4.569l-7.929 15.992h1.039c0.524 0 0.938 -0.052 1.242 -0.158 0.304 -0.104 0.53 -0.295 0.677 -0.571s0.236 -0.65 0.268 -1.123c0.032 -0.473 0.047 -1.076 0.047 -1.812v-1.615h4.783v10.99h-16.017V22.14z" />
				<path fill={color} d="M17.675 9.773H11.022v-1.693c0 -1.55 -0.108 -2.682 -1.939 -2.682 -2.02 0 -1.948 1.671 -1.948 2.248v11.186c0 0.998 -0.344 3.191 1.948 3.191 2.229 0 1.939 -2.175 1.939 -3.095v-2.356h-1.533V11.939h8.186v12.566c-1.014 0.971 -2.228 1.687 -3.64 2.147 -1.413 0.459 -3.299 0.811 -5.278 0.811 -2.011 0 -3.258 -0.346 -4.429 -0.791 -1.171 -0.447 -2.076 -1.05 -2.717 -1.813 -0.64 -0.761 -1.068 -1.641 -1.286 -2.639C0.109 21.222 0 20.171 0 19.068v-10.871c0 -1.05 0.114 -2.061 0.344 -3.033 0.229 -0.971 0.658 -1.832 1.285 -2.58 0.629 -0.748 1.508 -1.334 2.627 -1.792C5.85 0.139 8.757 0 8.757 0s2.843 0.238 4.862 0.871c1.527 0.478 2.873 1.247 4.056 2.245V9.773z" />
				<path fill={color} d="M36.641 20.322c-0.169 -0.42 -4.037 -19.727 -4.037 -19.727H24.661S20.974 20.093 20.92 20.294c-0.427 1.584 -2.029 1.773 -2.029 1.773v4.8h8.558l-0.007 -4.651c-1.28 -0.078 -1.382 -0.732 -1.382 -1.415 0 -0.131 0.034 -0.83 0.102 -1.224h3.294c0.046 0.236 0.102 1.145 0.102 1.224 0 0.578 -0.029 1.393 -1.422 1.411v4.655h10.28V22.067c-1.432 -0.304 -1.603 -1.325 -1.773 -1.745zM26.879 15.586l0.85 -5.866 0.947 5.866h-1.797z" />
				<path fill={color} d="M145.925 20.322c-0.169 -0.42 -4.037 -19.727 -4.037 -19.727h-7.944s-3.687 19.498 -3.741 19.699c-0.427 1.584 -2.029 1.773 -2.029 1.773v4.8h8.558l-0.007 -4.651c-1.28 -0.078 -1.382 -0.732 -1.382 -1.415 0 -0.131 0.034 -0.83 0.102 -1.224h3.294c0.046 0.236 0.102 1.145 0.102 1.224 0 0.578 -0.028 1.393 -1.422 1.411v4.655h10.28V22.067c-1.432 -0.304 -1.603 -1.325 -1.773 -1.745zm-9.762 -4.736 0.85 -5.866 0.947 5.866h-1.797z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M163.935 0.644h-9.702v4.621h0.478c0.39 0 0.742 0.299 0.742 0.692V21.536c0 0.392 -0.352 0.692 -0.742 0.692h-0.478v4.621h9.702c6.705 0 9.354 -4.213 9.354 -8.588V9.232c0 -4.613 -2.763 -8.588 -9.354 -8.588zm2.033 13.009v5.427c0 1.121 -0.279 3.379 -3.503 3.235l0.021 -8.662h0l0 -0.049 0 -0.174h-0.001l-0.021 -8.252c3.224 -0.145 3.503 2.113 3.503 3.235V13.653z" />
			</g>
		</svg>
	)
}

export default Testata