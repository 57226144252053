import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("Il nome è obbligatorio"),
    lastName: Yup.string().required("Il cognome è obbligatorio"),
    birthPlace: Yup.string().required("Il luogo di nascita è obbligatorio"),
    birthDate: Yup.string().length(10, "Inserisci una data corretta").required("La data di nascita è obbligatoria"),
    gender: Yup.string().oneOf(["M", "F"], "Scegliere il genere"),
    taxCode: Yup.string()
        .length(16, "Il codice fiscale deve avere 16 caratteri")
        .matches(
            /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/,
            "Il codice fiscale non è valido"
        )
        .required("Il codice fiscale è obbligatorio"),
});

export const InitialData = {
    firstName: '',
    lastName: '',
    birthPlace: '',
    gender: '',
    birthDate: ' ',
    taxCode: '',    
}

export const FieldsUnder25 = [{
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'firstName',
        required: true
    },
    component: {
        label: 'Nome',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'lastName',
        required: true
    },
    component: {
        label: 'Cognome',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'birthPlace',
        required: true
    },
    component: {
        label: 'Comune di nascita',
        name: 'EditText',
        type: 'text'
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'gender',
        required: true
    },
    component: {
        label: 'Genere',
        name: 'SelectField',
        options: [{
            id: 'M',
            label: 'Uomo'
        }, {
            id: 'F',
            label: 'Donna'
        }, ]
    }
}, {
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'birthDate',
        required: true
    },
    component: {
        label: 'Data di Nascita',
        name: 'EditText',
        type: 'date'
    }
},{
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'taxCode',
        required: true
    },
    component: {
        label: 'Codice Fiscale',
        name: 'EditText',
        type: 'text'
    }
}]