import React, { useContext } from 'react';
import CoverComponent from '../../components/kiosk/cover';
import Stepper from '../../components/kiosk/stepper';
import { KioskContext } from '../../core/context/kiosk.context';

const NewspaperContainer = ({
    items = []
}) => {

    const { index, setIndex } = useContext(KioskContext);

    return (
        items.length > 0 &&
        <Stepper index={index} setIndex={setIndex} items={items}>
            <CoverComponent issue={items[index]} height={500} />
        </Stepper>
    )
}

export default NewspaperContainer