import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'offer',
    initialState: {
        loading: false,
        error: null,
        offer: null,
    },
    reducers: {
        offer: (state) => {
            state.error = null
            state.loading = true
            state.offer = null
        },
        offerSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.offer = action.offer
        },
        offerError: (state, action) => {
            state.offer = null
            state.error = action.error
            state.loading = false
        }
    },
});


export const {
    offer,
    offerSuccess,
    offerError,
} = slice.actions

export default slice.reducer