import _ from 'lodash'

export const KioskEndpoints = {
    basePath: '',
    endpoints: {        
        promo: {
            path: `/redeem-purchase-with-code`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true
        }             
    }
}

export default KioskEndpoints