import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        error: null,
        user: null,
    },
    reducers: {
        me: (state) => {
            state.error = null
            state.loading = true
            state.user = null
        },
        meSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.user = action.user
        },
        logout: (state) => state,
        logoutSuccess: (state) => {
            state.user = null
            state.error = null
            state.loading = false
        },
        errorUser: (state, action) => {
            state.user = null
            state.error = action.error
            state.loading = false
        },        
    },
});


export const {
    me,
    meSuccess,
    logout,
    logoutSuccess,
    errorUser,
} = slice.actions

export default slice.reducer