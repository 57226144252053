import _ from 'lodash';

export const ProfileEndpoints = {
    basePath: '',
    endpoints: {
        update: {
            path: '/profile/',
            method: 'PUT',
            get: null,
            auth: true,
            fallback: null,
        },
        get: {
            path: '/profiles/',
            method: 'GET',
            get: null,
            auth: true,
            fallback: null,
        },
        me: {
            path: '/profile/',
            method: 'GET',
            get: null,
            auth: true,
            fallback: null,
        },                        
    }
}

export default ProfileEndpoints;