import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import sagas from '../sagas/sagas'
import user from './user.store'
import newsletters from './newsletters.store'
import products from './products.store'
import subscriptions from './subscriptions.store'
import offer from './offer.store'
import catalog from './catalog.store'
import issue from './issue.store'
import purchases from './purchase.store'
import products_special from './productsSpecial.store'
import newspaper from './newspaper.store'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
	user,
	newsletters,
	products,
	subscriptions,
	offer,
	catalog,
	issue,
	purchases,
	products_special,
	newspaper
})

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(sagas)

export default store;