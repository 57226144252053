import React, { Fragment, useCallback, useState } from 'react'
import { Requests, useUser } from '../../core'
import { Message, Loading } from '../../components/common';
import PromoForm from '../../components/promo/PromoForm.component';
import { useAuth0 } from "@auth0/auth0-react";
import _trim from 'lodash/trim'
import UserButton from '../../components/user/button';
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';
import DividerSection from '../../components/cards/components/divider';

export const Type = {
    subscription: 'subscription',
    issue: 'issue'
}

const PromoContainer = ({
    type = Type.subscription,
    issue,
    children
}) => {

    const navigate = useNavigate()
    const { newspaper, partner } = useParams()
    const { isAuthenticated } = useAuth0();
    const [user, token] = useUser()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isSubmitting, setSubmitting] = useState(false)


    // Submit Update
    const onSubmit = async (values) => {
        setSubmitting(true)
        setLoading(true)

        const data = { auth0: user.sub, code: _trim(values.code) }

        if (type === Type.subscription) {
            Requests.subscription.promo({ auth: token, ...data }).then((response) => onSuccess(response)).catch((error) => onFailure(error))
        }

        if (type === Type.issue && issue) {
            Requests.kiosk.promo({ auth: token, ...data, issue_sku: issue.id }).then((response) => onSuccess(response)).catch((error) => onFailure(error))
        }

    }

    const onSuccess = useCallback(() => {
        setSubmitting(false)
        setLoading(false)
        setError(null)
        navigate(partner ? `/${newspaper}/${partner}/promo/success` : `/${newspaper}/promo/success`)
    }, [newspaper, partner])

    const onFailure = useCallback((error) => {
        setSubmitting(false)
        setLoading(false)
        const data = error?.response?.data
        setError(data?.message ? data.message : 'Errore generico')
    }, [])

    return (
        <Fragment>
            {children}
            {loading && <Loading type='linear' />}
            {error && <Message severity='error' message={error} />}
            <Typography variant='subtitle1' sx={{ width: 1 }}>
                {isAuthenticated ? 'Inserisci il codice promozionale' : 'Effettua la login/registrazione prima di inserire il codice promozionale'}
            </Typography>
            {isAuthenticated ?
                <PromoForm isSubmitting={isSubmitting} onSubmit={onSubmit} /> :
                <UserButton showWelcome={false} hash='#promo' />
            }
        </Fragment>
    )

}

export default PromoContainer