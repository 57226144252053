import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Paper, Stack, Avatar, Tab, Tabs, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import { Loading } from '../../../components/common';
import _ from 'lodash'

const tabs = [{
    title: 'Profilo',
    link: '/account/profilo'
}, {
    title: 'Acquisti',
    link: '/account/acquisti'
}]

const ProfileTabs = () => {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0);
    const { pathname } = useLocation()
    const { newspaper, partner } = useParams()
    const { user, loading } = useSelector(state => state.user)

    const handleChange = (event, newValue) => {
        const to = partner ? `/${newspaper}/${partner}${tabs[newValue].link}` : `/${newspaper}${tabs[newValue].link}`
        navigate(to)
    };

    useEffect(() => {
        setActiveTab(_.findIndex(tabs, (tab) => pathname.includes(tab.link)))
    }, [pathname, setActiveTab])

    return (
        <Paper elevation={4} sx={{ paddingTop: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
            {loading && <Loading type={'backdrop'} />}
            <Stack direction='row' width={1} spacing={1} sx={{ padding: 2 }} alignItems='center'>
                <Avatar sx={{ width: 50, height: 50, color: 'primary.main', backgroundColor: 'white.main' }}>
                    <PersonIcon sx={{ width: 40, height: 40 }} />
                </Avatar>
                <Stack>
                    <Typography variant='h6' color='primary.main' fontWeight='bold'>
                        {user?.nickname ? user.nickname : ''}
                    </Typography>
                    <Typography variant='subtitle1' color='primary.main'>
                        {user?.given_name ? user.given_name : ''} {user?.family_name ? user.family_name : ''}
                    </Typography>
                </Stack>
            </Stack>
            <Tabs value={activeTab} onChange={handleChange}>
                {tabs.map((tab) => <Tab key={tab.link} label={tab.title} />)}
            </Tabs>
        </Paper>
    )
}

export default ProfileTabs