import { useMemo } from "react"
import { useMediaQuery } from "../../utils/useMediaQuery"

const Testata = ({ color = 'black' }) => {

    const mobile = useMediaQuery(900)
    const height = useMemo(() => mobile ? 25 : 35, [mobile])

    return (
        <svg
            id="b"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 300 51.52"
            height={`${height}`}
            preserveAspectRatio="xMinYMin meet">
            <g id="c">
                <g>
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="m188.044 40.184 -6.655 -13.776v-0.076c6.177 -1.782 7.316 -6.893 7.316 -11.507 0 -13.53 -9.183 -13.043 -13.322 -13.043h-21.531v8.668h0.812c0.813 0 1.383 0.561 1.383 1.296V40.995c0 0.723 -0.57 1.294 -1.383 1.294h-0.572l-0.24 0.235v8.512h18.764v-8.67l-0.158 -0.076h-1.788c-0.736 0 -1.379 -0.572 -1.379 -1.294V29.73l0.24 0.491v-0.084l2.518 5.351 7.223 15.552h12.191v-8.671c-1.29 0 -3.007 -1.3 -3.418 -2.184ZM172.05 22.282c-0.485 0.076 -0.978 0.156 -1.537 0.156h-1.306v-12.15c1.047 0 1.137 -0.08 1.629 0 0.407 0 0.807 0.076 1.215 0.251 1.797 0.8 2.835 3.232 2.835 6.313 -0.071 2.586 -0.564 4.854 -2.835 5.43Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="m147.841 40.184 -6.584 -13.776v-0.076c6.182 -1.782 7.318 -6.893 7.318 -11.507 0 -13.53 -9.265 -13.043 -13.324 -13.043H113.634v8.668h0.897c0.732 0 1.393 0.561 1.393 1.296V40.995c0 0.723 -0.66 1.294 -1.393 1.294h-0.648l-0.247 0.235v8.512h18.857v-8.67l-0.171 -0.076h-1.782c-0.728 0 -1.385 -0.572 -1.385 -1.294V29.73l0.254 0.491v-0.084l2.429 5.351 7.309 15.552h12.11v-8.671c-1.305 0 -3.007 -1.3 -3.414 -2.184Zm-16.005 -17.901a7.575 7.575 0 0 1 -1.467 0.156h-1.292v-12.15c1.059 0 1.134 -0.08 1.624 0 0.402 0 0.808 0.076 1.134 0.251 1.866 0.8 2.841 3.232 2.841 6.313 0 2.586 -0.559 4.854 -2.841 5.43Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M0 10.611h1.376c0.816 0 1.377 1.056 1.377 1.537v29.084c0 0.411 -0.561 1.458 -1.377 1.536H0l0.154 8.427H19.245v-8.427h-1.209c-0.819 -0.078 -1.467 -1.127 -1.467 -1.536 0 -0.479 0 -0.237 0.076 -0.321V12.476c-0.076 -0.17 -0.076 0.076 -0.076 -0.326 0 -0.482 0.648 -1.537 1.466 -1.537h1.129l0.08 -0.078V1.942H0v8.668Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M91.869 10.611h1.386c0.728 0 1.379 1.056 1.379 1.537v29.084c0 0.411 -0.651 1.458 -1.379 1.536h-1.386l0.086 8.425h19.17v-8.425h-1.222c-0.807 -0.078 -1.464 -1.127 -1.464 -1.536V12.15c0 -0.482 0.655 -1.537 1.464 -1.537h1.137l0.086 -0.078V1.942H91.869v8.668Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M43.13 36.456c-0.821 2.998 -0.244 5.589 -3.49 6.234h-1.706V12.15c0 -0.482 0.648 -1.537 1.46 -1.537h0.812v-8.67h-18.765v8.668h0.901c0.801 0 1.455 1.056 1.455 1.537v29.084c0 0.411 -0.654 1.458 -1.455 1.536h-0.901v8.427h29.168V34.669h-5.446c-0.723 0.086 -1.708 0.895 -2.031 1.787Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M56.447 18.473h6.921v-2.268c0 -2.193 0.32 -5.51 2.912 -5.592v30.621c0 0.411 -0.972 1.458 -1.78 1.537h-0.812v8.424h19.085v-8.427h-0.895c-0.816 -0.078 -1.386 -1.127 -1.386 -1.537l-0.071 -30.621h0.071c2.692 0 2.692 4.375 2.692 5.187v2.675h6.82V1.942H56.447v16.53Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M193.743 10.038h0.975c0.732 0 1.379 0.573 1.379 1.299v29.248c0 0.73 -0.646 1.304 -1.379 1.304h-0.892l-0.083 0.084v8.745h29.566v-16.53h-6.325c-0.582 2.429 -0.251 8.583 -6.675 7.858v-10.447h5.85v-11.106h-5.85V10.125c6.336 -0.167 5.691 5.671 6.582 7.857h6.42V1.461h-29.566v8.577Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M249.956 9.721h1.14c0.806 0 1.536 0.972 1.536 2.026V29.325h-0.08L241.99 1.461h-15.755v8.663h0.652c0.726 0 1.292 0.651 1.292 1.297v29.415a1.275 1.275 0 0 1 -1.29 1.292h-0.652v8.589h13.074v-8.589h-1.143c-0.812 0 -1.38 -0.812 -1.38 -1.863V23.895l0.162 0.57h0.084l10.722 26.252h13.409V17.253h0.08v-5.505c0 -1.054 0.729 -2.026 1.539 -2.026h0.977V1.461h-13.806v8.261Z"
                    />
                    <path
                        fill={color}
                        fillRule="evenodd"
                        d="M282.452 0c-13.324 0.646 -16.253 7.296 -17.229 16.608v17.018c0.728 9.88 3.256 17.658 17.149 17.895h0.08c13.719 -0.237 16.728 -8.089 17.549 -17.415V15.714C298.935 7.452 294.96 0.965 282.452 0Zm3.813 37.352c0 2.349 -1.137 3.966 -3.737 3.966h-0.076c-2.688 0 -3.827 -1.46 -3.827 -3.966V14.178c0 -2.103 0.651 -4.053 3.827 -4.053h0.076c3.171 0 3.737 1.948 3.737 4.051v23.175Z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default Testata