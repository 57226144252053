import React from 'react'
import ProfileContainer from '../../containers/profile/Profile.container';

const ProfilePage = () => {

    return (
        <ProfileContainer />
    )

}

export default ProfilePage