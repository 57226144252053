import React from 'react'
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { backLink } from '../../../conf/toolbar';
import { Testata } from '../../../icons/testata';


const Logo = () => {

    const { newspaper } = useParams();
    const Icon = Testata[newspaper]
    const link = backLink[newspaper]

    return (
        <a href={link} target="_blank" rel="noopener noreferrer">
            <Box sx={{ width: '100%', height:'100%', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <Icon />
            </Box>
        </a>
    )
}

export default Logo