import { useMemo } from "react"
import { useMediaQuery } from "../../utils/useMediaQuery"

const Testata = ({
    color = 'black',
}) => {


    const mobile = useMediaQuery(900)
    const height = useMemo(() => mobile ? 25 : 35, [mobile])

    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 35.568" height={`${height}`} preserveAspectRatio="xMinYMin meet">
            <path fill={color} d="M106.301 35.279c-5.749 0 -10.252 -5.336 -10.252 -12.145 0 -6.905 4.712 -12.644 10.504 -12.794 0.078 -0.002 0.155 -0.003 0.233 -0.003 5.561 0 9.918 5.376 9.918 12.24 0 6.972 -4.465 12.551 -10.165 12.698 -0.08 0.002 -0.159 0.004 -0.238 0.004m-0.445 -21.764c-0.116 0 -0.234 0.01 -0.353 0.031 -2.353 0.413 -3.309 3.84 -2.694 9.649 0.563 5.307 2.24 8.604 4.377 8.604 0.169 0 0.341 -0.021 0.513 -0.062 2.245 -0.543 3.002 -3.251 2.531 -9.058 -0.37 -4.562 -1.866 -9.165 -4.374 -9.165" fill-rule="evenodd" />
            <path fill={color} d="M106.786 10.625c5.4 0 9.629 5.249 9.629 11.952 0 6.815 -4.342 12.266 -9.884 12.409 -0.077 0.002 -0.154 0.004 -0.231 0.004 -5.586 0 -9.963 -5.208 -9.963 -11.856 0 -6.749 4.586 -12.359 10.223 -12.506 0.076 -0.002 0.15 -0.003 0.225 -0.003m0.4 21.463c0.192 0 0.387 -0.024 0.581 -0.07 3.034 -0.732 3.093 -5.143 2.751 -9.362 -0.353 -4.351 -1.786 -9.431 -4.662 -9.431 -0.133 0 -0.269 0.012 -0.403 0.036 -2.56 0.449 -3.574 3.895 -2.931 9.964 0.588 5.549 2.331 8.863 4.664 8.863m-0.4 -22.041c-0.08 0 -0.159 0.001 -0.24 0.003 -5.941 0.153 -10.786 5.977 -10.786 13.083 0 7.008 4.713 12.434 10.54 12.434 0.081 0 0.164 -0.002 0.246 -0.004 5.941 -0.154 10.447 -5.88 10.447 -12.987 0 -7.01 -4.386 -12.529 -10.207 -12.529m0.4 21.463c-2.026 0 -3.569 -3.432 -4.09 -8.346 -0.558 -5.267 0.152 -8.93 2.456 -9.335 0.102 -0.018 0.204 -0.027 0.303 -0.027 2.148 0 3.695 4.08 4.086 8.899 0.409 5.044 -0.066 8.212 -2.311 8.753a1.901 1.901 0 0 1 -0.445 0.054" />
            <path fill={color} d="M254.623 34.813v-2.51h1.493V18.38l-0.044 -0.071c-0.066 -0.106 -0.191 -0.304 -0.407 -0.304 -0.194 0 -0.287 0.149 -0.332 0.221a0.376 0.376 0 0 1 -0.036 0.053l-0.112 0.101c-0.135 0.12 -0.273 0.244 -0.406 0.377l-1.386 -1.209c1.005 -1.666 2.725 -4.502 4.235 -6.636l0.283 0.168c1.515 0.9 1.605 0.955 2.878 2.743l0.126 0.177 0.143 -0.05c0.032 0.008 0.081 0.016 0.138 0.016a0.501 0.501 0 0 0 0.436 -0.236 17.571 17.571 0 0 0 0.551 -0.94c0.619 -1.109 1.108 -1.984 2.219 -1.984 0.289 0 0.622 0.06 0.987 0.178 0.459 0.255 1.094 0.559 1.708 0.852 0.525 0.251 1.065 0.509 1.52 0.748 -0.285 0.983 -0.563 2.104 -0.831 3.19 -0.258 1.044 -0.523 2.12 -0.796 3.068 -0.901 -0.44 -1.639 -0.655 -2.248 -0.655 -0.537 0 -0.968 0.17 -1.282 0.506 -0.462 0.494 -0.646 1.31 -0.565 2.494v11.118h2.646v2.51h-10.915Z" fill-rule="evenodd" />
            <path fill={color} d="M264.399 11.094c0.247 0 0.533 0.05 0.85 0.148 0.505 0.272 1.123 0.567 1.721 0.853 0.444 0.213 0.899 0.43 1.302 0.635 -0.263 0.931 -0.519 1.966 -0.768 2.973 -0.228 0.923 -0.462 1.871 -0.701 2.731 -0.804 -0.361 -1.483 -0.538 -2.063 -0.538h-0.001c-0.619 0 -1.122 0.201 -1.492 0.598 -0.517 0.552 -0.727 1.433 -0.644 2.69v11.408h2.646v1.932h-10.338v-1.932h1.493v-14.295l-0.088 -0.141c-0.083 -0.132 -0.276 -0.44 -0.652 -0.44 -0.231 0 -0.422 0.116 -0.584 0.368l-0.09 0.08c-0.073 0.066 -0.148 0.132 -0.222 0.201l-1.004 -0.877c0.98 -1.624 2.539 -4.183 3.944 -6.192l0.052 0.031c1.495 0.889 1.553 0.923 2.79 2.662l0.251 0.354 0.276 -0.096c0.034 0.004 0.073 0.007 0.114 0.007a0.792 0.792 0 0 0 0.678 -0.368 18.34 18.34 0 0 0 0.56 -0.956c0.655 -1.173 1.069 -1.836 1.966 -1.836m0.001 -0.578c-1.628 0 -2.095 1.627 -3.014 3.058a0.213 0.213 0 0 1 -0.192 0.103c-0.07 0 -0.133 -0.023 -0.162 -0.023 -0.003 0 -0.007 0 -0.009 0.001 -1.44 -2.025 -1.461 -1.919 -3.476 -3.128 -1.61 2.244 -3.492 5.363 -4.527 7.079l1.777 1.551c0.214 -0.242 0.451 -0.445 0.693 -0.663 0.086 -0.078 0.115 -0.199 0.175 -0.199 0.036 0 0.084 0.044 0.162 0.169v13.551h-1.493v3.088h11.493v-3.088h-2.646V21.166c-0.11 -1.605 0.281 -2.692 1.558 -2.691 0.6 0 1.397 0.24 2.428 0.781 0.615 -2.059 1.176 -4.759 1.791 -6.818 -1.001 -0.543 -2.482 -1.186 -3.483 -1.729 -0.409 -0.133 -0.764 -0.192 -1.076 -0.192" />
            <path fill={color} d="M220.324 34.813v-2.51h1.494V18.38l-0.044 -0.071c-0.066 -0.106 -0.191 -0.304 -0.407 -0.304 -0.193 0 -0.287 0.148 -0.332 0.22 -0.012 0.018 -0.028 0.046 -0.037 0.053l-0.126 0.114a10.326 10.326 0 0 0 -0.392 0.365l-1.386 -1.209c1.006 -1.668 2.727 -4.506 4.235 -6.636l0.281 0.168c1.516 0.9 1.606 0.955 2.878 2.743l0.125 0.176 0.143 -0.05c0.032 0.008 0.082 0.016 0.139 0.016a0.5 0.5 0 0 0 0.435 -0.236c0.199 -0.31 0.377 -0.629 0.55 -0.939 0.619 -1.11 1.108 -1.986 2.219 -1.986 0.29 0 0.622 0.06 0.987 0.178 0.459 0.255 1.094 0.559 1.708 0.852 0.525 0.251 1.065 0.509 1.521 0.748 -0.286 0.984 -0.563 2.107 -0.833 3.195 -0.258 1.042 -0.523 2.117 -0.794 3.063 -0.901 -0.44 -1.639 -0.655 -2.248 -0.655 -0.537 0 -0.968 0.17 -1.282 0.507 -0.461 0.494 -0.646 1.309 -0.565 2.493v11.118h2.645v2.51h-10.916Z" fill-rule="evenodd" />
            <path fill={color} d="M230.101 11.094c0.247 0 0.533 0.05 0.85 0.148 0.506 0.272 1.123 0.567 1.721 0.853 0.444 0.213 0.899 0.43 1.302 0.635 -0.264 0.933 -0.521 1.97 -0.77 2.977a102.645 102.645 0 0 1 -0.7 2.727c-0.804 -0.361 -1.483 -0.538 -2.063 -0.538 -0.619 0 -1.123 0.201 -1.492 0.598 -0.517 0.553 -0.727 1.433 -0.643 2.69v11.408h2.645v1.932h-10.338v-1.932h1.494v-14.295l-0.088 -0.141c-0.083 -0.132 -0.276 -0.44 -0.652 -0.44 -0.231 0 -0.422 0.116 -0.585 0.367l-0.104 0.094c-0.068 0.062 -0.138 0.124 -0.207 0.188l-1.004 -0.877c0.981 -1.625 2.541 -4.186 3.944 -6.192l0.053 0.031c1.495 0.889 1.553 0.923 2.79 2.662l0.25 0.352 0.276 -0.095c0.034 0.004 0.073 0.008 0.116 0.008a0.792 0.792 0 0 0 0.678 -0.369c0.205 -0.32 0.385 -0.643 0.559 -0.955 0.655 -1.174 1.069 -1.837 1.967 -1.837m0 -0.578c-1.628 0 -2.095 1.627 -3.013 3.058a0.213 0.213 0 0 1 -0.192 0.103c-0.07 0 -0.133 -0.023 -0.162 -0.023 -0.004 0 -0.008 0 -0.01 0.001 -1.44 -2.025 -1.461 -1.919 -3.475 -3.128 -1.612 2.244 -3.492 5.363 -4.527 7.079l1.777 1.551c0.214 -0.242 0.452 -0.445 0.692 -0.663 0.086 -0.078 0.116 -0.199 0.176 -0.199 0.036 0 0.084 0.044 0.162 0.169v13.551h-1.494v3.088h11.494v-3.088h-2.645V21.166c-0.11 -1.605 0.281 -2.692 1.558 -2.691 0.6 0 1.397 0.24 2.428 0.781 0.615 -2.059 1.176 -4.759 1.791 -6.818 -1.001 -0.543 -2.482 -1.186 -3.484 -1.729 -0.409 -0.133 -0.764 -0.192 -1.076 -0.192" />
            <path fill={color} d="M204.707 34.813v-2.51h1.493V18.38l-0.044 -0.071c-0.066 -0.106 -0.191 -0.304 -0.407 -0.304 -0.193 0 -0.287 0.148 -0.332 0.22 -0.011 0.018 -0.028 0.046 -0.036 0.053l-0.114 0.103c-0.134 0.12 -0.271 0.243 -0.404 0.376l-1.386 -1.209c1.004 -1.665 2.721 -4.499 4.234 -6.636l0.284 0.169c1.484 0.882 1.605 0.954 2.876 2.741l0.125 0.177 0.143 -0.05c0.033 0.008 0.082 0.016 0.139 0.016a0.501 0.501 0 0 0 0.436 -0.236c0.199 -0.31 0.377 -0.629 0.55 -0.939 0.619 -1.109 1.108 -1.985 2.219 -1.985 0.29 0 0.622 0.06 0.987 0.178 0.459 0.255 1.094 0.559 1.71 0.853 0.524 0.251 1.064 0.508 1.518 0.747 -0.285 0.984 -0.563 2.107 -0.833 3.195 -0.257 1.042 -0.522 2.116 -0.794 3.063 -0.901 -0.44 -1.639 -0.655 -2.248 -0.655 -0.537 0 -0.968 0.17 -1.282 0.506 -0.462 0.494 -0.646 1.31 -0.565 2.494v11.118h2.645v2.51h-10.915Z" fill-rule="evenodd" />
            <path fill={color} d="M214.483 11.094c0.247 0 0.533 0.05 0.85 0.148 0.506 0.272 1.124 0.568 1.722 0.854 0.443 0.212 0.897 0.429 1.3 0.634 -0.264 0.933 -0.52 1.97 -0.77 2.977a98.366 98.366 0 0 1 -0.7 2.727c-0.803 -0.361 -1.482 -0.538 -2.062 -0.538h-0.001c-0.619 0 -1.122 0.201 -1.492 0.598 -0.517 0.552 -0.727 1.433 -0.644 2.69v11.408h2.646v1.932h-10.338v-1.932h1.493v-14.295l-0.088 -0.141c-0.083 -0.132 -0.276 -0.44 -0.652 -0.44 -0.231 0 -0.422 0.116 -0.584 0.367l-0.092 0.083c-0.073 0.065 -0.146 0.131 -0.22 0.199l-1.004 -0.877c0.979 -1.623 2.537 -4.18 3.944 -6.192l0.055 0.032c1.494 0.888 1.552 0.923 2.787 2.66l0.25 0.352 0.277 -0.095c0.035 0.004 0.074 0.008 0.116 0.008 0.281 0 0.529 -0.135 0.678 -0.369a18.062 18.062 0 0 0 0.56 -0.955c0.655 -1.174 1.069 -1.837 1.967 -1.837m0 -0.578c-1.628 0 -2.095 1.627 -3.013 3.058a0.213 0.213 0 0 1 -0.192 0.103c-0.07 0 -0.133 -0.023 -0.162 -0.023 -0.004 0 -0.008 0 -0.01 0.001 -1.439 -2.025 -1.461 -1.919 -3.475 -3.128 -1.61 2.244 -3.492 5.363 -4.526 7.079l1.777 1.551c0.214 -0.242 0.451 -0.445 0.692 -0.663 0.086 -0.078 0.116 -0.199 0.176 -0.199 0.036 0 0.084 0.044 0.162 0.169v13.551h-1.493v3.088h11.493v-3.088h-2.646V21.166c-0.11 -1.605 0.281 -2.692 1.558 -2.691 0.6 0 1.397 0.24 2.428 0.781 0.614 -2.059 1.176 -4.759 1.791 -6.818 -1.001 -0.543 -2.483 -1.186 -3.483 -1.729 -0.409 -0.133 -0.764 -0.192 -1.076 -0.192" />
            <path fill={color} d="M163.4 34.813v-3.64h1.716V5.281h-1.716V1.798h19.223v6.228h-2.568v-0.621l-0.007 -0.031c-0.27 -1.198 -1.345 -1.873 -3.191 -2.007l-3.118 -0.087c-1.012 0.051 -1.08 0.866 -1.108 1.213l-0.001 6.613h7.425v-2.103h2.568V18.675h-2.568v-1.8h-7.425v14.299h1.986v3.64h-11.215Z" fill-rule="evenodd" />
            <path fill={color} d="M182.333 2.086v5.65h-1.989v-0.363l-0.014 -0.063c-0.302 -1.336 -1.464 -2.088 -3.478 -2.233l-1.048 -0.029 -2.057 -0.057h-0.046c-0.825 0.043 -1.282 0.54 -1.36 1.478l-0.002 0.024v6.902h8.004v-2.103h1.989V18.386h-1.989v-1.8h-8.004v14.876h1.987v3.062h-10.638v-3.062h1.716V4.992h-1.716V2.086h18.645m0.578 -0.578h-19.801V5.57h1.716v25.315h-1.716v4.217H174.904v-4.217h-1.987V17.164h6.848v1.8h3.145V10.714h-3.145v2.103h-6.848V6.517c0.04 -0.485 0.158 -0.915 0.812 -0.948l3.105 0.087c1.714 0.124 2.69 0.718 2.931 1.782v0.877h3.145V1.509Z" />
            <path fill={color} d="M124.691 34.813c-1.747 -5.861 -3.492 -10.936 -5.733 -17.197l-0.111 -0.309 -1.34 0.685 -0.704 -2.039 7.324 -4.845c1.067 4.191 2.477 8.713 4.307 13.809 0.135 0.306 0.381 0.485 0.651 0.485 0.227 0 0.429 -0.124 0.54 -0.332 0.757 -2.271 1.298 -3.847 1.837 -5.42 0.543 -1.581 1.085 -3.161 1.826 -5.399l0.018 -0.055 -0.005 -0.058c-0.045 -0.607 -0.815 -0.765 -1.495 -0.905 -0.121 -0.025 -0.239 -0.049 -0.346 -0.075 0.066 -0.218 0.177 -0.506 0.278 -0.769 0.114 -0.297 0.224 -0.582 0.295 -0.821 0.712 0.184 1.46 0.344 2.215 0.344 0.638 0 1.184 -0.116 1.66 -0.353l0.727 0.523c-0.86 2.576 -1.759 5.119 -2.63 7.582 -1.808 5.115 -3.517 9.949 -4.952 15.15h-4.364Z" fill-rule="evenodd" />
            <path fill={color} d="M123.948 11.573c1.056 4.089 2.438 8.496 4.213 13.442l0.008 0.02 0.009 0.019c0.174 0.393 0.522 0.637 0.907 0.637 0.33 0 0.634 -0.185 0.794 -0.484l0.024 -0.044 0.015 -0.047c0.737 -2.225 1.287 -3.825 1.818 -5.372 0.533 -1.555 1.085 -3.163 1.827 -5.401l0.036 -0.11 -0.009 -0.115c-0.062 -0.825 -1.023 -1.022 -1.724 -1.167l-0.027 -0.005c0.055 -0.152 0.117 -0.313 0.17 -0.452 0.079 -0.206 0.154 -0.4 0.216 -0.578 0.651 0.159 1.331 0.287 2.024 0.287 0.605 0 1.151 -0.104 1.628 -0.309l0.121 0.087 0.296 0.214c-0.84 2.504 -1.714 4.978 -2.561 7.375 -1.785 5.05 -3.474 9.828 -4.899 14.957h-3.929c-1.729 -5.78 -3.461 -10.814 -5.676 -17.006l-0.221 -0.619 -0.586 0.299 -0.751 0.384 -0.522 -1.513 2.229 -1.475 4.569 -3.023m0.359 -0.931 -7.85 5.194 0.885 2.565 1.343 -0.687c2.188 6.113 3.972 11.274 5.79 17.389h4.799c2.171 -7.931 5.073 -15.236 7.703 -23.13l-1.046 -0.753c-0.513 0.298 -1.091 0.404 -1.682 0.404 -0.839 0 -1.701 -0.216 -2.429 -0.411 -0.057 0.474 -0.645 1.675 -0.701 2.149 0.6 0.209 1.854 0.254 1.895 0.798 -1.479 4.464 -2.165 6.311 -3.644 10.774a0.322 0.322 0 0 1 -0.285 0.179c-0.14 0 -0.29 -0.093 -0.379 -0.293 -1.698 -4.728 -3.214 -9.45 -4.398 -14.177" />
            <path fill={color} d="M81.903 34.814c-0.285 0 -0.58 -0.013 -0.903 -0.04 -4.183 -0.815 -4.71 -3.794 -4.74 -5.963a1.595 1.595 0 0 1 0.007 -0.1l0.001 -14.108 -1.605 0.686v-2.349l8.442 -2.957 -0.114 1.646v14.336c0.032 1.243 0.286 2.827 1.313 3.354 0.3 0.155 0.659 0.236 1.036 0.236 0.547 0 1.39 -0.173 2.301 -1.002l0.095 -0.086V14.694l-1.545 0.615v-2.312l8.025 -2.846v21.926h1.588v2.073h-7.497c-0.072 -0.434 -0.142 -0.725 -0.21 -1.007 -0.076 -0.315 -0.147 -0.612 -0.22 -1.059l-0.098 -0.597 -0.403 0.452c-1.89 2.122 -3.323 2.875 -5.475 2.875" fill-rule="evenodd" />
            <path fill={color} d="m82.789 10.401 -0.084 1.208 -0.001 0.02v14.351c0.035 1.315 0.318 3.005 1.471 3.597 0.341 0.176 0.745 0.268 1.168 0.268 0.598 0 1.517 -0.187 2.496 -1.078l0.189 -0.172V14.269l-0.792 0.315 -0.753 0.3v-1.682l7.448 -2.642v21.806h1.587v1.495h-6.963c-0.059 -0.306 -0.117 -0.548 -0.173 -0.785a12.752 12.752 0 0 1 -0.216 -1.037l-0.196 -1.197 -0.806 0.905c-1.826 2.05 -3.203 2.777 -5.259 2.777 -0.268 0 -0.545 -0.012 -0.847 -0.036 -3.979 -0.787 -4.478 -3.618 -4.506 -5.658 0 -0.032 0.003 -0.064 0.006 -0.095l0.002 -0.024V14.166l-0.805 0.344 -0.8 0.341V13.145l7.835 -2.744m0.637 -0.836 -9.05 3.17v2.991l1.605 -0.686v13.646a1.887 1.887 0 0 0 -0.008 0.128c0.032 2.33 0.669 5.404 5.005 6.247 0.324 0.027 0.632 0.041 0.926 0.041 2.316 0 3.812 -0.862 5.691 -2.971 0.149 0.913 0.299 1.245 0.448 2.157v0.15h8.052v-2.651h-1.587V9.742l-8.604 3.051v2.942l1.545 -0.615v13.22c-0.759 0.69 -1.502 0.927 -2.107 0.927 -0.358 0 -0.668 -0.083 -0.904 -0.205 -0.816 -0.419 -1.121 -1.728 -1.157 -3.098V11.649l0.145 -2.084Z" />
            <path fill={color} d="M68.726 34.812c-2.047 -5.636 -4.586 -9.94 -7.52 -14.914 -1.028 -1.743 -2.091 -3.545 -3.214 -5.538l-0.541 -0.96v18.165h1.518v3.182H52.224v-3.182h1.517V5.726h-1.517V2.33h7.367c0.894 1.165 8.517 14.049 9.359 15.514l0.545 0.947 -0.07 -13.859h-1.387V2.33h6.555v2.601h-1.582l0.064 29.863 -4.35 0.018Z" fill-rule="evenodd" />
            <path fill={color} d="M74.305 2.619v2.023h-1.582l0.001 0.579 0.062 29.286 -3.859 0.016c-2.043 -5.568 -4.564 -9.841 -7.473 -14.772 -1.027 -1.742 -2.09 -3.543 -3.211 -5.533l-1.082 -1.918V31.855h1.518v2.604H52.513V31.855h1.517V5.437h-1.517V2.619h6.931c1.183 1.681 8.451 13.968 9.257 15.369l1.09 1.893 -0.011 -2.184 -0.063 -12.48 -0.003 -0.576h-1.386V2.619h5.977m-14.592 -0.578H51.935v3.974h1.517v25.262h-1.517v3.759h7.323V31.277h-1.518V14.502c4.415 7.832 8.034 12.945 10.784 20.601 1.671 -0.006 3.17 -0.013 4.842 -0.021l-0.064 -29.862h1.581V2.041h-7.133v3.179h1.388l0.063 12.48c-0.71 -1.234 -9.231 -15.659 -9.488 -15.659" />
            <path fill={color} points="39.303 7.41 39.303 2.023 21.328 9.981 21.328 14.674 23.919 13.759 23.919 55.5 21.064 55.5 21.064 60.75 39.25 60.75 39.25 55.612 36.688 55.612 36.688 8.378 39.303 7.41" fill-rule="evenodd" d="M22.71 4.282L22.71 1.169L12.324 5.767L12.324 8.479L13.821 7.95L13.821 32.069L12.171 32.069L12.171 35.102L22.679 35.102L22.679 32.133L21.199 32.133L21.199 4.841L22.71 4.282Z" />
            <path fill={color} points="39.303 7.41 39.303 2.023 21.328 9.981 21.328 14.674 23.919 13.759 23.919 55.5 21.064 55.5 21.064 60.75 39.25 60.75 39.25 55.612 36.688 55.612 36.688 8.378 39.303 7.41" d="M22.71 4.282L22.71 1.169L12.324 5.767L12.324 8.479L13.821 7.95L13.821 32.069L12.171 32.069L12.171 35.102L22.679 35.102L22.679 32.133L21.199 32.133L21.199 4.841L22.71 4.282Z" />
            <path fill={color} d="M29.637 34.816c-3.184 0 -5.988 -2.965 -6.125 -6.475 -0.079 -2.772 0.658 -4.98 2.19 -6.557 1.734 -1.784 4.435 -2.689 8.027 -2.689 0.358 0 0.728 0.009 1.107 0.028l0.303 0.014v-1.696c-0.044 -1.806 -0.931 -2.838 -2.434 -2.838 -1.662 0 -3.659 1.293 -4.73 3.038a39.111 39.111 0 0 1 -1.534 -0.448c-0.19 -0.058 -0.38 -0.117 -0.567 -0.173l2.267 -7.624 2.047 0.503 -0.19 0.577a0.379 0.379 0 0 0 0.018 0.346c0.092 0.161 0.299 0.269 0.515 0.269 0.108 0 0.213 -0.027 0.305 -0.079 1.297 -0.534 2.654 -0.814 4.001 -0.814 4.008 0 6.92 2.511 6.925 5.972v15.841h1.613v2.325H36.009c-0.125 -0.187 -0.343 -0.777 -0.478 -1.141 -0.149 -0.403 -0.278 -0.751 -0.384 -0.928l-0.202 -0.336 -0.262 0.292c-1.521 1.697 -3.267 2.594 -5.048 2.594m4.201 -13.394c-0.938 0 -1.813 0.411 -2.6 1.221 -0.891 1.258 -1.015 2.509 -0.956 4.685 0.268 1.892 1.514 2.738 2.56 2.738 1.141 0 2.059 -0.892 2.339 -2.27l0.006 -6.12 -0.201 -0.064a3.744 3.744 0 0 0 -1.148 -0.191" fill-rule="evenodd" />
            <path fill={color} d="m28.338 9.741 1.477 0.363 -0.087 0.266c-0.081 0.191 -0.069 0.406 0.037 0.592 0.144 0.253 0.444 0.416 0.766 0.416 0.142 0 0.282 -0.033 0.407 -0.095 1.287 -0.529 2.598 -0.798 3.899 -0.798 3.903 0 6.632 2.337 6.636 5.683v16.13h1.613v1.747h-6.905c-0.114 -0.237 -0.274 -0.67 -0.379 -0.953 -0.178 -0.48 -0.293 -0.784 -0.407 -0.975l-0.402 -0.673 -0.524 0.584c-1.465 1.635 -3.136 2.498 -4.833 2.498 -3.033 0 -5.705 -2.838 -5.836 -6.191 -0.077 -2.694 0.632 -4.831 2.109 -6.351 1.677 -1.727 4.308 -2.602 7.818 -2.602h0.001c0.355 0 0.723 0.009 1.094 0.028l0.606 0.029v-2.013c-0.048 -1.95 -1.066 -3.113 -2.724 -3.113 -1.695 0 -3.714 1.263 -4.859 2.994a43.855 43.855 0 0 1 -1.319 -0.391l-0.293 -0.09 2.106 -7.085m4.503 20.614c1.284 0 2.314 -0.982 2.622 -2.503l0.012 -0.057V21.466l-0.401 -0.129a4.032 4.032 0 0 0 -1.237 -0.205c-1.02 0 -1.964 0.44 -2.807 1.308l-0.032 0.033 -0.026 0.037c-0.909 1.29 -1.041 2.584 -0.981 4.825l0.001 0.033 0.005 0.033c0.289 2.029 1.676 2.954 2.843 2.954M27.941 9.049l-2.427 8.166c0.847 0.248 1.769 0.555 2.599 0.76 1.007 -1.785 3.025 -3.083 4.591 -3.083 1.179 0 2.102 0.734 2.146 2.55v1.393a22.614 22.614 0 0 0 -1.122 -0.028c-7.67 0 -10.662 4.098 -10.505 9.547 0.141 3.632 3.007 6.753 6.413 6.753 1.711 0 3.556 -0.786 5.263 -2.69 0.246 0.412 0.755 2.21 1.016 2.21h7.748v-2.903h-1.613V16.168c-0.006 -3.842 -3.243 -6.26 -7.214 -6.261 -1.331 0 -2.743 0.272 -4.144 0.852a0.329 0.329 0 0 1 -0.162 0.042c-0.17 0 -0.323 -0.12 -0.265 -0.216l0.293 -0.893 -2.617 -0.644Zm4.9 20.729c-1.009 0 -2.041 -0.84 -2.271 -2.457 -0.056 -2.073 0.041 -3.294 0.875 -4.476 0.759 -0.781 1.557 -1.133 2.393 -1.133 0.347 0 0.7 0.061 1.06 0.176v5.852c-0.279 1.374 -1.158 2.039 -2.056 2.039" />
            <path fill={color} d="M141.348 35.073c-3.184 0 -5.988 -2.965 -6.125 -6.475 -0.079 -2.772 0.658 -4.98 2.19 -6.558 1.734 -1.784 4.435 -2.689 8.027 -2.689 0.358 0 0.728 0.009 1.107 0.028l0.303 0.014V17.698c-0.044 -1.807 -0.931 -2.839 -2.434 -2.839 -1.662 0 -3.659 1.293 -4.73 3.039a39.134 39.134 0 0 1 -1.534 -0.448c-0.19 -0.058 -0.38 -0.117 -0.567 -0.173l2.267 -7.624 2.047 0.503 -0.19 0.577a0.378 0.378 0 0 0 0.018 0.345c0.092 0.161 0.299 0.27 0.515 0.27 0.108 0 0.213 -0.028 0.305 -0.079 1.298 -0.535 2.654 -0.814 4.001 -0.814 4.009 0 6.921 2.512 6.925 5.972v15.841h1.613v2.325h-7.365c-0.125 -0.188 -0.343 -0.777 -0.478 -1.142 -0.149 -0.402 -0.277 -0.749 -0.383 -0.926l-0.202 -0.336 -0.262 0.291c-1.521 1.698 -3.267 2.594 -5.048 2.594m4.201 -13.394c-0.938 0 -1.813 0.411 -2.6 1.22 -0.892 1.259 -1.015 2.509 -0.957 4.686 0.268 1.892 1.514 2.738 2.56 2.738 1.141 0 2.059 -0.892 2.339 -2.271l0.006 -6.119 -0.201 -0.064a3.744 3.744 0 0 0 -1.148 -0.191" fill-rule="evenodd" />
            <path fill={color} d="m140.049 9.999 1.477 0.363 -0.087 0.266c-0.081 0.191 -0.069 0.406 0.037 0.592 0.144 0.253 0.444 0.416 0.766 0.416 0.143 0 0.282 -0.033 0.407 -0.095 1.287 -0.529 2.598 -0.798 3.899 -0.798 3.903 0 6.632 2.337 6.636 5.683v16.13h1.613v1.747h-6.905c-0.114 -0.237 -0.274 -0.67 -0.379 -0.954 -0.178 -0.48 -0.292 -0.784 -0.407 -0.974l-0.402 -0.673 -0.524 0.584c-1.465 1.635 -3.136 2.498 -4.833 2.498 -3.033 0 -5.705 -2.838 -5.836 -6.191 -0.077 -2.695 0.632 -4.832 2.108 -6.351 1.678 -1.726 4.308 -2.601 7.818 -2.601h0.001c0.355 0 0.723 0.009 1.094 0.028l0.606 0.029v-2.013c-0.048 -1.95 -1.066 -3.113 -2.724 -3.113 -1.695 0 -3.714 1.263 -4.859 2.994a43.855 43.855 0 0 1 -1.319 -0.391l-0.293 -0.09 2.106 -7.085m4.503 20.614c1.284 0 2.314 -0.982 2.622 -2.503l0.011 -0.057V21.724l-0.4 -0.129a4.032 4.032 0 0 0 -1.237 -0.205c-1.02 0 -1.964 0.44 -2.807 1.308l-0.032 0.033 -0.026 0.037c-0.909 1.29 -1.041 2.584 -0.981 4.825l0.001 0.033 0.005 0.033c0.289 2.029 1.676 2.954 2.843 2.954m-4.9 -21.306 -2.427 8.166c0.847 0.248 1.769 0.555 2.599 0.76 1.007 -1.785 3.025 -3.083 4.591 -3.083 1.179 0 2.102 0.734 2.146 2.55v1.393a22.632 22.632 0 0 0 -1.122 -0.028c-7.67 0 -10.662 4.098 -10.505 9.547 0.141 3.632 3.007 6.753 6.413 6.753 1.711 0 3.556 -0.786 5.263 -2.69 0.246 0.412 0.755 2.21 1.017 2.21h7.747v-2.903h-1.613V16.426c-0.006 -3.842 -3.243 -6.26 -7.214 -6.261 -1.33 0 -2.742 0.272 -4.144 0.852a0.329 0.329 0 0 1 -0.162 0.042c-0.17 0 -0.323 -0.12 -0.265 -0.216l0.293 -0.893 -2.617 -0.644Zm4.9 20.729c-1.009 0 -2.041 -0.84 -2.271 -2.457 -0.056 -2.073 0.041 -3.293 0.875 -4.476 0.759 -0.781 1.557 -1.133 2.393 -1.133 0.347 0 0.7 0.061 1.06 0.177v5.851c-0.279 1.374 -1.158 2.039 -2.056 2.039" />
            <path fill={color} d="M192.445 34.813c-1.777 -0.054 -3.476 -0.926 -4.91 -2.519 -2.388 -2.657 -3.672 -6.927 -3.351 -11.143 0.488 -6.344 4.468 -11.129 9.257 -11.129 0.272 0 0.547 0.016 0.818 0.047 3.789 0.437 6.867 4.413 7.71 9.925 -2.856 1.047 -5.385 2.204 -8.059 3.426 -0.864 0.395 -1.739 0.795 -2.641 1.198l-0.244 0.109 0.089 0.251c1.049 2.956 2.638 4.454 4.723 4.454 1.461 0 3.166 -0.755 5.073 -2.245 0.138 0.231 0.782 1.292 0.94 1.557 -1.952 3.636 -5.67 6.067 -9.3 6.067h-0.105Zm-0.164 -21.541c-0.11 0 -0.218 0.012 -0.318 0.035 -0.541 0.128 -0.987 0.522 -1.324 1.172 -1.038 1.997 -0.818 6.053 -0.404 7.087l0.112 0.279 5.497 -2.466 -0.039 -0.221c-0.894 -5.122 -2.591 -5.886 -3.524 -5.886" fill-rule="evenodd" />
            <path fill={color} d="M193.441 10.312c0.261 0 0.525 0.015 0.785 0.045 3.604 0.415 6.548 4.189 7.42 9.45 -2.775 1.029 -5.247 2.159 -7.857 3.352a341.093 341.093 0 0 1 -2.638 1.197l-0.487 0.218 0.179 0.502c1.093 3.083 2.774 4.647 4.994 4.647 1.46 0 3.136 -0.708 4.993 -2.107 0.087 0.145 0.177 0.293 0.267 0.44 0.143 0.237 0.287 0.473 0.421 0.698 -1.922 3.464 -5.489 5.771 -8.967 5.771h-0.091c-1.702 -0.052 -3.329 -0.89 -4.709 -2.424 -2.337 -2.6 -3.593 -6.788 -3.279 -10.928 0.477 -6.192 4.332 -10.862 8.969 -10.861m-3.251 11.921 0.55 -0.246 2.52 -1.131 2.497 -1.12 0.41 -0.184 -0.077 -0.443c-0.93 -5.33 -2.785 -6.125 -3.808 -6.125 -0.133 0 -0.262 0.014 -0.384 0.043 -0.626 0.148 -1.136 0.592 -1.514 1.32 -1.093 2.102 -0.856 6.227 -0.417 7.327l0.224 0.559m3.251 -12.499c-4.829 0 -9.034 4.746 -9.545 11.395 -0.54 7.109 3.37 13.815 8.546 13.973h0.109c3.828 0 7.649 -2.551 9.631 -6.364 -0.362 -0.612 -0.831 -1.368 -1.193 -1.981 -2.128 1.721 -3.813 2.388 -5.152 2.388 -2.429 0 -3.717 -2.196 -4.45 -4.262 3.736 -1.67 7.032 -3.295 10.902 -4.697 -0.758 -5.396 -3.756 -9.914 -7.996 -10.404a7.45 7.45 0 0 0 -0.852 -0.049m-2.938 11.725c-0.487 -1.217 -0.723 -7.339 1.527 -7.87a1.115 1.115 0 0 1 0.251 -0.028c0.79 0 2.385 0.752 3.239 5.646l-5.018 2.251" />
            <path fill={color} d="M239.417 35.209c-3.184 0 -5.988 -2.965 -6.125 -6.475 -0.08 -2.772 0.658 -4.98 2.19 -6.558 1.734 -1.784 4.435 -2.689 8.028 -2.689 0.358 0 0.727 0.009 1.107 0.028l0.302 0.014v-1.695c-0.044 -1.807 -0.931 -2.839 -2.435 -2.839 -1.662 0 -3.659 1.293 -4.729 3.039a38.272 38.272 0 0 1 -1.536 -0.449c-0.19 -0.058 -0.378 -0.116 -0.565 -0.172l2.266 -7.624 2.047 0.503 -0.19 0.577a0.378 0.378 0 0 0 0.019 0.345c0.092 0.161 0.298 0.27 0.515 0.27 0.108 0 0.213 -0.028 0.305 -0.079 1.298 -0.535 2.654 -0.814 4.001 -0.814 4.008 0 6.92 2.512 6.925 5.972v15.841h1.613v2.325h-7.365c-0.125 -0.188 -0.344 -0.778 -0.479 -1.143 -0.149 -0.401 -0.277 -0.748 -0.383 -0.925l-0.202 -0.337 -0.262 0.292c-1.521 1.698 -3.266 2.594 -5.048 2.594m4.201 -13.394c-0.938 0 -1.813 0.411 -2.6 1.22 -0.891 1.258 -1.015 2.509 -0.956 4.686 0.268 1.892 1.514 2.738 2.56 2.738 1.141 0 2.059 -0.892 2.339 -2.271l0.006 -6.119 -0.201 -0.064a3.741 3.741 0 0 0 -1.148 -0.191" fill-rule="evenodd" />
            <path fill={color} d="m238.118 10.134 1.477 0.363 -0.088 0.266c-0.081 0.191 -0.069 0.406 0.038 0.592 0.143 0.253 0.444 0.416 0.766 0.416 0.142 0 0.281 -0.033 0.407 -0.095 1.287 -0.529 2.598 -0.798 3.899 -0.798 3.903 0 6.632 2.337 6.636 5.683v16.13h1.613v1.747h-6.905c-0.114 -0.237 -0.274 -0.671 -0.38 -0.955 -0.179 -0.479 -0.292 -0.782 -0.406 -0.972l-0.402 -0.674 -0.524 0.584c-1.465 1.635 -3.136 2.498 -4.833 2.498 -3.033 0 -5.705 -2.838 -5.835 -6.191 -0.077 -2.695 0.632 -4.832 2.108 -6.351 1.677 -1.726 4.308 -2.601 7.818 -2.601h0.002c0.354 0 0.722 0.009 1.093 0.028l0.606 0.029v-2.013c-0.048 -1.95 -1.066 -3.113 -2.724 -3.113 -1.695 0 -3.714 1.263 -4.859 2.994 -0.43 -0.117 -0.881 -0.256 -1.321 -0.391l-0.291 -0.089 2.106 -7.085m4.503 20.614c1.285 0 2.314 -0.982 2.622 -2.503l0.012 -0.057V21.859l-0.401 -0.129a4.032 4.032 0 0 0 -1.237 -0.205c-1.019 0 -1.963 0.44 -2.807 1.308l-0.032 0.032 -0.026 0.038c-0.909 1.29 -1.041 2.584 -0.981 4.825l0.001 0.033 0.005 0.033c0.289 2.029 1.676 2.954 2.843 2.954m-4.9 -21.306 -2.427 8.166c0.846 0.248 1.769 0.555 2.598 0.76 1.007 -1.785 3.025 -3.083 4.591 -3.083 1.179 0 2.1 0.734 2.146 2.55v1.393a22.632 22.632 0 0 0 -1.122 -0.028c-7.67 0 -10.662 4.098 -10.506 9.547 0.141 3.632 3.008 6.753 6.413 6.753 1.711 0 3.557 -0.786 5.264 -2.69 0.246 0.412 0.755 2.21 1.016 2.21h7.747v-2.903h-1.613V16.561c-0.005 -3.842 -3.243 -6.26 -7.213 -6.261 -1.331 0 -2.743 0.272 -4.144 0.852a0.329 0.329 0 0 1 -0.162 0.042c-0.17 0 -0.324 -0.12 -0.266 -0.216l0.294 -0.893 -2.617 -0.644Zm4.9 20.729c-1.009 0 -2.041 -0.84 -2.271 -2.457 -0.055 -2.073 0.041 -3.293 0.875 -4.476 0.759 -0.781 1.557 -1.133 2.393 -1.133 0.347 0 0.7 0.061 1.06 0.177v5.851c-0.279 1.374 -1.158 2.039 -2.056 2.039" />
            <path fill={color} d="M273.604 35.158c-3.184 0 -5.988 -2.965 -6.125 -6.475 -0.08 -2.772 0.658 -4.98 2.19 -6.557 1.734 -1.785 4.435 -2.689 8.027 -2.689 0.358 0 0.727 0.009 1.107 0.027l0.303 0.014v-1.696c-0.044 -1.806 -0.931 -2.838 -2.434 -2.838 -1.662 0 -3.659 1.294 -4.73 3.039a38.777 38.777 0 0 1 -1.533 -0.448c-0.191 -0.059 -0.381 -0.117 -0.567 -0.173l2.266 -7.624 2.047 0.503 -0.19 0.577a0.378 0.378 0 0 0 0.019 0.345c0.092 0.161 0.298 0.27 0.515 0.27 0.108 0 0.213 -0.028 0.305 -0.079 1.298 -0.535 2.654 -0.814 4.001 -0.814 4.008 0 6.92 2.512 6.925 5.972V32.352h1.613v2.325h-7.365c-0.125 -0.188 -0.344 -0.778 -0.479 -1.143 -0.149 -0.401 -0.277 -0.748 -0.383 -0.925l-0.202 -0.337 -0.262 0.291c-1.521 1.698 -3.267 2.594 -5.048 2.594m4.201 -13.394c-0.938 0 -1.813 0.411 -2.6 1.22 -0.891 1.258 -1.015 2.509 -0.956 4.686 0.268 1.892 1.514 2.738 2.56 2.738 1.141 0 2.059 -0.892 2.339 -2.271l0.006 -6.119 -0.201 -0.064a3.741 3.741 0 0 0 -1.148 -0.191" fill-rule="evenodd" />
            <path fill={color} d="m272.306 10.083 1.477 0.363 -0.088 0.266c-0.081 0.191 -0.069 0.406 0.038 0.592 0.143 0.253 0.444 0.416 0.766 0.416 0.142 0 0.281 -0.033 0.407 -0.095 1.287 -0.529 2.598 -0.798 3.899 -0.798 3.903 0 6.632 2.338 6.636 5.683V32.641h1.613v1.747h-6.905c-0.114 -0.237 -0.274 -0.671 -0.38 -0.955 -0.178 -0.479 -0.292 -0.782 -0.406 -0.972l-0.402 -0.674 -0.524 0.584c-1.465 1.635 -3.136 2.498 -4.833 2.498 -3.033 0 -5.705 -2.838 -5.835 -6.191 -0.077 -2.695 0.632 -4.831 2.108 -6.351 1.677 -1.727 4.308 -2.601 7.818 -2.601h0.002c0.354 0 0.722 0.009 1.093 0.027l0.606 0.029v-2.013c-0.048 -1.95 -1.066 -3.113 -2.723 -3.113 -1.696 0 -3.715 1.263 -4.86 2.994a43.825 43.825 0 0 1 -1.319 -0.391l-0.294 -0.09 2.106 -7.085m4.503 20.614c1.285 0 2.314 -0.982 2.622 -2.503l0.012 -0.057V21.808l-0.401 -0.129a4.032 4.032 0 0 0 -1.237 -0.205c-1.019 0 -1.963 0.44 -2.807 1.308l-0.032 0.032 -0.026 0.038c-0.909 1.29 -1.041 2.584 -0.981 4.825l0.001 0.033 0.005 0.033c0.289 2.029 1.676 2.954 2.843 2.954m-4.9 -21.306 -2.427 8.166c0.846 0.248 1.769 0.556 2.598 0.76 1.008 -1.785 3.025 -3.083 4.592 -3.083 1.178 0 2.1 0.735 2.145 2.549v1.393a22.618 22.618 0 0 0 -1.122 -0.028c-7.67 0 -10.662 4.098 -10.506 9.546 0.141 3.632 3.008 6.753 6.413 6.753 1.711 0 3.557 -0.786 5.264 -2.69 0.246 0.412 0.755 2.21 1.016 2.21h7.747V32.063h-1.613V16.51c-0.005 -3.842 -3.243 -6.26 -7.213 -6.261 -1.331 0 -2.743 0.272 -4.144 0.852a0.329 0.329 0 0 1 -0.162 0.042c-0.17 0 -0.324 -0.12 -0.266 -0.216l0.294 -0.893 -2.617 -0.644Zm4.9 20.729c-1.009 0 -2.041 -0.84 -2.271 -2.457 -0.055 -2.073 0.041 -3.293 0.875 -4.476 0.759 -0.781 1.557 -1.133 2.393 -1.133 0.347 0 0.7 0.061 1.06 0.177v5.851c-0.279 1.374 -1.158 2.039 -2.056 2.039" />
        </svg>
    )
}

export default Testata