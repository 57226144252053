import React from 'react'
import Footer from '../components/footer'
import { Stack } from '@mui/material';


const MainLayout = ({
    children
}) => {

    return (
        <Stack>            
            {children}            
            <Footer />
        </Stack>
    )

}

export default MainLayout