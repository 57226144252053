import { useEffect } from 'react'
import { useUser } from '../../core'
import { useDispatch } from 'react-redux';
import { me, logout } from '../../store/user.store';

const UserContainer = ({
    children
}) => {

    const dispatch = useDispatch()
    const [user, token] = useUser()

    // Get User Profile
    useEffect(() => {
        if (user && token) {
            dispatch(me({ auth: token }))
        } else {
            dispatch(logout())
        }
    }, [user, token])


    return children
}

export default UserContainer