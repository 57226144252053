import EditText from './editText/EditText.component';
import ErrorField from './ErrorField.component';
import RememberMe from './RememberMe.component';
import SubmitButton from './Submit.component';
import SelectField from './selectField/SelectField.component';
import Check from './Check.component'

export  {
    EditText,
    ErrorField,
    RememberMe,
    SubmitButton,
    SelectField,
    Check
}

export const Components = {
    EditText,
    ErrorField,
    RememberMe,
    SubmitButton,
    SelectField,
    Check
}