import React from 'react';
import { useRoutes, Navigate, Outlet } from "react-router-dom";

import NotFoundPage from '../pages/NotFound.page';
import PrivateRoute from './PrivateRoute'

import NewslettersPage from '../pages/Newsletters.page';
import UserContainer from '../containers/user/User.container';
import FAQPage from '../pages/FAQ.page';
import KioskPage from '../pages/Kiosk.page';
import PageLayout from '../layout/PageLayout';

import SubscriptionsPage from '../pages/Subscriptions.page';

import PurchasePage from '../pages/purchase/Purchase.page';
import PurchaseBillingPage from '../pages/purchase/Billing.page';
import PurchasePaymentPage from '../pages/purchase/Payment.page';
import PurchaseSuccessPage from '../pages/purchase/Success.page'

import AccountPage from '../pages/profile/Account.page';
import ProfilePage from '../pages/profile/Profile.page';
import ProfileSubscripionsPage from '../pages/profile/ProfileSubscriptions.page';
import PromoCodeSuccessPage from '../pages/promo/PromoCodeSuccess.page';
import PapersPage from '../pages/Papers.page';


const routes = [{
    path: "abbonamenti",
    element: <SubscriptionsPage />,
}, {
    path: "cartaceo",
    element: <PapersPage />,
},{
    path: 'FAQ',
    element: <FAQPage />
}, {
    path: 'newsletters',
    element: <NewslettersPage />
}, {
    path: 'edicola',
    element: <KioskPage />
}, {
    path: 'promo/success',
    element: <PromoCodeSuccessPage />,
}, {
    path: '404',
    element: <NotFoundPage />
}]

const routesPurchase = [{
    path: "purchase/billing",
    element: <PurchaseBillingPage />,
}, {
    path: "purchase/payment",
    element: <PurchasePaymentPage />
}, {
    path: "purchase/success",
    element: <PurchaseSuccessPage />
}]

const routesProfile = [{
    path: "account/profilo",
    element: <ProfilePage />,
}, {
    path: "account/acquisti",
    element: <ProfileSubscripionsPage />
}]

const Router = () => {

    const pathname = window.location.pathname

    const elements = useRoutes([{
        path: '/:newspaper',
        element: <Navigate to={`/${pathname.split('/')[1]}/abbonamenti`} replace />
    }, {
        path: '/:newspaper',
        element:
            <PageLayout>
                <Outlet />
            </PageLayout>,
        children: routes.map((route) => route)
    }, {
        path: '/:newspaper/:partner',
        element:
            <PageLayout>
                <Outlet />
            </PageLayout>,
        children: routes.map((route) => route)
    }, {
        path: "/:newspaper",
        element:
            <PageLayout>
                <PrivateRoute>
                    <PurchasePage />
                </PrivateRoute>
            </PageLayout>,
        children: routesPurchase.map((route) => route)
    }, {
        path: "/:newspaper/:partner",
        element:
            <PageLayout>
                <PrivateRoute>
                    <PurchasePage />
                </PrivateRoute>
            </PageLayout>,
        children: routesPurchase.map((route) => route)
    }, {
        path: "/:newspaper",
        element:
            <PageLayout>
                <PrivateRoute>
                    <AccountPage />
                </PrivateRoute>
            </PageLayout>,
        children: routesProfile.map((route) => route)
    },{
        path: "/:newspaper/:partner",
        element:
            <PageLayout>
                <PrivateRoute>
                    <AccountPage />
                </PrivateRoute>
            </PageLayout>,
        children: routesProfile.map((route) => route)
    },{
        path: '*',
        element: <Navigate to={`/${pathname.split('/')[1]}/abbonamenti`} replace />
    },
    ])

    return (
        <UserContainer>
            {elements}
        </UserContainer>
    )
}

export default Router;
