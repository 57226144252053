import React from "react";
import { Alert } from "@mui/material";
import { Box } from "@mui/system";

const Message = ({
    severity = 'success',
    message = ''
}) => {

    return (
        <Box width={1}>
            <Alert severity={severity}>{message}</Alert>
        </Box>        
    )
}

export default Message