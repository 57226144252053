import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BodyLayout from './BodyLayout';

const PageLayout = ({
    children
}) => {

    const location = useLocation()

    useEffect(() => {
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10)
    }, [location])

    return (
        <BodyLayout>
            {children}
        </BodyLayout>
    )
}

export default PageLayout