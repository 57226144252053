import React, { useEffect, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useLocation } from "react-router-dom";

const steps = ['Dati di Fatturazione', 'Pagamento', 'Riepilogo'];

const PurchaseStepperContainer = () => {

    const [activeStep, setActiveStep] = useState(0);    
    const { pathname } = useLocation()

    useEffect(() => {

        if (pathname.includes('/purchase/billing')) {
            setActiveStep(0)
        }

        if (pathname.includes('/purchase/payment')) {
            setActiveStep(1)
        }

        if (pathname.includes('/purchase/success')) {
            setActiveStep(2)
        }

    }, [pathname, setActiveStep])

    return (
        <Stepper activeStep={activeStep}>
            {steps.map((label) =>
                <Step key={label} >
                    <StepLabel >{label}</StepLabel>
                </Step>
            )}
        </Stepper>
    )
}

export default PurchaseStepperContainer