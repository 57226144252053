import { AppBar, Stack, Toolbar } from '@mui/material'
import React from 'react'
import ToolbarContainer from '../containers/toolbar/Toolbar.container'
import CTAContainer from '../containers/CTA/CTA.container';

const BodyLayout = ({
    children
}) => {

    return (
        <Stack>
            <AppBar position="sticky" color='background' sx={{ boxShadow: 'none' }}>
                <Toolbar disableGutters>
                    <ToolbarContainer />
                </Toolbar>
            </AppBar>
            {children}
            <CTAContainer />
        </Stack>
    )
}

export default BodyLayout