import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { AppleButton, GooglePlayButtonButton, ReaderButton, SiteButton } from "../../components/common/buttons";

const PromoCodeSuccessPage = () => {

    return (
        <Stack justifyContent={'center'} width={1} alignItems='center' spacing={2}>
            <DoneIcon sx={{ fontSize: 80 }} color='success' />
            <Typography variant="h5">
                {`Grazie per aver riscattato il codice`}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <SiteButton />
                </Grid>
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <ReaderButton />
                </Grid>
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <AppleButton />
                </Grid>
                <Grid item xs={6} sm={3} justifyContent='center' display='flex'>
                    <GooglePlayButtonButton />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default PromoCodeSuccessPage