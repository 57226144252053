import NPlus from './NPlus'
import GPlus from './GPlus'
import TPlus from './TPlus'
import FPlus from './FPlus'

export const Abbonamento = {
    'il-tirreno' : TPlus,
    'gazzetta-di-modena' : GPlus,
    'gazzetta-di-reggio' : GPlus,
    'la-nuova-sardegna' : NPlus,
    'la-nuova-ferrara' : FPlus,
}