import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedEdition } from '../../../store/catalog.store';
import _find from 'lodash/find'
import { useTheme, MenuItem, FormControl, Select } from '@mui/material';

const SelectEditionKiosk = ({
    editions = []
}) => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const { catalog, selected: { edition } } = useSelector((state) => state.catalog)

    const handleChange = (event) => {
        if (catalog) {
            const selected = _find(catalog.magazineEditions, (item) => item.name === event.target.value)
            dispatch(selectedEdition({ selected }))
        }
    };


    return (
        catalog && edition &&
        <FormControl variant="standard" fullWidth sx={{ width: theme.spacing(30) }} size="small">
            <Select value={edition?.name} onChange={handleChange}>
                {editions.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectEditionKiosk