import React, { useEffect, useMemo } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import CTAActionComponent from '../../components/cta';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { newspaper as newspaperAction } from '../../store/newspaper.store'

export const Type = {
    FAQ: 'faq',
    Tutorial: 'tutorial'
}

const CTAObject = {
    [Type.FAQ]: {
        title: 'Domande frequenti',
        actionLink: '/faq'
    },
    [Type.Tutorial]: {
        title: 'Scarica la guida con tutte le informazioni per riscattare il coupon',
        actionLink: '/faq'
    },
}


const CTAContainer = () => {

    const dispatch = useDispatch()
    const { newspaper } = useParams();
    const { newspaper: contact } = useSelector((state) => state.newspaper)

    useEffect(() => {
        dispatch(newspaperAction({ slug: newspaper }))
    }, [newspaper, dispatch])

    const cta = useMemo(() => {
        return [
            <CTAActionComponent {...CTAObject[Type.FAQ]} />,
            <a target='__blank' href={contact? `${process.env.REACT_APP_BASE_URL}${contact?.paywall_configuration?.coupon_redemption_guide_file.url}`: '/'} style={{ textDecoration: 'none'}}>
                <Typography variant='h6' color={'primary'} sx={{ fontWeight: 'bold', width: 1 }}>
                    {CTAObject[Type.Tutorial].title}
                </Typography>
            </a>


        ]
    }, [contact])

    return (
        <Grid container>
            {cta.map((item, index) =>
                <Grid key={index} item xs={12} sm={6}>
                    <Paper square variant='outlined' sx={{ height: 1, padding: 2 }}>
                        {item}
                    </Paper>
                </Grid>
            )}
        </Grid>
    )

}

export default CTAContainer