import React, { memo } from 'react'
import { Button } from '@mui/material'

const SubmitButton = memo(({
    isSubmitting = false,
    variant = "contained",
    title
}) => {

    return (
        <Button
            type="submit"
            fullWidth
            variant={variant}
            color='button'
            // sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}>
            {title}
        </Button>
    )
})

export default SubmitButton