import React from 'react';
import { Typography } from '@mui/material';

const TextKiosk = ({
    text,
    color = 'secondary',
    variant = 'h6'
}) => {

    return (
        <Typography color={color} variant={variant} align='center' sx={{ fontWeight: 'bold', paddingBottom: 1 }}>
            {text}
        </Typography>
    )
}

export default TextKiosk
