import React, { forwardRef } from 'react'

import { Styled } from './EditText.styled';

const EditText = forwardRef(({
	required = false,
	label,
	type = 'text',
	shrink,
	...props
}, ref) => {

	return (
		<Styled.TextField
			inputRef={ref}
			margin="normal"
			required={required}
			fullWidth
			InputLabelProps={{
				shrink: props.value || shrink,
			}}
			label={label}
			name={props.name}
			type={type}
			value={props.value}
			onChange={props.onChange}			
		/>
	)
})

export default EditText