import React, { useCallback, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { useLocation } from "react-router-dom";
import Nav from '../../components/toolbar/nav';
import { Stack } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Logo from '../../components/toolbar/logo';
import UserButton from '../../components/user/button';
import MenuButton from '../../components/toolbar/menu';

const ToolbarContainer = () => {

    const location = useLocation();
    const [open, setOpen] = useState(false)

    const toggleMenu = useCallback(() => {
        setOpen(!open)
    }, [open])

    useEffect(() => {
        setOpen(false)
    }, [location])

    return (
        <Stack width={1} direction='row' alignItems='center' justifyContent={'space-between'} paddingLeft={1} paddingRight={1}>
            <MenuButton toggleMenu={toggleMenu} open={open} />
            <Logo />
            <UserButton variant='text' color='primary' />
            <Drawer open={open} onClose={toggleMenu} anchor="left">
                <Box sx={{ minWidth: 400, backgroundColor: 'white.main', height: '100%', role: 'presentation', overflowY: 'scroll', position: 'relative' }}>
                    <Nav onClose={toggleMenu} />
                </Box>
            </Drawer>
        </Stack>        
    )
}

export default ToolbarContainer