import React, { useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "../../components/cards";
import { prices } from '../../store/products.store';
import _ from 'lodash'
import Action from "../../components/cards/components/action";
import { pricesSpecial } from "../../store/productsSpecial.store";
import Contact from "../../components/cards/components/contact";

const CardContainer = ({
    product,
    conf,
}) => {

    const { period, title, info, interval, other_interval, tag } = conf
    const { newspaper } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (product.id_stripe || product.id) {
            dispatch(product.product_special ? pricesSpecial({ id_stripe: product.id_stripe, newspaper }) : prices(product.id_stripe !== '' ? { id_stripe: product.id_stripe, newspaper } : { id: product.id, newspaper }))
        }
    }, [dispatch, product.id_stripe, newspaper, product.product_special, product.id])

    // Get Prices
    const priceStripe = useMemo(() => {
        return product.prices.length > 1 ? _.find(product.prices, ({ recurring }) => recurring?.interval === period.interval && recurring?.interval_count === period.interval_count) : _.head(product.prices)
    }, [product, period])


    const price = useMemo(() => {
        const first = product.prices.length > 1 ? _.find(product.prices, ({ recurring }) => recurring?.interval === period.interval && recurring?.interval_count === period.interval_count) : _.head(product.prices)
        return first ? first.tiers ? _.first(first.tiers).unit_amount / 100 : first.unit_amount / 100 : ''
    }, [product, period])

    const other_price = useMemo(() => {
        const last = product.prices.length > 1 ? _.find(product.prices, ({ recurring }) => recurring?.interval === period.interval && recurring?.interval_count === period.interval_count) : _.head(product.prices)
        return last ? last.tiers ? _.last(last.tiers).unit_amount / 100 : last.unit_amount / 100 : ''
    }, [product, period])

    const paper_price = useMemo(() => {
        if(_.includes(tag, 'coupon') || _.includes(tag, 'paper')){
            return (price / interval).toFixed(2)
        }

        return ''
        
    }, [price, tag, interval])

    return (
        <Card
            height={_.includes(tag, 'paper') || _.includes(tag, 'coupon') ? 250 : 300}
            title={title}
            subtitle={_.template(product.description)({ interval: interval || '' })}
            info={info || ''}
            price={_.template(product.price_description)({ price, interval, other_price: (_.includes(tag, 'paper') || _.includes(tag, 'coupon'))? paper_price : other_price, other_interval })}>
            {(_.includes(tag, 'paper') || _.includes(tag, 'coupon'))? <Contact /> : <Action productStripe={product} priceStripe={priceStripe} period={period} />}
        </Card>
    )
}

export default CardContainer