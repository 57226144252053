import { Pattern } from "@marketto/codice-fiscale-utils";
import comuni from "./comuni.json";
import moment from "moment";

const younger_than = 25;

const checkCityCode = (city, taxCode) => {
	const cityCode = taxCode.slice(11, 15);
	const cities = comuni;
	const result = cities.find((obj) => obj.comune === city.toUpperCase());
	if (!result) {
		return null;
	}
	return result.codice === cityCode;
}

const formatDate = (type, element) => {
	let formatted;
	switch (type) {
		case 'year': {
			formatted = element.slice(-2);
			return Number(formatted);
		}
		case 'month': {
			formatted = element.startsWith("0") ? element.slice(-1) : element;
			return Number(formatted);
		}
		case 'day': {
			formatted = element.startsWith("0") ? element.slice(-1) : element;
			return Number(formatted);
		}
		case 'place': {
			return element.charAt(0).toUpperCase() + element.slice(1);
		}
		default: {
			return formatted;
		}
	}
}

const hasReachedAge = (birthDateString, ageToReach) => {
	const birthDate = moment(
		birthDateString.split("-").reverse().join("-"),
		"DD-MM-YYYY"
	);
	const now = moment();
	const age = now.diff(birthDate, "years");
	return age <= ageToReach;
}

const checkDates = (date, code, gender) => {
	if (gender === "M") {
		return (
			Number(code.slice(6, 8)) === formatDate('year', date.split("-")[0]) &&
			code.slice(9, 11) === date.split("-")[2]
		);
	} else {
		const dayOfBirth = Number(date.split("-")[2]) + 40;
		return (
			Number(code.slice(6, 8)) === formatDate('year', date.split("-")[0]) &&
			code.slice(9, 11) === dayOfBirth.toString()
		);
	}
};

export const checkTaxCode = (data, setSuccess, setError) => {

	const { lastName, firstName, birthDate, gender, birthPlace, taxCode } = data;

	if (!hasReachedAge(birthDate, younger_than)) {
		setSuccess(false);
		setError({ message: `Hai superato i ${younger_than} anni` });
	} else if (
		!Pattern.codiceFiscale({
			lastName: lastName.toUpperCase(),
			firstName: firstName.toUpperCase(),
			year: formatDate('year', birthDate.split("-")[0]),
			month: formatDate('month', birthDate.split("-")[1]),
			day: formatDate('day', birthDate.split("-")[2]),
			place: formatDate('place', birthPlace),
		}).test(taxCode.toUpperCase()) ||
		!checkDates(birthDate, taxCode.toUpperCase(), gender) ||
		checkCityCode(birthPlace.toUpperCase(), taxCode.toUpperCase()) === undefined ||
		!checkCityCode(birthPlace.toUpperCase(), taxCode.toUpperCase())
	) {
		setSuccess(false);
		setError({ message: "Il codice fiscale non corrisponde ai tuoi dati" });
	} else {
		setSuccess(true);
		setError({ message: "" });
	}
};
