import * as React from "react";
import { Card, CardHeader, CardContent, Box, Typography, CardActions } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import { Abbonamento } from '../../../icons/abbonamento'

export const NewsletterCard = ({
	card,
	children
}) => {

	const theme = useTheme()
	const { newspaper } = useParams()
	const Logo = Abbonamento[newspaper]

	return (
		<Card sx={{ backgroundColor: theme.palette.secondary.main }}>
			<CardHeader sx={{ padding: 0 }}
				avatar={
					card.free === true ?
						<Box sx={{ display: 'flex', backgroundColor: theme.palette.white.main, padding: theme.spacing(1), fontWeight: 'bold', fontStyle: 'italic' }}>
							Free
						</Box> :
						<Box sx={{ display: 'flex', width: theme.spacing(5), height: theme.spacing(5), backgroundColor: theme.palette.yellow.main }}>
							<Logo />
						</Box>
				} />

			<CardContent>
				<Typography textAlign='center' variant='h6' color={theme.palette.white.main} sx={{ fontWeight: 'bold', fontStyle: 'italic', padding: theme.spacing(2) }}>
					{card.title}
				</Typography>
				<Typography textAlign='center' variant='body1' color={theme.palette.white.main} sx={{ padding: theme.spacing(2), minHeight: theme.spacing(28) }}>
					{card.description}
				</Typography>
			</CardContent>

			<CardActions sx={{ backgroundColor: theme.palette.white.main, display: 'flex', justifyContent: 'center'}}>
				{children}
			</CardActions>
		</Card>
	);
};

export default NewsletterCard;