import { useMemo } from "react"
import { useMediaQuery } from "../../utils/useMediaQuery"

const Testata = ({
	color = 'black',
}) => {


	const mobile = useMediaQuery(900)
	const height = useMemo(() => mobile? 20 : 30, [mobile])

	return (
		<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 300 26.203" enableBackground="new 0 0 918.861 80.257" xmlSpace="preserve" height={`${height}`} preserveAspectRatio="xMinYMin meet">
			<g>
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M262.285 0.583V4.989h0.332c0.37 0 0.656 0.331 0.656 0.66v14.96a0.648 0.648 0 0 1 -0.656 0.657h-0.332v4.368h6.65V21.266h-0.581c-0.412 0 -0.702 -0.413 -0.702 -0.948V11.993l0.083 0.29h0.043l5.453 13.351h6.819V8.614h0.04v-2.8c0 -0.536 0.371 -1.03 0.783 -1.03h0.497V0.583h-7.022v4.201h0.58c0.409 0 0.781 0.494 0.781 1.03v8.983h-0.04l-5.371 -14.172h-8.013z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M216.594 0c-6.777 0.329 -8.266 3.711 -8.763 8.447V17.102c0.37 5.025 1.657 8.981 8.723 9.102h0.04c6.977 -0.12 8.508 -4.114 8.925 -8.857V7.992C224.977 3.79 222.955 0.491 216.594 0zm1.939 18.997c0 1.195 -0.578 2.017 -1.9 2.017h-0.039c-1.367 0 -1.946 -0.742 -1.946 -2.017v-11.786c0 -1.07 0.331 -2.061 1.946 -2.061h0.039c1.613 0 1.9 0.991 1.9 2.061v11.786z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M246.104 0.583v4.362h0.496c0.373 0 0.702 0.291 0.702 0.66V20.481c0 0.372 -0.329 0.663 -0.702 0.663h-0.453l-0.042 0.042v4.447h15.038v-8.407h-3.218c-0.296 1.235 -0.128 4.365 -3.395 3.997V15.911h2.975V10.262h-2.975v-5.273c3.223 -0.085 2.895 2.885 3.348 3.996h3.265V0.583h-15.038z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M236.036 0.582h-9.257v4.409h0.456c0.373 0 0.708 0.285 0.708 0.66v14.914c0 0.374 -0.336 0.66 -0.708 0.66h-0.456v4.409h9.257c6.51 0.002 8.925 -4.336 8.925 -8.194V8.776c0 -4.549 -2.741 -8.208 -8.925 -8.194zm1.939 12.461V18.221c0 1.07 -0.266 3.224 -3.342 3.086l0.02 -8.265h0l0 -0.096 0 -0.166h-0.001l-0.02 -7.873c3.076 -0.138 3.342 2.016 3.342 3.086V13.043z" />
				<path fill={color} d="M182.695 21.205c0.397 -0.2 0.655 -0.451 0.772 -0.751 0.117 -0.3 0.175 -0.838 0.175 -1.614V7.432c0 -0.801 -0.059 -1.351 -0.175 -1.651 -0.117 -0.3 -0.374 -0.55 -0.772 -0.751V0.602h9.719l2 9.832h0.07l2.14 -9.832h9.473v4.428c-0.398 0.2 -0.655 0.457 -0.772 0.77 -0.117 0.313 -0.175 0.857 -0.175 1.632v11.409c0 0.4 0.018 0.726 0.053 0.976 0.035 0.25 0.088 0.464 0.158 0.638 0.07 0.175 0.163 0.319 0.28 0.431 0.117 0.113 0.269 0.22 0.456 0.319v4.428h-8.842V21.205c0.397 -0.225 0.649 -0.482 0.755 -0.77 0.105 -0.288 0.158 -0.819 0.158 -1.595v-6.229h-0.07c-0.024 0.501 -0.094 1.082 -0.211 1.745 -0.117 0.663 -0.305 1.52 -0.562 2.571l-2.175 8.707h-2.807l-2.491 -9.983c-0.094 -0.35 -0.193 -0.832 -0.298 -1.445 -0.105 -0.613 -0.182 -1.145 -0.228 -1.595h-0.07v6.23c0 0.4 0.011 0.726 0.035 0.976 0.023 0.25 0.07 0.464 0.14 0.638 0.07 0.175 0.169 0.319 0.298 0.431 0.129 0.113 0.286 0.22 0.474 0.319v4.428H182.695V21.205z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M166.666 0.567v4.409h0.705c0.37 0 0.701 0.537 0.701 0.782v14.791c0 0.209 -0.331 0.742 -0.701 0.782h-0.705l0.044 4.286h9.75V21.332h-0.622c-0.411 -0.04 -0.744 -0.573 -0.744 -0.782V5.759c0 -0.245 0.333 -0.782 0.744 -0.782h0.578l0.043 -0.04V0.567h-9.793z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M87.225 0.586v8.408h3.52v-1.154c0 -1.116 0.162 -2.802 1.481 -2.844v15.574c0 0.209 -0.494 0.742 -0.906 0.782h-0.413v4.286h9.707V21.35h-0.455c-0.415 -0.04 -0.705 -0.573 -0.705 -0.782l-0.036 -15.574h0.036c1.369 0 1.369 2.226 1.369 2.638v1.36h3.469V0.586h-17.068z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M105.452 0.586v8.408h3.52v-1.154c0 -1.116 0.162 -2.802 1.481 -2.844v15.574c0 0.209 -0.494 0.742 -0.906 0.782h-0.413v4.286h9.707V21.35H118.386c-0.415 -0.04 -0.705 -0.573 -0.705 -0.782l-0.036 -15.574h0.036c1.369 0 1.369 2.226 1.369 2.638v1.36h3.469V0.586h-17.067z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M70.698 0.585v4.362h0.496c0.373 0 0.702 0.291 0.702 0.66v14.876c0 0.372 -0.329 0.663 -0.702 0.663h-0.453l-0.042 0.042v4.447h15.038v-8.407h-3.218c-0.296 1.235 -0.128 4.365 -3.395 3.997V15.913h2.975V10.264h-2.975v-5.273c3.223 -0.085 2.895 2.885 3.348 3.996h3.265V0.585h-15.038z" />
				<path fill={color} d="m54.256 21.125 7.626 -15.183h-0.931c-0.821 0 -1.371 0.176 -1.652 0.527 -0.28 0.351 -0.42 1.027 -0.42 2.029v2.142H54.406V0.567h15.011v4.36l-7.566 15.259h0.991c0.5 0 0.895 -0.05 1.185 -0.151 0.29 -0.099 0.505 -0.282 0.646 -0.545s0.225 -0.62 0.256 -1.071 0.045 -1.027 0.045 -1.729v-1.541h4.564v10.486h-15.283V21.125z" />
				<path fill={color} d="m37.813 21.125 7.626 -15.183h-0.931c-0.821 0 -1.371 0.176 -1.652 0.527 -0.28 0.351 -0.42 1.027 -0.42 2.029v2.142h-4.473V0.567h15.011v4.36l-7.566 15.259h0.991c0.5 0 0.895 -0.05 1.185 -0.151 0.29 -0.099 0.505 -0.282 0.646 -0.545s0.225 -0.62 0.256 -1.071c0.03 -0.451 0.045 -1.027 0.045 -1.729v-1.541h4.564v10.486h-15.283V21.125z" />
				<path fill={color} d="M16.865 9.325H10.517v-1.616c0 -1.479 -0.103 -2.559 -1.851 -2.559 -1.928 0 -1.859 1.594 -1.859 2.145v10.674c0 0.952 -0.328 3.045 1.859 3.045 2.127 0 1.851 -2.075 1.851 -2.953v-2.248h-1.462V11.391h7.81v11.99c-0.968 0.927 -2.126 1.61 -3.473 2.049 -1.348 0.438 -3.147 0.774 -5.036 0.774 -1.919 0 -3.109 -0.33 -4.226 -0.755 -1.117 -0.426 -1.981 -1.002 -2.592 -1.73 -0.61 -0.726 -1.019 -1.566 -1.227 -2.518C0.104 20.249 0 19.246 0 18.194v-10.373c0 -1.002 0.108 -1.967 0.328 -2.894 0.219 -0.927 0.628 -1.748 1.226 -2.462 0.6 -0.714 1.439 -1.273 2.507 -1.71C5.582 0.133 8.355 0 8.355 0s2.713 0.228 4.639 0.831c1.457 0.456 2.741 1.189 3.87 2.142V9.325z" />
				<path fill={color} d="M34.962 19.391c-0.161 -0.401 -3.852 -18.823 -3.852 -18.823H23.53S20.013 19.172 19.961 19.364c-0.407 1.511 -1.936 1.692 -1.936 1.692v4.58h8.166l-0.007 -4.438c-1.221 -0.075 -1.319 -0.698 -1.319 -1.35 0 -0.125 0.032 -0.792 0.097 -1.168h3.143c0.043 0.225 0.097 1.092 0.097 1.168 0 0.551 -0.027 1.329 -1.357 1.346v4.441h9.809V21.055c-1.366 -0.29 -1.53 -1.265 -1.692 -1.665zM25.647 14.871l0.811 -5.597 0.904 5.597h-1.715z" />
				<path fill={color} d="M139.236 19.391c-0.161 -0.401 -3.852 -18.823 -3.852 -18.823h-7.579s-3.518 18.604 -3.57 18.796c-0.407 1.511 -1.936 1.692 -1.936 1.692v4.58h8.166l-0.007 -4.438c-1.221 -0.075 -1.319 -0.698 -1.319 -1.35 0 -0.125 0.032 -0.792 0.097 -1.168h3.143c0.043 0.225 0.097 1.092 0.097 1.168 0 0.551 -0.027 1.329 -1.357 1.346v4.441h9.809V21.055c-1.367 -0.29 -1.53 -1.265 -1.692 -1.665zm-9.315 -4.519 0.811 -5.597 0.904 5.597h-1.715z" />
				<path fill={color} d="M298.308 19.391c-0.161 -0.401 -3.852 -18.823 -3.852 -18.823h-7.579s-3.518 18.604 -3.57 18.796c-0.407 1.511 -1.936 1.692 -1.936 1.692v4.58h8.166l-0.007 -4.438c-1.221 -0.075 -1.319 -0.698 -1.319 -1.35 0 -0.125 0.032 -0.792 0.097 -1.168h3.143c0.043 0.225 0.097 1.092 0.097 1.168 0 0.551 -0.027 1.329 -1.357 1.346v4.441h9.809V21.055c-1.366 -0.29 -1.53 -1.265 -1.692 -1.665zm-9.314 -4.519 0.811 -5.597 0.904 5.597h-1.715z" />
				<path fill={color} fillRule="evenodd" clipRule="evenodd" d="M156.421 0.615h-9.257v4.409h0.456c0.373 0 0.708 0.285 0.708 0.66V20.549c0 0.374 -0.336 0.66 -0.708 0.66h-0.456v4.409h9.257c6.397 0 8.925 -4.019 8.925 -8.194V8.809c0 -4.401 -2.636 -8.194 -8.925 -8.194zm1.939 12.412v5.178c0 1.07 -0.266 3.224 -3.342 3.086l0.02 -8.265h0l0 -0.047 0 -0.166h-0.001l-0.02 -7.873c3.076 -0.138 3.342 2.016 3.342 3.086V13.027z" />
			</g>
		</svg>
	)
}

export default Testata