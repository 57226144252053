import React, { useState, useCallback } from 'react';
import { Divider, Typography, Button, Stack } from '@mui/material';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useParams } from "react-router-dom";
import Loading from '../../../components/common/Loading.component';
import Message from '../../../components/common/Message.component';
import useQuery from '../../../core/hooks/useQuery';

const PurchaseContainer = () => {

    const query = useQuery()
    const stripe = useStripe();
    const elements = useElements();
    const { newspaper, partner } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const handleSubmit = useCallback(async (event) => {

        event.preventDefault();
        setErrorMessage(null)
        setIsLoading(true)

        const return_url = partner? `${window.location.origin}/${newspaper}/${partner}/purchase/success?${query.toString()}` : `${window.location.origin}/${newspaper}/purchase/success?${query.toString()}`

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url
            },
        });

        if (error) {
            setErrorMessage(error.message);
        }

        setIsLoading(false)
    }, [elements, newspaper, query, partner]);

    return (
        <form onSubmit={handleSubmit}>
            {isLoading && <Loading type='backdrop' />}
            {errorMessage && <Message severity='error' message={errorMessage} />}
            <Stack spacing={1}>                
                <Stack direction={'row'} justifyContent='space-between' alignContent={'center'}>
                    <Typography variant="subtitle1" fontWeight={'bold'}>
                        {query.get('name')} / {query.get('type')}
                    </Typography>
                    <Typography variant="h6" fontWeight={'bold'} color='secondary'>
                        {parseFloat(query.get('amount') / 100).toFixed(2)} €
                    </Typography>
                </Stack>
                <Divider />
                <PaymentElement options={{ defaultValues: { billingDetails: { name: 'billingDetails' } } }} onReady={() => setIsLoading(false)} />                                
                <Button variant='contained' color='primary' disabled={!stripe || !elements} type="submit">
                    Completa pagamento
                </Button>
                <Typography>
                    {errorMessage && <div >{errorMessage}</div>}
                </Typography>
            </Stack>
        </form>
    )
}

export default PurchaseContainer