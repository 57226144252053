import React from "react";
import { backLink, readerLink, googlePlayLink, appleLink } from '../../../conf/toolbar'
import { Button, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import LanguageIcon from '@mui/icons-material/Language';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';

export const SiteButton = () => {

    const { newspaper } = useParams()

    return (
        <a href={backLink[newspaper]}>
            <Stack alignItems={'center'}>
                <LanguageIcon color='primary' />
                <Typography color='primary'>
                    Visita il sito
                </Typography>
            </Stack>
        </a>
    )
}


export const ReaderButton = () => {

    const { newspaper } = useParams()

    return (
        <a href={readerLink[newspaper]}>
            <Stack alignItems={'center'}>
                <ChromeReaderModeIcon color='primary' />
                <Typography color='primary'>
                    Leggi il quotidiano
                </Typography>
            </Stack>
        </a>
    )
}


export const AppleButton = () => {
    const { newspaper } = useParams()

    return (
        <a href={appleLink[newspaper]}>
            <Stack alignItems={'center'}>
                <AppleIcon color='primary' />
                <Typography color='primary'>
                    Apple Store
                </Typography>
            </Stack>
        </a>
    )
}


export const GooglePlayButtonButton = () => {
    const { newspaper } = useParams()

    return (
        <a href={googlePlayLink[newspaper]}>
            <Stack alignItems={'center'}>
                <GoogleIcon color='primary' />
                <Typography color='primary'>
                    Google Play
                </Typography>
            </Stack>
        </a>
    )
}
