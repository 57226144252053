import React from 'react'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import { Controller } from 'react-hook-form'
import { SubmitButton, ErrorField } from '../form'
import { FieldsCode, ValidationSchema, InitialData } from '../../conf/promo'
import { Components } from '../form'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

const PromoForm = ({
    isSubmitting = false,
    onSubmit = () => { },
}) => {

    const { handleSubmit, control, formState } = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues: InitialData
    });


    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} width={1}>            
            <Grid container spacing={2} display={'flex'} alignItems={'center'}>
                <Grid container item xs={12} >
                    {FieldsCode.map(({ dimensions, controller, component }) => {
                        const Field = Components[component.name]
                        return (
                            <Grid item xs={dimensions.xs} key={controller.name}>
                                <Controller
                                    name={controller.name}
                                    control={control}
                                    rules={{ required: controller.required }}
                                    render={({ field }) =>
                                        <Field
                                            shrink={controller.name === 'birthdate'}
                                            required={controller.required}
                                            label={component.label}
                                            type={component.type}
                                            options={component.options}
                                            {...field} />} />
                                {formState.errors?.[controller.name] ? <ErrorField message={formState.errors?.[controller.name]?.message} /> : null}
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <SubmitButton title='Attiva' isSubmitting={isSubmitting} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default PromoForm