export const OffersEndpoints = {
    basePath: '',
    endpoints: {
        all: {
            path: `/product-limited-offers-currently-available?newspaper={{newspaper}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false
        }
    }
}

export default OffersEndpoints