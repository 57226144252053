import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import TextKiosk from '../../components/kiosk/text';
import SelectEditionKiosk from '../../components/kiosk/select';
import NewspaperContainer from './Newspaper.container';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { issue } from '../../store/issue.store';

const KioskContainer = ({
    items = [],
    editions = []
}) => {

    const dispatch = useDispatch()
    const { newspaper } = useParams();

    useEffect(() => {
        dispatch(issue({ slug: newspaper }))
    }, [newspaper, dispatch])

    return (
        <Stack spacing={2} padding={2}>
            <Stack direction={'row'} spacing={1} justifyContent='center' alignItems='flex-start' width={1} >
                <TextKiosk text={'Scegli l\'edizione:'} variant='subtitle1'/>
                {editions && <SelectEditionKiosk editions={editions} />}            
            </Stack>
            <NewspaperContainer items={items} />
        </Stack>
    )
}

export default KioskContainer
