import _ from 'lodash'

export const ProductsEndpoints = {
    basePath: '',
    endpoints: {
        all: {
            path: `/products?newspaper.slug={{slug}}&product_special_null=true`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data,'order')
        },
        all_products_and_special: {
            path: `/products?newspaper.slug={{slug}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data,'order')
        },
        all_special: {
            path: `/products?newspaper.slug={{slug}}&product_special_null=false`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data,'order')
        },
        filter: {
            path: `/products?newspaper.slug={{slug}}&is_one_shot=false`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data, 'order')
        },
        prices: {
            path: `/prices?id_stripe={{id_stripe}}&newspaper={{newspaper}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            }
        },
        prices_papers: {
            path: `/prices?id={{id}}&newspaper={{newspaper}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            }
        },
        product_options: {
            path: '/product-options?{{query}}',
            method: 'GET',
            get: null,
            fallback: [],
            auth: false,
            swr: {
                revalidateOnFocus: false
            }
        }        
    }
}

export default ProductsEndpoints