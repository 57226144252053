import * as React from "react"

const Logo = () => (

    <svg id="a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 43">
        <rect width="67" height="43" fill="#ffdf33" />
        <path d="M27.361,4.354H10.327v4.154h1.755v25.891h-1.755v4.313h12.062v-4.313h-2.032v-14.033h7.004v1.841h3.217V13.769h-3.217v2.151h-7.004v-6.444c.041-.496,.162-.936,.831-.97,1.059,.03,2.117,.059,3.176,.089,1.753,.126,2.752,.734,2.997,1.823v.897h3.217V4.354h-3.217Z" />
        <path d="M47.643,10.371c.458,0,.815,.242,1.07,.727,.256,.484,.383,.905,.383,1.262,0,.203-.115,.727-.345,1.568s-.483,1.784-.765,2.83c-.279,1.044-.561,2.014-.842,2.906-.279,.892-.445,1.492-.497,1.798,.357,0,1.161,.012,2.409,.038,1.25,.025,2.576,.102,3.978,.229,1.403,.127,2.627,.306,3.671,.535,1.046,.229,1.568,.548,1.568,.956,0,.459-.203,.88-.612,1.263-.407,.382-.841,.573-1.3,.573-1.784,0-3.582,.012-5.393,.038-1.809,.025-3.606,.038-5.392,.038-.662,2.243-1.339,4.486-2.027,6.73-.687,2.243-1.262,4.513-1.721,6.808-.306,.05-.522-.115-.649-.497-.127-.383-.204-.804-.229-1.263-.025-.458-.039-.765-.039-.917,0-1.786,.128-3.595,.383-5.431,.256-1.836,.511-3.646,.765-5.431h-8.337c-.713,0-1.427,.013-2.142,.038-.713,.025-1.427,.063-2.142,.115,0-.052-.012-.09-.038-.115-.024-.025-.038-.064-.038-.114,0-.357,.396-.688,1.186-.994,.791-.306,1.785-.587,2.983-.842,1.198-.256,2.422-.485,3.671-.688,1.25-.204,2.384-.37,3.403-.497,1.021-.128,1.709-.217,2.065-.268,.103-.357,.344-1.147,.727-2.371,.382-1.224,.829-2.524,1.338-3.901,.511-1.376,1.021-2.575,1.53-3.595,.51-1.021,.969-1.53,1.377-1.53Z" />
    </svg>
)

export default Logo