import React, { forwardRef } from 'react'
import { FormControlLabel, Checkbox } from '@mui/material'

const Check = forwardRef(({    
    label,
    ...props
}, ref) => {

    return (
        <FormControlLabel
            inputRef={ref}
            control={
                <Checkbox
                    defaultChecked={props.value}
                    value={props.value}
                    color="primary"
                />}
            label={label}
            onChange={props.onChange}            
            name={props.name}
        />
    )
})

export default Check