import _ from 'lodash'

export const PurchaseEndpoints = {
    basePath: '',
    endpoints: {
        buy: {
            path: `/stripe-purchases`,
            method: 'POST',
            get: null,
            fallback: null,
            auth: true,
            swr: {
                revalidateOnFocus: false
            }
        },
        all: {
            path: `/purchases-all?newspaper={{newspaper}}`,
            method: 'GET',
            get: null,
            fallback: [],
            auth: true,
            swr: {
                revalidateOnFocus: false
            },
            transform: (data) => _.orderBy(data, 'current_period_end')
        },
    }
}

export default PurchaseEndpoints