import { Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux';

export const SubscriptionsType = {
    Paper: 'paper',
    PA: 'PA'
}

const ContactsContainer = ({
    type = SubscriptionsType.Paper
}) => {


    const { newspaper: contact } = useSelector((state) => state.newspaper)

    return (
        <Stack justifyContent={'center'}>
            <Typography variant='subtitle1' color='primary' textAlign={'center'}>
                {`CHIAMA AL NUMERO:`}
            </Typography>
            <Typography variant='h6' textAlign={'center'} color={'secondary'} fontWeight={'bold'}>                
                {`${contact?.paywall_configuration?.[type === SubscriptionsType.Paper? 'paper_subscription_contact_phone' : 'pa_subscription_contact_phone' ] || ''}`}
            </Typography>
            <Typography variant='subtitle1' color='primary' textAlign={'center'}>
                {`OPPURE SCRIVI ALL’INDIRIZZO EMAIL:`}
            </Typography>
            <Typography variant='h6' textAlign={'center'} color={'secondary'} fontWeight={'bold'}>
                {`${contact?.paywall_configuration?.[type === SubscriptionsType.Paper? 'paper_subscription_contact_email' : 'pa_subscription_contact_email' ] || ''}`}
            </Typography>
        </Stack>
    )

}

export default ContactsContainer