import React from 'react';
import { useTheme, Typography, Stack } from '@mui/material';
import { DateTime } from 'luxon';

const DateKiosk = ({
    date
}) => {

    const theme = useTheme()

    return (
        <Stack direction={'row'} spacing={1}>
            <Typography color={theme.palette.primary.main} variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                COPIA DEL:
            </Typography>
            <Typography color={theme.palette.secondary.main} variant='h6' sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                {DateTime.fromISO(date).setLocale('it').toFormat('dd MMMM yyyy')}
            </Typography>
        </Stack>

    )
}

export default DateKiosk
