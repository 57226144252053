import * as Yup from 'yup';

export const ValidationSchema = Yup.object({
    code: Yup.string().required(),    
})


export const FieldsCode = [{
    dimensions: {
        xs: 12
    },
    controller: {
        name: 'code',
        required: false
    },
    component: {
        label: 'Codice',
        name: 'EditText',
        type: 'text'
    }
}]



export const InitialData = {
    code: ''    
}