import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'subscriptions',
    initialState: {        
        loading: false,
        error: null,        
        subscriptions: [],        
    },
    reducers: {
        subscriptions: (state) => {
            state.error = null
            state.loading = true            
            state.subscriptions = []
        },                
        subscriptionsSuccess: (state, action) => {
            state.error = null
            state.loading = false
            state.subscriptions = action.subscriptions
        },
        subscriptionsError: (state, action) => {              
            state.subscriptions = []   
            state.error = action.error
            state.loading = false
        }        
    },
});


export const {
    subscriptions,
    subscriptionsSuccess,
    subscriptionsError    
} = slice.actions

export default slice.reducer