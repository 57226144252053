import React, { useEffect, useState } from 'react'
import { Requests, useUser } from '../../core'
import SubscribeButton from '../../components/cards/newsstand-card/components/SubscribeButton.component';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from "react-router-dom";

const SubscribeContainer = ({
    item,
}) => {

    const { newspaper } = useParams()
    const [user, token] = useUser()
    const { loginWithRedirect } = useAuth0();
    const [isSubscribe, setIsSubscribe] = useState(item.isSubscribe)
    const [enable, setEnable] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        setIsSubscribe(item.isSubscribe)
    }, [item])

    useEffect(() => {
        token && Requests.subscription.exists({ auth: token , newspaper }).then((response) => response ? setEnable(response.status === 'active') : setEnable(false)).catch(() => setEnable(false))
    }, [token])

    const onSubcribe = () => {
        Requests.newsletter.subscribe({ id: item.mailup_id, auth: token }, { email: user.email }).then((response) => setIsSubscribe(response.result)).catch(() => setIsSubscribe(false))
    }

    const onUnsubcribe = () => {
        Requests.newsletter.unsubscribe({ id: item.mailup_id, auth: token }, { email: user.email }).then((response) => setIsSubscribe(!response.result)).catch(() => setIsSubscribe(true))
    }


    if (!user) {
        return (
            <SubscribeButton label={item.free ? 'Registrati' : 'Abbonati'} onClick={() => loginWithRedirect({
                    redirectUri: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/${newspaper}/abbonamenti`
                })} />
        )
    }


    if (user && item.free) {
        return (
            <SubscribeButton label={isSubscribe ? 'Disiscriviti' : 'Iscriviti'} onClick={() => isSubscribe ? onUnsubcribe() : onSubcribe()} />
        )
    }


    if (user && !item.free) {
        return (
            <SubscribeButton label={enable ? isSubscribe ? 'Disiscriviti' : 'Iscriviti' : 'Abbonati'} onClick={() => enable ? isSubscribe ? onUnsubcribe() : onSubcribe() : navigate(`/${newspaper}/abbonamenti`)} />
        )
    }

    return null
}

export default SubscribeContainer